/**
 * @param {JSX.Element} icon
 * @param {string} title
 * @param {string} text
 * @param {array<function>} buttonsArray
 */
export function Modal({
  icon,
  title,
  text,
  buttonsArray = null,
  isMobileView = false,
}) {
  return (
    <div className="relative z-10">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className={`${!isMobileView && "sm:flex sm:items-start"}`}>
                <div
                  className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full ${
                    !isMobileView && "sm:mx-0 sm:h-10 sm:w-10"
                  }`}
                >
                  {icon}
                </div>
                <div
                  className={`mt-3 text-center ${
                    !isMobileView && "sm:ml-4 sm:mt-0 sm:text-left"
                  }`}
                >
                  <h3 className="text-base font-semibold leading-6 text-gray-900">
                    {title}
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">{text}</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`bg-gray-50 px-2 py-3 flex flex-col sm:px-6 justify-end gap-2 ${
                !isMobileView && "sm:items-center sm:flex-row"
              }`}
            >
              {buttonsArray.map((buttonComponent) => buttonComponent)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
