import React, { useRef, useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import * as Yup from "yup";
import debounce from "lodash.debounce";
// firebase functions
import { handleReauthentication } from "../actions";
import { getAuth, updatePassword } from "firebase/auth";
// profile context
import { useProfileContext } from "pages/contexts/profileContext";
// components
import ProfilDataItem from "layouts/ProfilDataItem";
import ProfilePageCancelButton from "components/buttons/ProfilePageCancelButton";
import ProfilePageSubmitButton from "components/buttons/ProfilePageSubmitButton";

const PasswordsForm = () => {
  const {
    setPasswordFormIsUpdating,
    passwordFormIsUpdating,
    passwordDataSubmitButtonRef,
    handleButtonState,
    isPasswordEditable,
    setIsPasswordEditable,
    combinedUserData,
    containerAnimation,
  } = useProfileContext();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true); // state to disable submit button when is is true
  const formRef = useRef(null);

  useEffect(() => {
    const formik = formRef.current;

    return () => {
      if (isPasswordEditable) {
        setIsSubmitDisabled(true); // reset state value to disable submit button
      }
      // Reseting formik values and errors
      formik?.resetForm(); // reseting form values/errors
    };
  }, [isPasswordEditable]);

  const validationSchema = Yup.object({
    currentPassword: Yup.string()
      .min(8, "Must be at least 8 characters")
      .required("Required"),
    newPassword: Yup.string()
      .min(8, "Must be at least 8 characters")
      .required("Required")
      .oneOf([Yup.ref("confirmPassword"), null], "Passwords should match"),
    confirmPassword: Yup.string()
      .min(8, "Must be at least 8 characters")
      .required("Required")
      .oneOf([Yup.ref("newPassword"), null], "Passwords should match"),
  });

  // Handle form submission
  const handleSubmit = async (e) => {
    if (!isPasswordEditable) {
      // if the form is not editable then prevent submission
      e.preventDefault();
    } else {
      e.preventDefault();
      // get form data from form ref
      const formData = formRef.current?.values;

      setPasswordFormIsUpdating(true); // set form updating to true
      setIsSubmitDisabled(true); // disable submit button

      try {
        // validate input data because debounced input fields
        await validationSchema.validate(formData);

        await handleReauthentication(
          combinedUserData.email,
          formData.currentPassword
        ); // re-authenticate the user to change password

        if (!formData.newPassword) {
          throw new Error("Missing new password!");
        }
        if (!formData.confirmPassword) {
          throw new Error("Missing confirm password!");
        }
        await updatePassword(getAuth().currentUser, formData.newPassword); // update password in firebase

        toast.success("Password Sucessfully Updated!");
        setIsPasswordEditable(false); // Turn off edit mode
        setPasswordFormIsUpdating(false); // set form updating to false
        handleButtonState(passwordDataSubmitButtonRef, false); // enable submit button
      } catch (error) {
        // Handling error messages
        const errorCode = error.code;
        const errorMessage = error.message;
        let toastError;
        switch (errorCode) {
          case "auth/wrong-password":
            toastError = "Current password is incorrect.";
            break;
          case "auth/missing-password":
            toastError = "Current password is missing.";
            break;
          case "auth/weak-password":
            toastError = "New Password should be at least 8 characters.";
            break;
          case "auth/network-request-failed":
            toastError = "Make sure you are connected to the internet!";
            break;
          default:
            toastError = `Update failed. (${errorMessage})`;
            break;
        }
        toast.error(toastError);
        console.log(`%c ${error}`, "color: red");
        setPasswordFormIsUpdating(false); // set form updating to false
        setIsSubmitDisabled(false); // enable submit button
      }
    }
  };

  // When the user typing this function will be fired. If 'isSubmitDisabled' is true then disable deactivation button.
  const handleChange = async () => {
    // get values from form
    const values = formRef.current?.values;

    try {
      await validationSchema.validate(values);
      setIsSubmitDisabled(false);
    } catch (error) {
      /* console.log(error.message); */
      setIsSubmitDisabled(true);
    }
  };

  const debouncedChangeHandler = debounce(handleChange, 300); // debounce input changes. Wait 300ms after every key stroke

  // Debounce clean-up function
  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, []);

  return (
    <>
      <Formik
        validationSchema={validationSchema}
        validateOnMount={true}
        innerRef={formRef}
        initialValues={{
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        }}
      >
        {({ errors, values }) => {
          return (
            <Form
              className="mt-4 sm:mt-1 overflow-hidden"
              onSubmit={handleSubmit}
              onChange={debouncedChangeHandler}
            >
              <ProfilDataItem
                inputProps={{
                  label: "current password",
                  name: "currentPassword",
                  placeholder: "●●●●●●●●●●●",
                  inputType: "password",
                }}
                errors={errors.currentPassword}
                value={values.currentPassword}
                isEditable={isPasswordEditable}
                isUpdateLoading={passwordFormIsUpdating}
              ></ProfilDataItem>
              <ProfilDataItem
                inputProps={{
                  label: "new password",
                  name: "newPassword",
                  placeholder: "●●●●●●●●●●●",
                  inputType: "password",
                }}
                errors={errors.newPassword}
                value={values.newPassword}
                isAnimate={true}
                hideByDefault={true}
                isEditable={isPasswordEditable}
                isUpdateLoading={passwordFormIsUpdating}
              ></ProfilDataItem>
              <ProfilDataItem
                inputProps={{
                  label: "confirm password",
                  name: "confirmPassword",
                  placeholder: "●●●●●●●●●●●",
                  inputType: "password",
                }}
                description="Repeat your new password"
                errors={errors.confirmPassword}
                value={values.confirmPassword}
                isAnimate={true}
                hideByDefault={true}
                isEditable={isPasswordEditable}
                isUpdateLoading={passwordFormIsUpdating}
              ></ProfilDataItem>
              <motion.div
                initial="initial"
                animate={isPasswordEditable ? "show" : "hide"}
                variants={containerAnimation}
                className="flex justify-around sm:justify-end gap-3"
              >
                <ProfilePageCancelButton
                  content="cancel changing"
                  onClickFunc={() => setIsPasswordEditable(!isPasswordEditable)}
                  isEditable={isPasswordEditable}
                  isUpdateLoading={passwordFormIsUpdating}
                />
                <ProfilePageSubmitButton
                  content="save password"
                  isDisabled={isSubmitDisabled}
                  isUpdateLoading={passwordFormIsUpdating}
                  isEditable={isPasswordEditable}
                />
              </motion.div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default PasswordsForm;
