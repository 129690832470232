import React from "react";
import SidebarMenu from "./SidebarMenu";
import { motion, AnimatePresence } from "framer-motion";
import { CircleStackIcon } from "@heroicons/react/24/outline";
import { ChevronDoubleLeftIcon } from "@heroicons/react/20/solid";

/**
 * framer-motion variant animation for the title/icon appearance
 */
const animationOnClose = {
	visible: {
		opacity: "60%",
		transition: {
			duration: 0.2,
			delay: 0.2,
		},
	},
	hidden: {
		opacity: 0,
		transition: {
			duration: 0,
		},
	},
};

/**
 * THIS COMPONENT REPLACE THE SIDEBAR WHEN ITS CLOSED ON MOBILE TO A SIMPLE NAVBAR AT THE TOP
 * 
 * PROPS:
 * @param {Boolean} isSidebarClosed
 * @param {Function} toggleSidebarClose 
 */
const SidebarMoblieNav = ({ isSidebarClosed, toggleSidebarClose }) => {
	return (
		<div
            data-testid="SidebarMobileNav"
			className={`hidden absolute w-full bg-neutral-100 shadow-lg px-6 py-3 z-10 ${
				isSidebarClosed && "max-sidebar3:block"
			}`}
		>
			<div className="flex flex-row justify-between items-center">
                {/* DASHBOARD TITLE WITH ICON */}
				<AnimatePresence>
					{isSidebarClosed && (
						<motion.div
							data-testid="SidebarMobileNav-title"
							initial="hidden"
							animate="visible"
							exit="hidden"
							variants={animationOnClose}
							className="flex flex-row items-center gap-2 text-custom pr-6"
						>
							<CircleStackIcon className="h-8 w-8" />
							<p className="text-lg font-semibold">Dashboard</p>
						</motion.div>
					)}
				</AnimatePresence>
                {/* OPEN SIDEBAR BUTTON */}
				<button
					onClick={toggleSidebarClose}
					className="text-custom p-1 bg-black opacity-60 bg-opacity-10 duration-200 hover:opacity-100 hover:text-primary-blue-dark rounded-medium"
					data-testid="SidebarMobileNav-Button"
				>
					<motion.div
						animate={{ rotate: isSidebarClosed ? 180 : 0 }}
						transition={{
							delay: 0.2,
							duration: 2,
							type: "spring",
							stiffness: 100,
						}}
					>
						<ChevronDoubleLeftIcon className="h-6 w-6" />
					</motion.div>
				</button>
			</div>
		</div>
	);
};

export default SidebarMoblieNav;
