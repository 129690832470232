import {
  GoogleAuthProvider,
  reauthenticateWithPopup,
  getAuth,
} from "firebase/auth";

/* Reauthenticate Google user with popup */
export const handleGoogleReauthentication = async () => {
  const reUser = getAuth().currentUser;

  await reauthenticateWithPopup(reUser, new GoogleAuthProvider());
};
