import React, { useState, useRef, useEffect } from "react";
import { Field } from "formik";
import { motion, AnimatePresence } from "framer-motion";
// profile context
import { useProfileContext } from "pages/contexts/profileContext";
// icons
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";

const NewProfilDataItem = ({
  inputProps = {
    label: "",
    name: "",
    placeholder: "",
    disabled: false,
    inputType: "text",
  },
  isMobileView = false,
  isEditable,
  isUpdateLoading = false,
  isAnimate = false,
  hideByDefault = false,
  description = "",
  errors = null,
  value = "",
}) => {
  // Import required variables from context
  const { fadeIn, fadeInOutHeight, inputAnimations, combinedUserData } =
    useProfileContext();
  const [isShowPassword, setIsShowPassword] = useState(false); // state variable to show or hide input text (changes when the user clicks on the eye button).
  const inputRef = useRef(null); // ref for input field
  const [rememberLastError, setRememberLastError] = useState("");

  useEffect(() => {
    // remember last error to decrease ui bugs
    const timeout = setTimeout(() => {
      setRememberLastError(errors);
    }, 400);

    // timeout clean-up function
    return () => {
      clearTimeout(timeout);
    };
  }, [errors]);

  /* Handle eye button click event on password fields */
  const handleShowHidePassword = (e) => {
    if (!isShowPassword) {
      inputRef.current.type = "text";
    } else {
      inputRef.current.type = "password";
    }
    setIsShowPassword(!isShowPassword);
  };

  return (
    <AnimatePresence initial={false}>
      <motion.div
        initial={!hideByDefault ? "initial" : "default"}
        animate={isAnimate ? (isEditable ? "show" : "hide") : "default"}
        variants={inputAnimations}
        data-testid="input-container"
        className="profile-data-item-container"
      >
        <hr />
        <motion.div
          className={`px-1 py-3 sm:py-6 grid grid-cols-[39%_58%] justify-center ${
            !isMobileView && "sm:grid sm:grid-cols-3 sm:gap-4"
          } gap-1 gap-x-0  items-center opacity-1 `}
        >
          <dt
            className={`text-sm  font-medium capitalize leading-6 text-gray-900 align-middle pl-0 ${
              !isMobileView && "sm:text-base sm:pl-3 md:pl-6 sm:my-2"
            } order-1 col-span-1 my-0`}
          >
            <AnimatePresence>
              {combinedUserData?.lastName ? (
                <motion.div
                  initial="initial"
                  animate="animate"
                  variants={fadeIn}
                >
                  {inputProps.label}
                </motion.div>
              ) : (
                <div role="status" className="animate-pulse mb-4 sm:mb-0">
                  <div className="h-5 sm:h-6 bg-gray-300 rounded-full dark:bg-gray-700 w-40"></div>
                  <span className="sr-only">Loading...</span>
                </div>
              )}
            </AnimatePresence>
          </dt>
          <div
            className={`relative flex flex-col order-3 col-span-2 ${
              !isMobileView && "sm:order-2 sm:col-span-1"
            }`}
          >
            <div className="profile-input-container">
              <AnimatePresence>
                {!combinedUserData?.lastName ? (
                  <div role="status" className="w-full animate-pulse">
                    <div className="h-6 sm:h-6 bg-gray-300 rounded-full dark:bg-gray-700 w-50 "></div>
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <>
                    {
                      <motion.div
                        key={1}
                        initial="initial"
                        animate="animate"
                        variants={fadeIn}
                      >
                        <Field
                          innerRef={inputRef}
                          name={inputProps.name}
                          data-testid="input-field"
                          type={inputProps.inputType}
                          placeholder={inputProps.placeholder}
                          className={`text-sm ${
                            !isMobileView && "md:text-base"
                          } leading-6 text-gray-700 r px-1 py-1 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-600 rounded-lg transition ease-in-out delay-50 ${
                            isEditable && !inputProps.disabled
                              ? "placeholder:text-gray-400"
                              : "placeholder:text-gray-700"
                          } placeholder:capitalize ${
                            isEditable && !inputProps.disabled
                              ? "border-gray-500 ring-1 ring-inset ring-gray-300"
                              : "border-transparent"
                          } ${
                            (inputProps.disabled && isEditable) ||
                            isUpdateLoading
                              ? "bg-gray-300"
                              : ""
                          }`}
                          disabled={
                            inputProps.disabled ||
                            !isEditable ||
                            isUpdateLoading
                          }
                          value={value}
                        ></Field>
                      </motion.div>
                    }
                  </>
                )}
                {isEditable && inputProps.inputType === "password" && (
                  <button
                    key={2}
                    data-testid="eye-button"
                    type="button"
                    className="password-eye right-0 border-2 border-transparent focus:outline-none focus:border-sky-500 focus:ring-1 text-xl text-gray-700 focus:ring-sky-600"
                    onClick={(e) => handleShowHidePassword(e)}
                  >
                    {isShowPassword ? <BsEyeSlashFill /> : <BsEyeFill />}
                  </button>
                )}
              </AnimatePresence>
            </div>
            <AnimatePresence initial={false}>
              {description && (
                <motion.span
                  data-testid="description"
                  initial="initial"
                  animate={isEditable ? "in" : "out"}
                  variants={fadeInOutHeight}
                  className="pl-1"
                  style={{ fontSize: "clamp(0.7rem, 1.2vw, 0.9rem)" }}
                >
                  *{description}
                </motion.span>
              )}
            </AnimatePresence>
          </div>
          <dd
            data-testid="error-message-container"
            className={`mt-0 text-m leading-6 text-gray-700 text-right pe-0 select-none order-2 ${
              !isMobileView && "sm:order-3 sm:col-span-1 md:pe-5"
            }`}
          >
            <motion.span
              data-testid="indicator"
              initial="initial"
              animate={!errors ? "out" : isEditable && errors ? "in" : "out"}
              variants={fadeIn}
              style={{
                transition: "all ease 0.5s",
                transform: "scale(1)",
              }}
              className={`bg-${"red"}-100 opacity-100 text-${"red"}-600 text-center text-xs rounded-xl py-1 px-3 ${
                !isMobileView && "md:text-sm sm:px-4 md:px-7"
              }`}
            >
              {errors ? errors : rememberLastError}
            </motion.span>
          </dd>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default NewProfilDataItem;
