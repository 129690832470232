import { uploadBytesResumable, getDownloadURL } from "firebase/storage";

export const uploadFile = (file, storageRef, setDataRef, setPercent=()=>{}, index = -1) => {
        
        const uploadTask = uploadBytesResumable(storageRef, file);
        let downloadURL = "";

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const percent = Math.floor(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );

                // update progress
                if(index<0){
                    setPercent(percent);
                }
                else {
                    setPercent(percent, index)
                    //console.log(index + " = " + percent + "%");
                }
            },
            (err) => console.log(err),
            () => {
                // download url
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    // dataAction(dataRef, url);
                    downloadURL = url;
                    setDataRef(url);
                });
                if(index<0){
                    setPercent(0);
                }
            }
        );
        return downloadURL;
}