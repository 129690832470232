import React, { Fragment, useEffect } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  BellIcon,
  XMarkIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import logo from "assets/images/logo-icon.png";
import { NavLink, Link } from "react-router-dom";
import { handleLogout } from "features/authentication/actions";
import { getAuth } from "firebase/auth";
import { Banner } from "components";
import { child, get, getDatabase, ref, set } from "firebase/database";
import { toast } from "react-toastify";
import ReportABug from "./components/ReportABug";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const navigationItems = [
  { name: "Dashboard", href: "/home", current: false, disabled: false },
  { name: "Team", href: "/team", current: false, disabled: true },
];

export const Navbar = ({ currentNavItem }) => {
  const auth = getAuth();
  const db = getDatabase();
  const user = auth.currentUser;
  const updatedNavItems = navigationItems.map((item) => ({
    ...item,
    current: item.name === currentNavItem,
  }));

  // BANNER LOGIC
  const [showMigrationRequired, setShowMigrationRequired] =
    React.useState(false);
  const [showMigrationProgress, setShowMigrationProgress] =
    React.useState(false);

  useEffect(() => {
    const dbRef = ref(getDatabase());

    if (user) {
      get(child(dbRef, `migrations/${user.uid}`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            setShowMigrationRequired(false);
            sessionStorage.removeItem("migration-request");

            // get the migration progress from session storage
            const migrationProgress =
              sessionStorage.getItem("migration-progress");
            // if the migration progress is not set, then show the banner
            if (migrationProgress === null) {
              setShowMigrationProgress(true);
            } else {
              // if the migration progress is set, then check if it is set to false
              if (migrationProgress === "false") {
                setShowMigrationProgress(false);
              } else {
                // if the migration progress is set to true, then show the banner
                setShowMigrationProgress(true);
              }
            }
          } else {
            setShowMigrationProgress(false);
            // get the banner status from session storage
            const requestBanner = sessionStorage.getItem("migration-request");
            // if the banner status is not set, then show the banner
            if (requestBanner === null) {
              setShowMigrationRequired(true);
            } else {
              // if the banner status is set, then check if it is set to false
              if (requestBanner === "false") {
                setShowMigrationRequired(false);
              } else {
                // if the banner status is set to true, then show the banner
                setShowMigrationRequired(true);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
          toast.error(error.message);
        });
    }
  }, [user]);

  // END OF BANNER LOGIC

  return (
    <div>
      <Banner
        isShow={showMigrationProgress}
        setIsShow={setShowMigrationProgress}
        title="Migration in Progress"
        description="The migration of your data is in progress."
        color="info"
        onDismiss={() => {
          sessionStorage.setItem("migration-progress", false);
        }}
      />
      <Banner
        isShow={showMigrationRequired}
        setIsShow={setShowMigrationRequired}
        title={"Migration Request"}
        description={
          "Migrate your previous projects from our old app to here! "
        }
        onAction={async (setIsLoading, setIsSucccess) => {
          // Show loading animation and disable the button
          setIsLoading(true);
          try {
            // add the migration request to the database
            await set(ref(db, "migrations/" + user.uid), {
              email: user.email,
            });

            // Show success modal
            setTimeout(() => {
              setShowMigrationProgress(true);
              setShowMigrationRequired(false);
              setIsSucccess(true);
            }, 1000);
          } catch (error) {
            // Show error toast and hide the loading animation
            toast.error(error.message);
            setIsLoading(false);
          }
        }}
        onDismiss={() => {
          // set banner visibility to false
          sessionStorage.setItem("migration-request", false);
        }}
        buttonText={"Migrate Now"}
        renderModalOnAction={
          <div className="flex gap-5 justify-center items-center">
            <svg
              viewBox="0 0 64 64"
              xmlns="http://www.w3.org/2000/svg"
              className="w-[90px] h-[90px] fill-green-500 drop-shadow-lg"
            >
              <g id="Processing">
                <path d="M31.1057,37.8494a3.5971,3.5971,0,0,1,.425-4.5713L33.4192,31.39a3.5948,3.5948,0,0,1,4.5713-.4246l1.935,1.3236a1.7286,1.7286,0,0,1,.0908-.2869c.2579-.54,1.4732-3.2485,1.6856-3.595A3.5882,3.5882,0,0,1,44.76,26.6924h2.67a3.5744,3.5744,0,0,1,.575.051,2.01,2.01,0,0,0,.0383-.3812V22.6611a2.0179,2.0179,0,0,0-1.6455-1.9824l-4.2891-.8047A1.832,1.832,0,0,1,40.7107,18.7a17.64,17.64,0,0,0-.6944-1.6788,1.8368,1.8368,0,0,1,.1612-1.8173l2.4619-3.6a2.02,2.02,0,0,0-.2383-2.5654L39.7839,6.4218a2.0186,2.0186,0,0,0-2.5654-.2388l-3.6,2.4629a1.8329,1.8329,0,0,1-1.8174.16,17.4516,17.4516,0,0,0-1.68-.6934A1.8356,1.8356,0,0,1,28.948,6.7128l-.8047-4.2881A2.0154,2.0154,0,0,0,26.1619.7792H22.4607a2.0181,2.0181,0,0,0-1.9824,1.6451l-.8047,4.2885A1.8357,1.8357,0,0,1,18.5,8.1122a17.279,17.279,0,0,0-1.6787.6934,1.837,1.837,0,0,1-1.8183-.16l-3.6-2.4629a2.02,2.02,0,0,0-2.5655.2388L6.2214,9.0385a2.02,2.02,0,0,0-.2392,2.5654l2.4629,3.6a1.8352,1.8352,0,0,1,.16,1.8178,17.3743,17.3743,0,0,0-.6943,1.6792A1.8327,1.8327,0,0,1,6.5124,19.874l-4.288.8047A2.0176,2.0176,0,0,0,.5788,22.6611v3.7011a2.0177,2.0177,0,0,0,1.6456,1.9825l4.2871.8042a1.8332,1.8332,0,0,1,1.3994,1.1748,17.4507,17.4507,0,0,0,.6943,1.6782,1.8355,1.8355,0,0,1-.16,1.8179l-2.4629,3.6a2.0179,2.0179,0,0,0,.2392,2.5649l2.6162,2.6172a2.0182,2.0182,0,0,0,2.5655.2383l3.6-2.4624a1.8408,1.8408,0,0,1,1.8183-.16,17.1578,17.1578,0,0,0,1.6787.6939,1.8357,1.8357,0,0,1,1.1739,1.3994l.8047,4.2886a2.0181,2.0181,0,0,0,1.9824,1.645h3.7012a2.0011,2.0011,0,0,0,.7712-.159,3.5647,3.5647,0,0,1-.1-.7956v-2.67a3.5969,3.5969,0,0,1,1.6492-3.0227A31.9678,31.9678,0,0,1,32.6177,40.06Zm-6.7947-5.73a7.6078,7.6078,0,1,1,7.6077-7.6076A7.6078,7.6078,0,0,1,24.311,32.1193Z" />
                <path d="M61.9643,43.5434l-3.0942-.58a1.6825,1.6825,0,0,1-1.2832-1.0815,12.0418,12.0418,0,0,0-.4858-1.1764,1.6826,1.6826,0,0,1,.1433-1.67l1.7768-2.5973a1.0946,1.0946,0,0,0-.1294-1.3919l-1.8882-1.8881a1.0943,1.0943,0,0,0-1.3918-.1294l-2.5974,1.7766a1.6819,1.6819,0,0,1-1.67.1433,12.16,12.16,0,0,0-1.1763-.4857,1.6821,1.6821,0,0,1-1.0815-1.2833l-.5806-3.0943a1.0948,1.0948,0,0,0-1.0757-.8928H44.76a1.0948,1.0948,0,0,0-1.0757.8928l-.5806,3.0943a1.6819,1.6819,0,0,1-1.0813,1.2832,12.2,12.2,0,0,0-1.1765.4858,1.682,1.682,0,0,1-1.67-.1433l-2.5972-1.7766a1.0946,1.0946,0,0,0-1.392.1294L33.2986,35.046a1.0946,1.0946,0,0,0-.1294,1.3919l1.7768,2.5972a1.6824,1.6824,0,0,1,.1433,1.67,12.0623,12.0623,0,0,0-.4858,1.1764A1.6825,1.6825,0,0,1,33.32,42.963l-3.0942.58a1.0949,1.0949,0,0,0-.8929,1.0759v2.67a1.0946,1.0946,0,0,0,.8929,1.0758l3.0942.58a1.6825,1.6825,0,0,1,1.2832,1.0816,12.05,12.05,0,0,0,.4858,1.1763,1.6821,1.6821,0,0,1-.1433,1.67l-1.7768,2.5973a1.0946,1.0946,0,0,0,.1294,1.3919l1.8882,1.8882a1.0945,1.0945,0,0,0,1.392.1294l2.5972-1.7766a1.682,1.682,0,0,1,1.67-.1434,12.15,12.15,0,0,0,1.1765.4858,1.6822,1.6822,0,0,1,1.0813,1.2833l.5806,3.0942a1.0945,1.0945,0,0,0,1.0757.8927h2.67a1.0945,1.0945,0,0,0,1.0757-.8926l.5806-3.0944a1.6822,1.6822,0,0,1,1.0813-1.2832,12.1184,12.1184,0,0,0,1.1765-.4858,1.682,1.682,0,0,1,1.67.1434L55.6116,58.88a1.0947,1.0947,0,0,0,1.392-.1294l1.8882-1.8882a1.0944,1.0944,0,0,0,.1294-1.3918l-1.7768-2.5974a1.6821,1.6821,0,0,1-.1431-1.67,12.0988,12.0988,0,0,0,.4856-1.1763A1.6825,1.6825,0,0,1,58.87,48.9457l3.0942-.58a1.0944,1.0944,0,0,0,.8929-1.0758v-2.67A1.0946,1.0946,0,0,0,61.9643,43.5434ZM46.0952,52.1669a6.2125,6.2125,0,1,1,6.2126-6.2126A6.2126,6.2126,0,0,1,46.0952,52.1669Z" />
              </g>
            </svg>
            <div className="text-gray-600 text-sm sm:text-base flex flex-col gap-1">
              <strong className="text-base sm:text-lg">
                Thank you for your request!
              </strong>
              <span>
                We are working on the migrations. Your old datafiles and results
                will be added to your projects soon.
              </span>
            </div>
          </div>
        }
      />
      <Disclosure as="nav" className="bg-gray-800">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
              <div className="relative flex h-16 items-center justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  {/* Mobile menu button*/}
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                  <NavLink to={"/home"}>
                    <div className="flex flex-shrink-0 items-center">
                      <img
                        className="block h-8 w-auto lg:hidden"
                        src={logo}
                        alt="Your Company"
                      />
                      <img
                        className="hidden h-8 w-auto lg:block"
                        src={logo}
                        alt="Your Company"
                      />
                    </div>
                  </NavLink>
                  <div className="hidden sm:ml-6 sm:block">
                    <div className="flex space-x-4">
                      {updatedNavItems.map((item) => (
                        <NavLink
                          key={item.name}
                          to={item.href}
                          disabled={item.disabled}
                          onClick={(e) => {
                            if (item.disabled) e.preventDefault();
                          }}
                          className={classNames(
                            item.current
                              ? "bg-gray-900 text-white"
                              : item.disabled
                              ? "text-gray-500 cursor-not-allowed"
                              : "text-gray-300 hover:bg-gray-700 hover:text-white",
                            "rounded-md px-3 py-2 text-sm font-medium"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </NavLink>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  <ReportABug />
                  {/* <button
                    type="button"
                    className="rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  >
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button> */}
                  {/* Profile dropdown */}
                  <Menu as="div" className="relative ml-3">
                    <div>
                      <Menu.Button
                        data-testid="navbar-menu-button"
                        className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                      >
                        <span className="sr-only">Open user menu</span>
                        {user && user.photoURL ? (
                          <img
                            src={user.photoURL}
                            className="w-8 h-8 rounded-full"
                            alt=""
                          />
                        ) : (
                          <UserCircleIcon className="w-8 h-8 text-white" />
                        )}
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-50 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/me"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Your Profile
                            </Link>
                          )}
                        </Menu.Item>
                        {/* <Menu.Item>
                          {({ active }) => (
                            <a
                              href="/"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Settings
                            </a>
                          )}
                        </Menu.Item> */}
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={handleLogout}
                              className={classNames(
                                active ? "bg-red-100" : "",
                                "block w-full text-start px-4 py-2 text-sm text-red-700"
                              )}
                            >
                              Sign out
                            </button>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
            <Disclosure.Panel className="sm:hidden">
              <div className="space-y-1 px-2 pb-3 pt-2">
                {updatedNavItems.map((item) => (
                  <NavLink
                    key={item.name}
                    to={item.href}
                    onClick={(e) => {
                      if (item.disabled) e.preventDefault();
                    }}
                    className={classNames(
                      item.current
                        ? "bg-gray-900 text-white"
                        : item.disabled
                        ? "text-gray-500 cursor-not-allowed"
                        : "text-gray-300 hover:bg-gray-700 hover:text-white",
                      "block rounded-md px-3 py-2 text-base font-medium"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </NavLink>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};
