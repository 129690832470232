export const getStatus = (project) => {
	if (!project) {
		return -1;
	} if (!project.details.sampleDefFile && !project.details.dataFiles) {
		return 0;
	} else if (!project.details.sampleDefFile) {
		return 1;
	} else if (!project.details.dataFiles) {
		return 2;
	} else if (!project.result && ![4, 5].includes(project.main.status)) {
		return 3;
	} else if (!project.result && project.main.status === 4) {
		return 4;
	} else if (!project.result && project.main.status === 5) {
		return 5;
	} else {
		return 6;
	}
};