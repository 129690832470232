/* Component for profile page route '/me'. The user can read and update their own data. */

import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { motion, AnimatePresence } from "framer-motion";
// firebase functions
import { getAuth, updateProfile } from "firebase/auth";
import { getDatabase, onValue, ref, update } from "firebase/database";
import { getStorage, ref as strgRef, deleteObject } from "firebase/storage";
import { uploadFile } from "features/projects/actions";
import { handleLogout } from "features/authentication/actions";
// Components
import { Navbar, PageFrame, Header, Background } from "layouts";
import ProfileFormSection from "layouts/ProfileFormSection";
import PasswordsForm from "features/authentication/components/PasswordsForm";
import PersonalDetailsForm from "features/authentication/components/PersonalDetailsForm";
import DeactivateProfileSection from "features/authentication/components/DeactivateProfileSection";
// Icons
import {
  UserCircleIcon,
  ArrowRightOnRectangleIcon,
  ChevronLeftIcon,
  ArrowSmallLeftIcon,
} from "@heroicons/react/24/outline";
import { MdOutlineAdd } from "react-icons/md";
import { ImBin } from "react-icons/im";
// Profile css
import "../style/profile.css";
// Profile context
import { useProfileContext } from "pages/contexts/profileContext";

export function Profile({ isLoading = true }) {
  const user = getAuth().currentUser;
  const [userData, setUserData] = useState(user);
  const db = getDatabase();
  const storage = getStorage();
  const userRef = ref(db, `users/${user?.uid}`);
  const profileImageElementRef = useRef(null);

  // Import required context variables/functions
  const {
    isGoogleProfile,
    setIsGoogleProfile,
    combinedUserData,
    setCombinedUserData,
    fadeIn,
    passwordFormIsUpdating,
    isPasswordEditable,
    setIsPasswordEditable,
    personalDetailsFomIsUpdating,
    isEditable,
    setIsEditable,
  } = useProfileContext();

  // Combine the user data from Authentication and Realtime Database
  useEffect(() => {
    setCombinedUserData({ ...user, ...userData });
  }, [userData, user]);

  // Get user data from 'userRef' variable when the 'isLoading' state change
  useEffect(() => {
    onValue(userRef, (snapshot) => {
      setUserData(snapshot.val());
    });
  }, [isLoading]);

  // Update the profile picture in real time database
  const setProfilePicURL = (user, url) => {
    updateProfile(user, {
      photoURL: url,
    })
      .then(() => {
        /* console.log("Profile updated"); */
        update(userRef, { photoURL: url });
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  // Handle input value changes
  const handleInputValueChangeProfilePicture = (event) => {
    const newPic = event.target.files[0]; // new pricture value

    try {
      if (userData.photoURL) {
        const profilePicRef = strgRef(storage, user.photoURL);
        deleteObject(profilePicRef);
      }
      uploadFile(
        newPic,
        strgRef(
          storage,
          "users/" + user.uid + "/profilePicture/" + newPic.name
        ),
        (url) => { setProfilePicURL(user, url) },
        () => {},
        0
      );
    } catch (err) {
      console.log(`%c ${err.message}`, "color: red");
    }
  };

  // Delete profile picture form database and page
  const handleDeleteProfilePicture = () => {
    if (!userData.photoURL) return;
    const profilePicRef = strgRef(storage, user?.photoURL);
    deleteObject(profilePicRef);
    setProfilePicURL(user, "");
    profileImageElementRef.current.value = "";
  };

  // Get user authentication type (password/google.com) when 'isLoading' variable changes
  useEffect(() => {
    const providerID = user?.providerData[0]?.providerId;
    if (providerID) {
      // set true when 'providerID' is 'google.com', false when the value is anything else
      setIsGoogleProfile((prevState) =>
        providerID === "google.com" ? true : false
      );
    }
  }, [user?.providerData, isLoading]);

  return (
    <Background>
      <Navbar currentNavItem={""} />
      <Header>
        <div className="relative flex justify-between">
          <div className="flex items-center text-gray-500 hover:text-gray-600">
            <Link to={"/home"} className="flex">
              <ArrowSmallLeftIcon className="w-6 h-6 text-gray-500 hover:text-gray-600" />
            </Link>
          </div>
          <div className="grid-cols-1 content-center w-auto max-w-full px-4 sm:px-6 lg:px-8">
            <AnimatePresence initial={false}>
              {isLoading || !combinedUserData.lastName ? (
                <motion.div
                  initial="initial"
                  animate="animate"
                  variants={fadeIn}
                  role="status"
                  className="w-full animate-pulse"
                  data-testid="skeleton-loading"
                >
                  <div className="h-7 bg-gray-300 rounded-full dark:bg-gray-700 w-60"></div>
                  <span className="sr-only">Loading...</span>
                </motion.div>
              ) : (
                <motion.h1
                  initial="initial"
                  animate="animate"
                  variants={fadeIn}
                  className="text-lg sm:text-xl font-bold mx-2 tracking-tight text-gray-700"
                >{`${combinedUserData.firstName} ${combinedUserData.lastName}`}</motion.h1>
              )}
            </AnimatePresence>
          </div>
          <div className="flex  items-center">
            <ArrowRightOnRectangleIcon
              onClick={handleLogout}
              className="w-6 h-6 text-red-400 cursor-pointer hover:text-red-500"
            />
          </div>
        </div>
      </Header>
      <PageFrame>
        <div className="flex justify-center py-6">
          <motion.label
            htmlFor="profile-upload-input"
            className="relative w-32 h-32 rounded-full"
          >
            <AnimatePresence initial={false}>
              {isLoading || !combinedUserData.lastName ? (
                <motion.div
                  initial="initial"
                  animate="animate"
                  variants={fadeIn}
                  role="status"
                  className="space-y-8 animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center"
                >
                  <div className="flex items-center justify-center w-32 h-32 bg-gray-300 rounded-full dark:bg-gray-700">
                    <svg
                      className="w-12 h-12 text-gray-200"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 640 512"
                    >
                      <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                    </svg>
                  </div>
                  <span className="sr-only">Loading...</span>
                </motion.div>
              ) : (
                <>
                  {user && user?.photoURL ? (
                    <>
                      <AnimatePresence>
                        <motion.img
                          key={1}
                          initial="initial"
                          animate="animate"
                          variants={fadeIn}
                          src={user?.photoURL}
                          className={`w-32 h-32 transition duration-75 rounded-full cursor-pointer`}
                          alt="profile-pic"
                        />
                        <motion.div className="relative">
                          <motion.div
                            key={2}
                            initial="initial"
                            animate="animate"
                            variants={fadeIn}
                            className="flex justify-center cursor-pointer absolute z-10001 right-1 bottom-1 w-[36px] h-[36px] bg-white/100 py-1 px-1 rounded-full shadow-md"
                            onClick={(e) => {
                              e.preventDefault();
                              handleDeleteProfilePicture();
                            }}
                          >
                            <ImBin className="text-red-600 self-center text-lg cursor-pointer shadow-2xl" />
                          </motion.div>
                        </motion.div>
                        <motion.div
                          key={3}
                          initial="initial"
                          animate="animate"
                          variants={fadeIn}
                          onClick={(e) => {
                            e.preventDefault();
                            handleDeleteProfilePicture();
                          }}
                          style={{
                            position: "absolute",
                            zIndex: 1000,
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                          className="w-32 h-32 rounded-full cursor-pointer"
                        ></motion.div>
                      </AnimatePresence>
                    </>
                  ) : (
                    <AnimatePresence>
                      <motion.div
                        key={4}
                        initial="initial"
                        animate="animate"
                        variants={fadeIn}
                        className="relative"
                      >
                        <UserCircleIcon
                          key={10}
                          data-testid="profile-image"
                          className="w-32 h-32 text-gray-500 cursor-pointer hover:text-gray-600"
                        />
                        <motion.div
                          key={11}
                          initial="initial"
                          animate="animate"
                          variants={fadeIn}
                          className="flex justify-center cursor-pointer absolute right-1 bottom-1 w-[36px] h-[36px] bg-white/100 py-1 px-1 rounded-full shadow-md"
                        >
                          <MdOutlineAdd className="text-primary-blue-light self-center text-2xl cursor-pointer shadow-2xl" />
                        </motion.div>
                      </motion.div>
                    </AnimatePresence>
                  )}
                </>
              )}
            </AnimatePresence>
          </motion.label>
          <input
            id="profile-upload-input"
            onChange={handleInputValueChangeProfilePicture}
            type="file"
            data-testid="upload-profile"
            accept="image/*"
            className="hidden"
            ref={profileImageElementRef}
          />
        </div>
        <ProfileFormSection
          title="Profile Information"
          description="Personal details and profile settings."
          editModeButtonTitle="Edit profile data"
          isEditable={isEditable}
          setIsEditable={setIsEditable}
          isUpdateLoading={personalDetailsFomIsUpdating}
        >
          <PersonalDetailsForm />
        </ProfileFormSection>
        {!isGoogleProfile && isGoogleProfile !== null && (
          <ProfileFormSection
            title="Password"
            description=" Section for password modification"
            editModeButtonTitle="change password"
            isEditable={isPasswordEditable}
            setIsEditable={setIsPasswordEditable}
            isUpdateLoading={passwordFormIsUpdating}
          >
            <PasswordsForm />
          </ProfileFormSection>
        )}
      </PageFrame>
      <DeactivateProfileSection />
    </Background>
  );
}
