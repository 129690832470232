/* This component displays a red button with the user can turn off edit mode. When the user clicks on the button changes the editable state. If 'isEditable' is true than the input fields become enabled, if false then input fields become disabled. */

import React, { useState, useEffect } from "react";
import { useProfileContext } from "pages/contexts/profileContext";
import { motion } from "framer-motion";

const ProfilePageCancelButton = ({
  content,
  isEditable,
  isUpdateLoading,
  onClickFunc,
  buttonRef = null,
  fullWidth = false,
  children,
}) => {
  const { buttonInAndOut, preventDoubleClick } = useProfileContext(); // import required context variables
  const [isLoadingStarted, setIsLoadingStarted] = useState(false);

  // Prevent ui bugs
  useEffect(() => {
    let timeout = null;
    if (isUpdateLoading) {
      timeout = setTimeout(() => {
        setIsLoadingStarted(true);
      }, [500]);
    } else {
      setIsLoadingStarted(false);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isUpdateLoading]);

  return (
    <motion.div
      className={`flex justify-center self-center select-none ${
        fullWidth ? "w-full" : ""
      }`}
    >
      <motion.button
        ref={buttonRef}
        initial="initial"
        animate={isEditable ? "show" : "hide"} // if true then show the button else hide the button
        variants={buttonInAndOut}
        data-testid="cancel-button"
        onClick={onClickFunc}
        onDoubleClick={preventDoubleClick}
        type="button"
        className={`profile-page-fail-button ${fullWidth ? "w-full" : ""}`}
        disabled={isLoadingStarted}
      >
        {content}
        {children}
      </motion.button>
    </motion.div>
  );
};

export default ProfilePageCancelButton;
