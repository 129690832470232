import { useParams } from "react-router";
import { useState, useEffect } from "react";
import {
  Background,
  Navbar,
  Header,
  SidebarFrame,
  SidebarDropdown,
  SidebarItem,
} from "layouts";
import { ArrowSmallLeftIcon } from "@heroicons/react/24/outline";
import { PencilIcon } from "@heroicons/react/24/solid";
import { TrashIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
import { getDatabase, ref as dbRef, onValue, update } from "firebase/database";
import { database, auth } from "../../firebase";
import { DeleteModal, ProjectDetails } from "features/projects";
import {} from "layouts";
import { handleDeleteProject } from "features/projects/actions";
import { useNavigate } from "react-router-dom";
import { ChartTable } from "features/projects/components/graphs";
import logo from "assets/images/logo-icon.png";
import {
  ProjectDetailsHelp,
  CouplexChartsHelp,
  LambdaChartsHelp,
  SettingsHelp,
} from "features/projects/components/helpPages";
import { toast } from "react-toastify";

export const Project = () => {
  const { projectID } = useParams();
  const [deleteProjectOpen, setDeleteProjectOpen] = useState(false);
  const [editingName, setEditingName] = useState(false);
  const navigate = useNavigate();

  const db = getDatabase();
  const projectMainRef = dbRef(db, `projects/${projectID}/main`);
  const [projectMainData, setProjectMainData] = useState();

  useEffect(() => {
    onValue(projectMainRef, (snapshot) => {
      let data = snapshot.val();
      setProjectMainData(data);
    });
  }, []);

  useEffect(() => {
    if (!projectMainData) return;
    if (!auth.currentUser) return;
    if (!projectMainData.accessorIDs.includes(auth.currentUser.uid)) {
      toast.error("You don't have access to this project");
      navigate("/home");
    }
  }, [projectMainData, navigate]);

  function handleNameChange(event) {
    //console.log(event.target.value);
    setProjectMainData({
      ...projectMainData,
      name: event.target.value,
    });
  }
  function handleNameBlur(event) {
    setEditingName(false);
    try {
      update(dbRef(database, "projects/" + projectID + "/main"), {
        name: event.target.value,
      });
    } catch (error) {
      console.error(error);
    }
  }
  function deleteProject() {
    handleDeleteProject(projectID);
    setDeleteProjectOpen(false);
    navigate("/home");
  }

  return (
    <Background isFullScreen={true}>
      <Navbar currentNavItem={"Dashboard"} />
      {projectMainData && (
        <>
          <Header>
            <div className="relative flex justify-between md:grid md:grid-cols-[75px_auto_75px]">
              <div className="flex items-center">
                <Link
                  to={"/home"}
                  data-testid="project-homebutton"
                  className="flex items-center text-gray-500 hover:text-gray-600"
                >
                  <ArrowSmallLeftIcon className="w-6 h-6 mr-2" />
                  <span className="hidden md:block">Projects</span>
                </Link>
              </div>
              <div className="flex w-auto max-w-full px-4 sm:px-6 lg:px-8 self-center justify-center">
                <h1 className="text-xl font-bold mx-2 tracking-tight text-gray-900">
                  Project:
                </h1>
                {editingName ? (
                  <input
                    onChange={handleNameChange}
                    autoFocus
                    onBlur={handleNameBlur}
                    value={projectMainData.name}
                    type="text"
                    className="text-base text-gray-500 py-0 focus:outline-none"
                  ></input>
                ) : (
                  <div className="flex items-center">
                    <span className="text-xl font-semibold text-gray-500">
                      {projectMainData.name}
                    </span>
                    <PencilIcon
                      onClick={() => setEditingName(true)}
                      className="w-5 h-5 mx-3 font-bold text-gray-300 cursor-pointer hover:text-gray-400"
                    ></PencilIcon>
                  </div>
                )}
              </div>
              <div className="flex items-center justify-end">
                <TrashIcon
                  onClick={() => setDeleteProjectOpen(true)}
                  className="w-6 h-6 text-red-400 cursor-pointer hover:text-red-500"
                />
              </div>
            </div>
          </Header>
          <SidebarFrame>
            <SidebarItem
              itemProps={{
                title: "Project Details",
                icon: "BookMarkIcon",
              }}
              content={<ProjectDetails projectID={projectID} />}
              tips={<ProjectDetailsHelp />}
            />
            {projectMainData.status > 3 && (
              <SidebarDropdown
                itemProps={{
                  title:
                    projectMainData.status === 4 ? "Calculating" : "Results",
                  icon:
                    projectMainData.status === 4
                      ? "LoadingIcon"
                      : "ChartBarIcon",
                  disabled: projectMainData.status === 4 ? true : false,
                }}
                // content={<div>Results</div>}
                tips={<div>Results</div>}
              >
                <SidebarItem
                  itemProps={{
                    title: "Couplex charts",
                    icon: "ChartBarIcon",
                  }}
                  content={
                    <ChartTable
                      rawData={{}}
                      title="Couplex charts"
                      type="couplex"
                      projectTitle={projectMainData.name}
                      projectID={projectID}
                    />
                  }
                  tips={<CouplexChartsHelp />}
                />
                <SidebarItem
                  itemProps={{
                    title: "Lambda charts",
                    icon: "ChartBarIcon",
                  }}
                  content={
                    <ChartTable
                      rawData={{}}
                      title="Lambda charts"
                      type="lambda"
                      projectTitle={projectMainData.name}
                      projectID={projectID}
                    />
                  }
                  tips={<LambdaChartsHelp />}
                ></SidebarItem>
              </SidebarDropdown>
            )}
            <SidebarItem
              itemProps={{
                title: "Settings",
                icon: "Cog8ToothIcon",
              }}
              content={
                <>
                  <div className=" sm:mx-auto sm:w-full sm:max-w-sm py-12">
                    <img
                      className="mx-auto h-20 w-auto"
                      src={logo}
                      alt="Your Company"
                    />
                  </div>
                  <div className="mb-12 mx-auto max-w-2xl text-center">
                    <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                      Project Settings
                    </p>
                    <p className="mt-2 text-xl font-bold tracking-tight text-gray-600 sm:text-xl">
                      Under construction...
                    </p>
                  </div>
                </>
              }
              tips={<SettingsHelp />}
            />
          </SidebarFrame>
        </>
      )}
      <DeleteModal
        open={deleteProjectOpen}
        setOpen={setDeleteProjectOpen}
        deleteFunction={deleteProject}
        objectName={"project"}
      />
    </Background>
  );
};
