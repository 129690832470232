import React from 'react';
import {sendPasswordResetEmail} from 'firebase/auth';
import {auth} from '../../firebase';
import {useNavigate} from 'react-router-dom'
import {ForgotPasswordForm} from 'features/authentication';
import {toast} from 'react-toastify';

export const ForgotPassword = () => {
    const navigate = useNavigate();

    const onForgotPassword = (email) => {
        //console.log("forgot_password");
        sendPasswordResetEmail(auth, email)
            .then(() => {
                // Email sent
                toast.success(
                    "An email has been sent to the given address. Check your mailbox to reset your password.",
                    {
                        position: "top-center",
                    }
                )

                navigate("/login")
                console.log('Password reset email sent');
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage)
            });

    }

    return (
        <>
            <main>
                <section>
                    <ForgotPasswordForm onForgotPassword={onForgotPassword}/>
                </section>
            </main>
        </>
    )
}