import { uploadBytesResumable, getDownloadURL } from "firebase/storage";

export const uploadFilePromise = (file, storageRef, setDataRef, setPercent=()=>{}) => {
        
    return new Promise((resolve, reject) => {

        const uploadTask = uploadBytesResumable(storageRef, file);
        let downloadURL = "";

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const percent = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );

                // update progress
                setPercent(percent);
            },
            (err) => console.log(err),
            () => {
                // download url
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    // dataAction(dataRef, url);
                    resolve(url);
                    setDataRef(url);
                });
                setPercent(0);
            }
        );
    });
}