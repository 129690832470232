import React, { useState, useEffect } from 'react';
import image1 from 'assets/images/logo-icon.png';

export const LoadingPage = () => {
  const [angle, setAngle] = useState(0);
  const [size, setSize] = useState(40);

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="flex items-center justify-center">
        <img
          src={image1}
          className="w-24 h-auto animate-bounce px-3"
          alt="Loading"
        />
        <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-6xl">
            Loading...
        </h2>
      </div>
    </div>
  );
};
