/* This component contains one animated submit button for profile page. This components display a green button. 'isEditable' variable state decide when to animate in and when to hide the button. If 'isUpdateLoading' variable is true then show a spinner and the button state changes to disabled*/

import React, { useEffect, useState } from "react";
import { useProfileContext } from "pages/contexts/profileContext";
import Lottie from "lottie-react";
// loading gif
import loadingAnimation from "../../assets/lotties/loadingBars.json";
import { motion } from "framer-motion";

const ProfilePageSubmitButton = ({
  content,
  isUpdateLoading,
  isEditable,
  isDisabled = false,
}) => {
  const { buttonInAndOut, preventDoubleClick } = useProfileContext(); // import required context variables/functions
  const [isLoadingStarted, setIsLoadingStarted] = useState(false);

  // Prevent ui bugs
  useEffect(() => {
    let timeout = null;
    if (isUpdateLoading) {
      timeout = setTimeout(() => {
        setIsLoadingStarted(true);
      }, [500]);
    } else {
      setIsLoadingStarted(false);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isUpdateLoading]);

  return (
    <motion.div className="flex justify-end self-center select-none">
      <motion.button
        initial="initial"
        animate={isEditable ? "show" : "hide"}
        variants={buttonInAndOut}
        onDoubleClick={preventDoubleClick}
        data-testid="submit-button"
        disabled={isDisabled}
        type="submit"
        className="profile-page-success-button"
      >
        {isLoadingStarted ? (
          <div className="flex justify-center self-center w-20">
            <Lottie
              className="w-7"
              animationData={loadingAnimation}
              loop={true}
            />
          </div>
        ) : (
          content
        )}
      </motion.button>
    </motion.div>
  );
};

export default ProfilePageSubmitButton;
