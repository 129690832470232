import React, { useState, useRef, useEffect } from "react";
import { useProfileContext } from "pages/contexts/profileContext";
import { motion, AnimatePresence } from "framer-motion";
import Countdown, { zeroPad } from "react-countdown";
import { getAuth } from "firebase/auth";
import { getDatabase, ref, remove, onValue } from "firebase/database";
import { toast } from "react-toastify";
import { Formik, Form } from "formik";
import {
  handleGoogleReauthentication,
  handleReauthentication,
} from "../actions";
import * as Yup from "yup";
import Lottie from "lottie-react";
// icons
import { BsFillQuestionCircleFill } from "react-icons/bs";
import loadingAnimation from "../../../assets/lotties/loadingBars.json";
// components
import { PageFrame } from "layouts";
import { FailButton, NeutralButton } from "components/buttons";
import { Modal } from "components";
import NewProfilDataItem from "layouts/ProfilDataItem";
import ProfilePageCancelButton from "components/buttons/ProfilePageCancelButton";

const DeactivateProfileSection = () => {
  const {
    isLoading,
    combinedUserData,
    fadeIn,
    fadeInOutModal,
    preventDoubleClick,
    isGoogleProfile,
    handleButtonState,
  } = useProfileContext();

  const user = getAuth().currentUser;
  const database = getDatabase();
  const projectsRef = ref(database, "projects/");
  const verifyPasswordFormRef = useRef(null); // ref to get password field value
  const [isModalActiave, setIsModalActive] = useState(false);
  const [startDeactivation, setStartDeactivation] = useState(false); // state for when user clicks on the modal deactivation button
  const clockRef = useRef(null); // Ref for 'Countdown' component
  const formikRef = useRef(null); // Formik ref
  const [haveErrors, setHaveErrors] = useState(true); // state to disable submit button when is is true
  const deactivateButtonRef = useRef();
  const cancelButtonRef = useRef();

  useEffect(() => {
    if (isGoogleProfile) {
      setHaveErrors(false); // enable submit button if current user provider is google
    }

    // Reseting formik values and errors
    const formik = formikRef.current;
    return () => {
      if (isModalActiave) {
        setHaveErrors(true); // reset state value to disable again the submit button
        formik?.resetForm(); // reseting form values/errors
      }
    };
  }, [isModalActiave]);

  // Handling deactivation when the countdown is completed
  const handleDeactivation = async () => {
    try {
      console.log("deactivate user");

      handleButtonState(deactivateButtonRef, true);
      handleButtonState(cancelButtonRef, true);
      if (isGoogleProfile) {
        await handleGoogleReauthentication(); // authenticate google user
      } else {
        await handleReauthentication(
          user.email,
          verifyPasswordFormRef.current[0].value
        ); // authenticate registered user
      }

      onValue(projectsRef, (snapshot) => {
        // get all projects by user id
        const data = Object.entries(snapshot.val())
          .filter(([key, value]) => value.creatorID === user.uid)
          .map(([key, project]) => ({
            id: key,
          }));

        // remove all projects that are belongs to the current user
        data.forEach(async (project) => {
          await remove(ref(database, "projects/" + project.id));
        });
      });

      // remove current user data from 'users' table
      await remove(ref(database, "users/" + user.uid));

      // delete current user
      await user?.delete();

      // hide modal
      setIsModalActive(false);
      // show success message
      toast.success("Current User Successfully Deactivated!");
    } catch (error) {
      // Handling error messages
      const errorCode = error.code;
      const errorMessage = error.message;
      let toastError;
      switch (errorCode) {
        case "auth/wrong-password":
          toastError = "Verify password is incorrect.";
          break;
        case "auth/missing-password":
          toastError = "Verify password is missing.";
          break;
        case "auth/weak-password":
          toastError = "New Password should be at least 8 characters.";
          break;
        case "auth/requires-recent-login":
          toastError = "Recent login required!";
          break;
        case "auth/network-request-failed":
          toastError = "Make sure you are connected to the internet!";
          break;
        default:
          toastError = `Update failed. (${errorMessage})`;
          break;
      }
      handleButtonState(deactivateButtonRef, true);
      handleButtonState(cancelButtonRef, false);
      // hide clock
      setStartDeactivation(!startDeactivation);
      // logging errors
      console.log(error);
      toast.error(toastError);
    }
  };

  // Pause countdown when user cancels deactivation
  const handlePause = () => {
    clockRef?.current?.pause();
  };

  // Renderer for countdown component which is set display value and when the countdown is completed then show a spinner
  const renderer = ({ hours, minutes, seconds, completed }) => {
    // Render a countdown
    if (completed) {
      return (
        <Lottie className="w-7" animationData={loadingAnimation} loop={true} />
      );
    }
    // return with formatted display clock and padding two digit
    return (
      <span>
        {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
      </span>
    );
  };

  // Validation Schema for formik
  const validationSchema = Yup.object({
    verifyDeactivationPassword: Yup.string("Enter your current password")
      .min(8, "Must be at least 8 characters")
      .required("Verify password is required"),
  });

  // When the user typing this function will be fired. If 'haveErrors' is true then disable deactivation button.
  const handleChange = async (e) => {
    try {
      await validationSchema.validate({
        verifyDeactivationPassword: e.target.value,
      });
      setHaveErrors(false); // enable submit button
    } catch (error) {
      setHaveErrors(true); // disable submit button if have any errors
    }
  };

  return (
    <div data-testid="deactivate-profile-section">
      <AnimatePresence initial={false}>
        <motion.div
          data-testid="deactivate-profile-modal"
          initial="initial"
          animate={isModalActiave ? "in" : "out"}
          variants={fadeInOutModal}
        >
          <Modal
            isMobileView={true}
            title="Deactivation"
            icon={
              <BsFillQuestionCircleFill className="text-5xl text-custom-gray" />
            }
            text="Are you sure you want to deactivate this profile?"
            buttonsArray={[
              !isGoogleProfile && (
                <Formik
                  key={20}
                  innerRef={formikRef}
                  initialValues={{ verifyDeactivationPassword: "" }}
                  validationSchema={validationSchema}
                >
                  {({ errors, values }) => (
                    <Form
                      onChange={handleChange}
                      ref={verifyPasswordFormRef}
                      onSubmit={(e) => e.preventDefault()}
                    >
                      <NewProfilDataItem
                        inputProps={{
                          label: "Verify Password",
                          name: "verifyDeactivationPassword",
                          placeholder: "●●●●●●●●●●●",
                          disabled: startDeactivation,
                          inputType: "password",
                        }}
                        value={values.verifyDeactivationPassword}
                        isEditable={true}
                        isMobileView={true}
                        errors={errors.verifyDeactivationPassword}
                      ></NewProfilDataItem>
                    </Form>
                  )}
                </Formik>
              ),
              <NeutralButton
                key={1}
                buttonRef={cancelButtonRef}
                onClick={() => {
                  handlePause(); // Reset clock state
                  setIsModalActive(!isModalActiave); // close modal
                }}
              >
                Cancel
              </NeutralButton>,
              <ProfilePageCancelButton
                fullWidth={true}
                isEditable={true}
                isUpdateLoading={haveErrors}
                onClickFunc={() => setStartDeactivation(true)}
                key={2}
                buttonRef={deactivateButtonRef}
              >
                {startDeactivation ? (
                  <Countdown
                    ref={clockRef}
                    date={Date.now() + 5000}
                    autoStart={true}
                    renderer={renderer}
                    onComplete={handleDeactivation}
                    onPause={() => {
                      // Delaying state change to wait until modal fade out is finished
                      setTimeout(() => {
                        setStartDeactivation(false);
                      }, 400);
                    }}
                  ></Countdown>
                ) : (
                  "Deactivate"
                )}
              </ProfilePageCancelButton>,
            ]}
          ></Modal>
        </motion.div>
      </AnimatePresence>
      <PageFrame>
        <div className="flex flex-col sm:flex-row gap-2 sm:gap-0 items-center justify-between my-3 sm:my-5">
          <div className="mx-3">
            <AnimatePresence initial={false}>
              {isLoading || !combinedUserData?.lastName ? (
                <>
                  <motion.div
                    initial="initial"
                    animate="animate"
                    variants={fadeIn}
                    role="status"
                    className="w-full animate-pulse self-center"
                  >
                    <div className="h-6 sm:h-6 md:h-7 bg-gray-300 rounded-full dark:bg-gray-700 w-[180px] sm:w-[190px] mb-2 sm:mb-2 m-auto sm:mr-0 sm:ml-0 sm:mt-0"></div>
                    <div className="h-5 sm:h-5 bg-gray-300 rounded-full dark:bg-gray-700 w-[250px] sm:w-[250px] m-auto "></div>
                    <span className="sr-only">Loading...</span>
                  </motion.div>
                </>
              ) : (
                <>
                  <motion.h1
                    initial="initial"
                    animate="animate"
                    variants={fadeIn}
                    data-testid="deactivate-profile-title"
                    className="capitalize text-lg md:text-xl text-center sm:text-left font-bold tracking-tight text-other-red"
                  >
                    deactivate profile
                  </motion.h1>
                  <motion.p
                    initial="initial"
                    animate="animate"
                    variants={fadeIn}
                    data-testid="deactivate-profile-text"
                    className="mt-1 max-w-2xl text-sm md:text-base leading-6 text-other-red text-center sm:text-left"
                  >
                    Once you deactivate a profile, there is no going back.
                    Please be certain.
                  </motion.p>
                </>
              )}
            </AnimatePresence>
          </div>
          <div className="mx-3" style={{ display: "flex", gap: "1rem" }}>
            <AnimatePresence initial={false}>
              {isLoading || !combinedUserData?.lastName ? (
                <>
                  <motion.div
                    initial="initial"
                    animate="animate"
                    variants={fadeIn}
                    role="status"
                    className="max-w-sm animate-pulse"
                  >
                    <div className="h-5 bg-gray-300 rounded-full dark:bg-gray-700 w-40 mt-2"></div>
                    <span className="sr-only">Loading...</span>
                  </motion.div>
                </>
              ) : (
                <>
                  <motion.div
                    initial="initial"
                    animate="animate"
                    variants={fadeIn}
                    onDoubleClick={preventDoubleClick}
                  >
                    <FailButton
                      onClick={() => setIsModalActive(!isModalActiave)}
                      data-testid="deactivate-profile-button"
                    >
                      Deactivate this profile
                    </FailButton>
                  </motion.div>
                </>
              )}
            </AnimatePresence>
          </div>
        </div>
      </PageFrame>
    </div>
  );
};

export default DeactivateProfileSection;
