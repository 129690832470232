import { useState } from "react";
import { set } from "firebase/database";
import {
	getStorage,
	ref as strgRef,
	uploadBytesResumable,
	getDownloadURL,
	deleteObject,
} from "firebase/storage";
import { DeleteModal } from "./DeleteModal";
import { checkFileExtension } from "../actions";
import { toast } from "react-toastify";
import {
	FolderArrowDownIcon,
	ArrowPathIcon,
} from "@heroicons/react/24/outline";
import { TrashIcon } from "@heroicons/react/24/solid";

export const FileVisualizer = ({
	fileURL,
	dataRef,
	onFileChange = () => {},
}) => {
	const storage = getStorage();
	const fileRef = strgRef(storage, fileURL);
	const fileName = fileRef.name;
	const [percent, setPercent] = useState(0);
	const [delOpen, setDelOpen] = useState(false);

	const handleFileChange = (event) => {
		const newFile = event.target.files[0];
		if (!checkFileExtension(newFile)) {
			toast.error(`File extension is not suported!`, {
				position: "top-center",
			});
			return;
		}

		deleteObject(fileRef);
		const storageRef = strgRef(
			storage,
			`${fileRef.parent}/${newFile.name}`
		);

		// progress can be paused and resumed. It also exposes progress updates.
		// Receives the storage reference and the file to upload.
		const uploadTask = uploadBytesResumable(storageRef, newFile);

		uploadTask.on(
			"state_changed",
			(snapshot) => {
				const percent = Math.round(
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100
				);

				// update progress
				setPercent(percent);
			},
			(err) => console.log(err),
			() => {
				// download url
				getDownloadURL(uploadTask.snapshot.ref).then((url) => {
					set(dataRef, url);
				});
				setPercent(0);
				onFileChange();
			}
		);
	};
	const handleFileDelete = () => {
		deleteObject(fileRef);
		set(dataRef, null);
		setDelOpen(false);
		onFileChange();
	};

	return (
		<>
			<div data-testid="FileVisualizer" className="grid grid-cols-[1fr_minmax(0,_1fr)]">
				{percent === 0 ? (
					<a
						href={fileURL}
						className="flex justify-center items-center py-2 px-4 w-max shadow-md border border-gray-700 text-gray-700 cursor-pointer rounded-full hover:bg-gray-200"
					>
						<p className="text-sm font-bold whitespace-nowrap overflow-hidden text-ellipsis max-w-[100px] sm:max-w-[150px] lg:max-w-[250px]">{fileName}</p>
						<FolderArrowDownIcon className="w-5 h-5 ml-2" />
					</a>
				) : (
					<div className="flex pb-1">
						<label className="flex justify-center items-center px-2 w-max shadow-md border border-gray-700 text-gray-700 cursor-pointer rounded-full hover:bg-gray-200">
							<p className="pl-2 text-sm font-bold">
								Uploading...
							</p>
						</label>
						<div
							className="radial-progress text-blue-600 mx-3"
							style={{
								"--value": percent,
								"--size": "2rem",
								"--thickness": "4px",
							}}
						></div>
					</div>
				)}
				<div className="flex">
					<label data-testid="FileVisualizer-reupload" className=" text-blue-600 bg-blue-50 border border-blue-500 cursor-pointer hover:bg-blue-600 hover:text-white font-medium rounded-full text-sm px-2 py-2 shadow-md ml-6 ">
						<ArrowPathIcon className="w-5 h-5 " />
						<input
							id="dropzone-file"
							data-testid="FileVisualizer-fileInput"
							onChange={handleFileChange}
							type="file"
							accept=".xls,.xlsx,.csv"
							className="hidden"
						/>
					</label>
					<button
                        data-testid="FileVisualizer-delete"
						onClick={() => setDelOpen(true)}
						type="button"
						className="flex text-red-600 bg-red-50 hover:bg-red-600 hover:text-white border border-red-500 font-medium rounded-full text-sm px-2 py-2 text-center shadow-md ml-3 "
					>
						<TrashIcon className="w-5 h-5 " />
					</button>
				</div>
			</div>
			<DeleteModal
				open={delOpen}
				setOpen={setDelOpen}
				deleteFunction={handleFileDelete}
				objectName={"file"}
			/>
		</>
	);
};
