import { NeutralButton, NextButton } from "components/buttons";
import { Textarea } from "flowbite-react";
import { Formik, Form, useField } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { set, ref, getDatabase } from "firebase/database"
import { getAuth } from "firebase/auth";
import { v4 as uuidv4 } from "uuid";
import { forwardRef, useEffect } from "react";

const FormikTextarea = ({ label, ...props }) => {

    const [field, meta] = useField(props);
 
    return (
        <>
            <label>
                {label}
                <Textarea {...field} {...props} style={{
                    outline: meta.error && meta.touched ? "2px solid #e53e3e" : "none",
                }} />
            </label>
            {meta.touched && meta.error ? (
            <div className="flex justify-end text-[#e53e3e] mt-2">
                <p>{meta.error}</p>
            </div>
            ) : null}
        </>
    );
};

function writeReportData(message) {
    const db = getDatabase();
    const auth = getAuth();
    set(ref(db, 'reports/' + uuidv4()), {
        name: auth.currentUser.displayName ?? "Anonymous",
        email: auth.currentUser.email ?? "None",
        path: window.location.pathname,
        time: new Date().toLocaleString("hu-HU", {timeZone: "Europe/Berlin"}),
        message,
    });
  }

  const ReportABugForm = forwardRef(({ closeTooltip }, ref) => {

    useEffect(() => {
        const msg = sessionStorage.getItem("reportBugMessage");
        if (msg && msg !== "") {
            ref.current?.setFieldValue("message", msg);
        }
    }, [ref]);

    return <div className="p-4 w-[300px] sm:w-[400px]" data-testid="report-form-container">
        <Formik
            innerRef={ref}
            initialValues={{
                message: "",
            }}
            onSubmit={(values) => {
                //console.log(values);
                closeTooltip();
                writeReportData(values.message);
                sessionStorage.setItem("reportBugMessage", "");
                toast.success("Your bug report has been submitted!");
            }}
            validationSchema={
                Yup.object().shape({
                    message: Yup.string().required("Please fill out this form before submitting.").max(5000, "Must be 5000 characters or less."),
                })
            }
        >
            <Form>
                <div className="mb-4">
                    <p className="text-lg">Contact us!</p>
                    <p className="text-xs text-gray-500 font-normal">Reach out to us for bug reports, questions, clarifications, or suggestions for new features and improvements.</p>
                </div>
                <FormikTextarea
                    data-testid="message-input"
                    id="message"
                    name="message"
                    label=""
                    placeholder="Type your message here..."
                    rows={6}
                    className=" resize-none h-[300px] sm:h-auto sm:resize-y max-h-[400px] min-h-[100px] min-w-full text-sm font-normal text-gray-700"
                />
                <div className="flex mt-1 sm:mt-4 gap-2 justify-end">
                    <NeutralButton onClick={closeTooltip} data-testid="report-cancel-button">Cancel</NeutralButton>
                    <NextButton type="submit" data-testid="report-submit-button">Send Report</NextButton>
                </div>
            </Form>
        </Formik>
    </div>
});

export default ReportABugForm;