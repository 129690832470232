import SidebarMenu from "./sidebarElements/SidebarMenu";
import React from "react";
import SidebarListButton from "./sidebarElements/SidebarListButton";
import SidebarListDropdownButton from "./sidebarElements/SidebarListDropdownButton";
import {
	ChartPieIcon,
	StarIcon,
	BookmarkIcon,
	ChartBarIcon,
	Cog8ToothIcon,
} from "@heroicons/react/24/solid";
import LoadingIcon from "../../../assets/icons/loadingIcon.svg";
import {
	MagnifyingGlassCircleIcon,
	BellIcon,
	AdjustmentsVerticalIcon,
} from "@heroicons/react/24/outline";
import SidebarListDivider from "./sidebarElements/SidebarListDivider";
import SidebarSmallButton from "./sidebarElements/SidebarSmallButton";
import SidebarMoblieNav from "./sidebarElements/SidebarMoblieNav";
import { useMediaQuery } from "react-responsive";

/**
 * ACTUAL SIDEBAR COMPONENT
 * (component that holds a list of buttons to select content as well as other features)
 * 
 * PROPS:
 * @param {Object} selectedContent current selected content index
 * @param {Function} changeSelectedContent changes current selected content
 * @param {Array} itemProps holds all props passed in from SidebarItem and SidebarDropdown (title, icon, disabled, etc..)
 */
export const Sidebar = ({
	selectedContent,
	changeSelectedContent,
	itemProps,
}) => {
	const isMobile = useMediaQuery({ query: `(max-width: 1100px)` });

	/**
	 * is bar on full view (icons + title are visible) or is in closed view (only icons are visible)
	 */
	const [isSidebarClosed, setIsSidebarClosed] = React.useState(isMobile);

	const toggleSidebarClose = () => {
		setIsSidebarClosed((prev) => !prev);
	};

	/**
	 * Function to get the corresponding icon based on the given name
	 * @param {String} iconName
	 * @returns React Icon Component
	 */
	const getIconByName = (iconName) => {
		switch (iconName) {
			case "ChartPieIcon":
				return <ChartPieIcon />;
			case "StarIcon":
				return <StarIcon />;
			case "BookMarkIcon":
				return <BookmarkIcon />;
			case "ChartBarIcon":
				return <ChartBarIcon />;
			case "Cog8ToothIcon":
				return <Cog8ToothIcon />;
			case "MagnifyingGlassCircleIcon":
				return <MagnifyingGlassCircleIcon />;
			case "BellIcon":
				return <BellIcon />;
			case "AdjustmentsVerticalIcon":
				return <AdjustmentsVerticalIcon />;
			case "LoadingIcon":
				return <img src={LoadingIcon}></img>;
		}
		return <StarIcon />;
	};

	/**
	 * Function to check if button at the index of mainIndex and subIndex is selected
	 * @param {Number} mainIndex listbnutton/dropdownbutton position
	 * @param {Number} subIndex dropdownbutton subbutton position (optional --> -1 if not dropdown button)
	 * @returns if button by index is selected
	 */
	const isButtonSelected = (mainIndex, subIndex = -1) => {
		if (subIndex === -1) {
			return selectedContent.mainContent === mainIndex;
		}
		return (
			selectedContent.mainContent === mainIndex &&
			selectedContent.subContent === subIndex
		);
	};

	return (
		<>
			{/* ACTUAL SIDEBAR */}
			<div
				className={`min-h-full bg-custom-white pt-6 p-4 shadow-xl border border-gray-200 duration-500 overflow-y-auto overflow-x-hidden scrollbar-track-neutral-200 scrollbar-thin scrollbar-thumb-neutral-400 max-sidebar3:absolute max-sidebar3:z-10 ${
					isSidebarClosed
						? "w-24 max-sidebar3:hidden"
						: "max-w-[400px] w-full max-sidebar:w-[25%] max-sidebar2:w-[30%] max-sidebar3:w-full max-sidebar3:max-w-full"
				}`}
			>
				<div className="flex flex-col justify-between items-between min-h-full max-sidebar3:justify-start max-sidebar3:h-auto">
					<div
						className={`flex flex-col min-h-full justify-start ${
							isSidebarClosed && "items-center"
						}`}
					>
						{/* MENU TO HOLD TITLE AND COLLAPSE BUTTON */}
						<SidebarMenu
							isSidebarClosed={isSidebarClosed}
							toggleSidebarClose={toggleSidebarClose}
						/>
						<SidebarListDivider />
						{/* SIDEBAR LIST OF BUTTONS */}
						<ul
							data-testid="Sidebar-list"
							className="flex flex-col gap-2 w-full"
						>
							{itemProps &&
								itemProps.map((props, propsIndex) => {
									// render ListButton
									if ("title" in props) {
										return (
											<SidebarListButton
												key={props.title}
												testid="Sidebar-listButton"
												toggleSidebarClose={
													toggleSidebarClose
												}
												isSidebarClosed={
													isSidebarClosed
												}
												isSelected={isButtonSelected(
													propsIndex,
													-1
												)}
												changeSelectedContent={
													changeSelectedContent
												}
												mainIndex={propsIndex}
												title={props.title}
												itemProps={props}
												isMobile={isMobile}
											/>
										);
									}
									// render ListDropdownButton
									else {
										return (
											<SidebarListDropdownButton
												key={props.itemProps.title}
												isSidebarClosed={
													isSidebarClosed
												}
												toggleSidebarClose={
													toggleSidebarClose
												}
												changeSelectedContent={
													changeSelectedContent
												}
												isButtonSelected={
													isButtonSelected
												}
												mainIndex={propsIndex}
												mainItemProps={props.itemProps}
												subItemProps={
													props.subItemProps
												}
												isMobile={isMobile}
											/>
										);
									}
								})}
						</ul>
					</div>
					{/* SMALL BUTTONS AT THE BOTTOM */}
					{/* <div>
						<SidebarListDivider />
						<div className="flex flex-col gap-3 pb-8">
							<SidebarSmallButton
								isSidebarClosed={isSidebarClosed}
								title="Notifications"
								icon={getIconByName("BellIcon")}
							/>
							<SidebarSmallButton
								isSidebarClosed={isSidebarClosed}
								title="Get help"
								icon={getIconByName(
									"MagnifyingGlassCircleIcon"
								)}
							/>
							<SidebarSmallButton
								isSidebarClosed={isSidebarClosed}
								title="Make Adjustments"
								icon={getIconByName("AdjustmentsVerticalIcon")}
							/>
						</div>
					</div> */}
				</div>
			</div>
			{/* Mobile Navbar (only visible on mobile) */}
			<SidebarMoblieNav
				isSidebarClosed={isSidebarClosed}
				toggleSidebarClose={toggleSidebarClose}
			/>
		</>
	);
};
