import { useState } from "react";
import { Drawer, Tooltip } from "@mui/material"
import { LightBulbIcon, XMarkIcon } from "@heroicons/react/24/outline";

export const DataVisualizer = ({
    header,
    children,
    tips,
    disableTips = false
}) => {
    const [open, setOpen] = useState(false);

    return (
        <div>
            <div className="flex justify-between items-center mb-4">
                <p className="text-xl font-bold tracking-tight text-gray-500">{header}</p>
                {!disableTips && <Tooltip title="Open tips" arrow placement="bottom">
                    <LightBulbIcon  className="h-8 w-8 mr-2 flex-shrink-0 cursor-pointer text-gray-500 opacity-100 hover:opacity-60"  onClick={() => setOpen(true)}/>
                </Tooltip>}
            </div>
            <hr className="shadow" />
            <div className="">
                <dl className="divide-y divide-gray-200">
                    {children}
                </dl>
            </div>
            <Drawer
                anchor="right"
                open={open}
                onClose={() => setOpen(false)}
            >
                <div className="w-[500px] h-full bg-neutral-200 text-neutral-600 px-6 pb-12">
                    <div className="flex items-center justify-between my-6 bg-neutral-300 p-3 rounded-medium">
                        <div className="flex items-center gap-2">
                            <LightBulbIcon className="h-8 w-8" />
                            <h1 className="text-xl">Quick Tips!</h1>
                        </div>
                        <XMarkIcon className="h-8 w-8 cursor-pointer" onClick={() => setOpen(false)} />
                    </div>
                    <div className="bg-white p-5 rounded-medium">{tips}</div>
                </div>
            </Drawer>
        </div>

    )
}