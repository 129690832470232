import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { InfoModal } from "microtrade-react-lib";
import Lottie from "lottie-react";
import animationData from "../assets/lotties/loadingBars.json";

export function Banner({
  title = "",
  description = "",
  dismissable = true,
  isSticky = false,
  onDismiss = () => {},
  onAction = () => {},
  renderModalOnAction,
  color = "default", // default, success, warning, info
  children,
  buttonText = "",
  isShow,
  setIsShow = () => {},
}) {
  // State variable for moda visibility
  const [openModal, setOpenModal] = React.useState(undefined);
  // State variable for loading animation
  const [isLoading, setIsLoading] = React.useState(false);
  // State variable for success
  const [isSuccess, setIsSuccess] = React.useState(false);

  // Dismiss banner function. It will call onDismiss function and set isShow to false
  const dismiss = () => {
    setIsShow(false);
    onDismiss();
  };

  // If renderModalOnAction is defined and isSuccess is true, then open modal
  React.useEffect(() => {
    if (renderModalOnAction && isSuccess) {
      setOpenModal("default");
      setIsLoading(false);
    }
  }, [isSuccess]);

  // If openModal is defined and isSuccess is true, then close modal
  React.useEffect(() => {
    if (isSuccess && openModal === undefined) {
      dismiss();
      setIsSuccess(false);
    }
  }, [openModal]);

  return (
    <React.Fragment>
      {/* Modal Implementation */}
      <InfoModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        header="Migration Started"
      >
        {renderModalOnAction ?? null}
      </InfoModal>
      <AnimatePresence>
        {/* Animate banner when 'isShow' equals 'false' */}
        {isShow ? (
          <motion.div
            initial={{ opacity: 1 }}
            exit={{
              opacity: 0,
              transition: {
                duration: 0.5,
              },
            }}
            className={`${
              isSticky ? "sticky top-0" : "relative"
            } z-[5] isolate flex items-center gap-x-6 overflow-hidden ${
              color === "default"
                ? "bg-gray-50 text-gray-900"
                : color === "success"
                ? "bg-green-400 text-white"
                : color === "warning"
                ? "bg-yellow-300 text-gray-900"
                : color === "info"
                ? "bg-blue-700 text-white"
                : ""
            } px-6 py-2.5 sm:px-3.5 sm:before:flex-1`}
          >
            <div
              className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
              aria-hidden="true"
            >
              <div
                className={
                  "aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
                }
                style={{
                  background:
                    "clip-path: polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)",
                }}
              ></div>
            </div>
            <div
              className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
              aria-hidden="true"
            >
              <div
                className={
                  "aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
                }
                style={{
                  background:
                    "clip-path: polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)",
                }}
              ></div>
            </div>
            <div className="flex flex-wrap items-center gap-x-4 gap-y-2 empty:hidden">
              <p className="text-sm leading-6 text-inherit empty:hidden">
                <strong className="font-semibold empty:hidden">{title}</strong>
                {title ? (
                  <svg
                    viewBox="0 0 2 2"
                    className="mx-2 inline h-0.5 w-0.5 fill-current"
                    aria-hidden="true"
                  >
                    <circle cx="1" cy="1" r="1" />
                  </svg>
                ) : null}
                {description}
              </p>
              <button
                disabled={isLoading}
                onClick={() => {
                  onAction(setIsLoading, setIsSuccess);
                }}
                type="button"
                className="flex flex-row items-center gap-2 rounded-full bg-gray-900 px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900 empty:hidden"
              >
                {/* Show lottie loading animation when 'isLoading' is true else display an arrow after title */}
                {isLoading ? (
                  <React.Fragment>
                    {buttonText}
                    <Lottie animationData={animationData} className="w-5 h-5" />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {buttonText}
                    {buttonText ? (
                      <span aria-hidden="true"> &rarr;</span>
                    ) : null}
                  </React.Fragment>
                )}
              </button>
            </div>
            {/* If 'dismissable' is true then render an 'x' and it becomes dismissable */}
            {dismissable ? (
              <div className="flex flex-1 justify-end">
                <button
                  type="button"
                  className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
                  onClick={() => dismiss()}
                >
                  <span className="sr-only">Dismiss</span>
                  <svg
                    className="h-5 w-5 text-inherit"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                  </svg>
                </button>
              </div>
            ) : null}
            {children}
          </motion.div>
        ) : null}
      </AnimatePresence>
    </React.Fragment>
  );
}
