import { makeGraphData } from "../components/graphs";

const timer = ms => new Promise(res => setTimeout(res, ms))

const ALLOWED_MESSAGES = [
    "Analysis allowed",
    "Upload allowed"
]

export const getAnalysisResults = (mutationFunction, mutationParams, attempt=0) => {
    return new Promise((resolve, reject) => {
    //console.log(dataRef.current);
        if(attempt>50){
            console.log("No results received after 50 attempts. Aborting...");
            resolve({code: 2, message: "Timeout"});
        }
        else{
            mutationFunction(mutationParams).then(async ({data, error}) => {
                const result = data.createTicket.couptype.coupresultjson;
                //console.log("Result:", result);
                const message = data.createTicket.m.split(":")[0];
                if (result) {
                    //console.log("Data received!", result);
                    
                    resolve({code: 0, message: result});
                }
                else if (!ALLOWED_MESSAGES.includes(message)){
                    console.log("Error received!", message);
                    resolve({code: 1, message: message});
                }
                else{
                    console.log("Waiting for results...");
                    await timer(15000);
                    resolve(getAnalysisResults(mutationFunction, mutationParams, attempt+1));
                }
            });
        }
    });
}
// export const getAnalysisResults = () => {
//     return new Promise((resolve, reject) => {
//         setTimeout(() => {
//             const result = makeGraphData(data);
//             console.log("RESULT");
//             console.log(result);
//             let graphs = [];
//             result.forEach((value, key) => {
//                 graphs.push(value);
//             });
//             console.log("GRARPHS");
//             console.log(graphs);
//             resolve(graphs);
//         }
//         , 5000);
//     });
// }