import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const SortableItem = ({ id, color }) => {
	const { attributes, listeners, setNodeRef, transform, transition, isDragging } =
		useSortable({
			id: id,
		});

	const style = {
		transform: CSS.Transform.toString(transform),
		color: 'black',
		transition,
	};

	return (
		<li
			ref={setNodeRef}
			style={style}
			{...attributes}
			{...listeners}
			// className={`text-black border border-black font-semibold p-1 text-xs rounded-small h-full min-h-max max-h-full flex items-center justify-center ${color}`}
            className={`text-[18px] rounded-small max-w-full flex-grow-0 w-full h-full flex items-center justify-center opacity-100 hover:opacity-60`}
		>
			<div className="relative w-full h-full">
                <div className="absolute right-[0%] whitespace-nowrap w-20" style={{
                    transformOrigin: "100% 50%", /* Rotate around the right end point */
                    transform: "rotate(-45deg)", /* Rotate by 45 degrees */
                }} >
					<p className="truncate text-center" title={id}>{id}</p>
				</div>
            </div>
		</li>
	);
};

export default SortableItem;
