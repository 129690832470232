import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { getDatabase, ref, onValue } from "firebase/database";
import { getAuth } from "firebase/auth";
import { handleDeleteProject, getStatus } from "features/projects/actions";
import { toast } from "react-toastify";
import { NewProjectModal, DeleteModal } from "features/projects";
import { EyeIcon, TrashIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";

const STATUS_CODES = {
	0: { label: "Created", color: "gray" },
	1: { label: "Sample definition file missing", color: "orange" },
	2: { label: "Data files missing", color: "orange" },
	3: { label: "Files uploaded", color: "blue" },
	4: { label: "Calculating results", color: "yellow" },
	5: { label: "Error", color: "red" },
	6: { label: "Results ready", color: "green" },
};

export const ProjectList = () => {
	// State variables
	const [projects, setProjects] = useState();
	const [deleteOpen, setDeleteOpen] = useState(false);
	const [deleteID, setDeleteID] = useState("");

	// Retrieve current user's UID
	const uid = getAuth().currentUser.uid;
	const db = getDatabase();
	const projectsRef = ref(db, "projects/");

	// Fetch projects data from the database when the component mounts
	useEffect(() => {
		onValue(projectsRef, (snapshot) => {
			const data = Object.entries(snapshot.val())
				.filter(
					([key, value]) =>
						value.main &&
						Object.values(value.main.accessorIDs ? value.main.accessorIDs : {}).includes(uid) //Fixed: null check for accessorIDs
				)
				.map(([key, project]) => ({
					id: key,
					name: project.main.name,
					date: project.details.date,
					status: getStatus(project),
				}));
			setProjects(data);
		});
	}, []);

	// Handle project deletion
	const handleDelete = (projectID) => {
		handleDeleteProject(projectID);
		setDeleteOpen(false);
	};

	const renderStatusBadge = (status) => {
		const className = `bg-${STATUS_CODES[status].color}-100 text-${STATUS_CODES[status].color}-600 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full border border-${STATUS_CODES[status].color}-500`;
		return <span className={className}>{STATUS_CODES[status].label}</span>;
	};

	const rows = projects;

	// console.log("PROJECTS------");
	// console.log(projects);

	const columns = [
		{
			field: "name",
			headerName: "Project name",
			headerClassName: "text-lg text-gray-600",
			cellClassName: "font-bold text-md",
			flex: 1,
			minWidth: 150,
			renderCell: (params) => (
				<Link
					to={`/projects/${params.row.id}`}
					data-testid={`projectlist-view-${params.row.name}`}
				>
					{params.row.name}
				</Link>
			)
		},
		{
			field: "date",
			headerName: "Creation date",
			headerClassName: "text-lg text-gray-600",
			cellClassName: "text-md",
			flex: 1,
			minWidth: 200,
		},
		{
			field: "status",
			headerName: "Status",
			flex: 1,
			minWidth: 100,
			headerClassName: "text-lg text-gray-600",
			renderCell: (params) => (
				<div className="flex justify-center items-center">
					{renderStatusBadge(params.row.status)}
				</div>
			),
		},
		{
			field: "actions",
			headerName: "",
			flex: 0.3,
			minWidth: 100,
			sortable: false,
			filterable: false,
			align: "right",
			renderHeader: () => null,
			renderCell: (params) => (
				<div className="flex justify-end items-center">
					<Link
						to={`/projects/${params.row.id}`}
						data-testid={`projectlist-view-${params.row.name}`}
					>
						<EyeIcon className="w-6 h-6 mx-2 text-gray-500 hover:text-gray-400" />
					</Link>
					<button
						data-testid={`projectlist-delete-${params.row.name}`}
						onClick={() => {
							setDeleteID(params.row.id);
							setDeleteOpen(true);
						}}
						className="w-6 h-6 mx-2 text-red-500 cursor-pointer hover:text-red-400"
					>
						<TrashIcon />
					</button>
				</div>
			),
		},
	];

	const skeletonColumns = [
		{
			field: "name",
			headerName: "Project name",
			headerClassName: "text-lg text-gray-600",
			flex: 1,
			minWidth: 150,
			renderCell: (params) => (
				<div className="animate-pulse flex items-center">
					<div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-56 max-w-full"></div>
				</div>
			),
		},
		{
			field: "date",
			headerName: "Creation date",
			headerClassName: "text-lg text-gray-600",
			flex: 1,
			minWidth: 200,
			renderCell: (params) => (
				<div className="animate-pulse flex items-center">
					<div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 max-w-full"></div>
				</div>
			),
		},
		{
			field: "status",
			headerName: "Status",
			headerClassName: "text-lg text-gray-600",
			flex: 1,
			minWidth: 100,
			renderCell: (params) => (
				<div className="animate-pulse flex items-center">
					<div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 max-w-full"></div>
				</div>
			),
		},
		{
			field: "actions",
			headerName: "",
			flex: 0.3,
			minWidth: 100,
			sortable: false,
			filterable: false,
			align: "right",
			renderHeader: () => null,
			renderCell: (params) => (
				<div className="flex justify-end items-center flex-row gap-6">
					<div className="h-6 w-6 bg-gray-200 rounded-full dark:bg-gray-700"></div>
					<div className="h-6 w-6 bg-gray-200 rounded-full dark:bg-gray-700"></div>
				</div>
			),
		},
	];

	return (
		<div>
			<div className="">
				<NewProjectModal />
				{!projects ? (
					/**
					 * SKELETON
					 */
					<DataGrid
						key={"datagrid-skeleton"}
						rows={[
							{ id: 0 },
							{ id: 1 },
							{ id: 2 },
							{ id: 3 },
							{ id: 4 },
						]}
						columns={skeletonColumns}
						disableRowSelectionOnClick
						autoHeight
						width="60%"
						initialState={{
							pagination: { paginationModel: { pageSize: 10 } },
						}}
						pageSizeOptions={[5, 10, 25]}
						sx={{
							background: "white",
							border: "1",
							paddingX: 1,
							marginTop: 2,
							borderRadius: 4,
						}}
					/>
				) : (
					/**
					 * PROJECTLIST DATAGRID
					 */
					<DataGrid
						key={"datagrid-normal"}
						rows={projects}
						columns={columns}
						disableRowSelectionOnClick
						autoHeight
						width="60%"
						initialState={{
							pagination: { paginationModel: { pageSize: 10 } },
						}}
						pageSizeOptions={[5, 10, 25]}
						sx={{
							background: "white",
							border: "1",
							paddingX: 1,
							marginTop: 2,
							borderRadius: 4,
						}}
					/>
				)}
			</div>

			<DeleteModal
				open={deleteOpen}
				setOpen={setDeleteOpen}
				deleteFunction={() => handleDelete(deleteID)}
				objectName={"project"}
			/>
		</div>
	);
};
