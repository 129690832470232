import Plot from "react-plotly.js";
import React, { useEffect } from "react";
import { SortableList } from "layouts";
import { arrayMove } from "@dnd-kit/sortable";
import { getDatabase, ref as dbRef, set, onValue } from "firebase/database";

const colorCodes = {
	O: "#FFA500",
	R: "#E33535",
	G: "#3CBE1B",
	Y: "#F2E34C",
};

const graphSkeleton = (
	<div
		role="status"
		className="h-full w-full p-4 border border-gray-200 rounded shadow animate-pulse md:p-6 dark:border-gray-700"
	>
		<div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2.5"></div>
		<div className="w-48 h-2 mb-10 bg-gray-200 rounded-full dark:bg-gray-700"></div>
		<div className="flex items-baseline mt-4 space-x-6">
			<div className="w-full bg-gray-200 rounded-t-lg h-72 dark:bg-gray-700"></div>
			<div className="w-full h-56 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
			<div className="w-full bg-gray-200 rounded-t-lg h-72 dark:bg-gray-700"></div>
			<div className="w-full h-64 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
			<div className="w-full h-56 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
			<div className="hidden xl:block w-full bg-gray-200 rounded-t-lg h-80 dark:bg-gray-700"></div>
			<div className="hidden 2xl:block w-full bg-gray-200 rounded-t-lg h-72 dark:bg-gray-700"></div>
		</div>
		<span className="sr-only">Loading...</span>
	</div>
);

export const LambdaChart = ({ projectID, index, toggles, plotid }) => {
	const db = getDatabase();
	const chartRef = dbRef(db, `projects/${projectID}/result/data/${index}`);
	const [graphData, setGraphData] = React.useState([]);
	const [isLoading, setIsLoading] = React.useState(true);

	useEffect(() => {
		onValue(chartRef, (snapshot) => {
			let data = snapshot.val();
			setGraphData(data);
		});
	}, []);

	useEffect(() => {
		if (isLoading && graphData.length !== 0) {
			setIsLoading(false);
		}
	}, [graphData]);

	const x = graphData
		.map((sample) => sample.lambdas.left.map((l) => sample.Name))
		.flat();
	const leftY = graphData
		.map((sample) => sample.lambdas.left.map((l) => l.value))
		.flat();
	const rightY = graphData
		.map((sample) => sample.lambdas.right.map((l) => l.value))
		.flat();

	const leftTrace = {
		x: x,
		y: leftY,
		type: "box",
		name: graphData[0] ? graphData[0].lambdas.antibodyleft : '...',
		boxpoints: "all",
		pointpos: 0,
		jitter: 0.5,
		whiskerwidth: 0.2,
		fillcolor: "#F36060",
		marker: {
			size: 6,
		},
		line: {
			width: 1,
			color: "#16374B",
		},
		hovertemplate:
			"<b>Lambda:</b> %{y}<br>" +
			"<b>Well:</b> %{text}" +
			"<extra></extra>",
		text: graphData
			.map((sample) => sample.lambdas.left.map((l) => l.well))
			.flat(),
	};
	const rightTrace = {
		x: x,
		y: rightY,
		type: "box",
		name: graphData[0] ? graphData[0].lambdas.antibodyright : '...',
		boxpoints: "all",
		pointpos: 0,
		jitter: 0.5,
		whiskerwidth: 0.2,
		fillcolor: "#2A96BD",
		marker: {
			size: 6,
		},
		line: {
			width: 1,
			color: "#16374B",
		},
		hovertemplate:
			"<b>Lambda:</b> %{y}<br>" +
			"<b>Well:</b> %{text}" +
			"<extra></extra>",
		text: graphData
			.map((sample) => sample.lambdas.right.map((l) => l.well))
			.flat(),
	};
	const data = [leftTrace, rightTrace];

	const title = isLoading
		? {}
		: {
			text: `<span>${graphData[0].lambdas.antibodyleft} @ ${graphData[0].lambdas.antibodyright}</span><br>`,
			x: 0,
			xref: "paper",
		};

	const [leftColor, rightColor] = isLoading
		? ["", ""]
		: graphData[0].Type.split("").map((c) => colorCodes[c]);

	const annotations = [
		{
			x: 0,
			y: 1.1,
			xref: "paper",
			yref: "paper",
			text: " ",
			width: 35,
			bgcolor: leftColor,
			showarrow: false,
		},
		{
			x: 0.18,
			y: 1.1,
			xref: "paper",
			yref: "paper",
			text: " ",
			width: 35,
			bgcolor: rightColor,
			showarrow: false,
		},
	];

	function onSortEnd(oldIndex, newIndex) {
		let temp = graphData;
		let newGraphData = arrayMove(temp, oldIndex, newIndex);
		set(chartRef, newGraphData);
	}

	return (
		<div className="h-[580px] w-full ">
			{isLoading ? (
				<div className="h-full w-full p-16 opacity-40">{graphSkeleton}</div>
			) : (
				<>
					<Plot
						divId={plotid}
						data={data}
						layout={{
							title: {
								text: title.text,
								x: title.x,
								y: 0.9,
								xref: title.xref,
								font: {
									family: "Roboto, monospace",
									size: 20,
									color: "#212121",
									weght: 400,
								},
							},
							legend: {
								font: {
									family: "Roboto, monospace",
									size: 18,
									color: "#212121",
									weght: 400,
								},
								xref: "container",
								xanchor: "left",
								x: 0.75,
								entrywidth: 1,
								entrywidthmode: "pixels",
							},
							xaxis: {
								tickangle: 45,
							},
							margin: {
								t: 100,
								r: 150,
								b: 0,
							},
							yaxis: {
								title: {
									text: "Lambda",
									font: {
										family: "Roboto, monospace",
										size: 18,
										color: "#212121",
										weght: 400,
									},
								},
								range: toggles.uniform ? [0, 1] : [],
								showline: true,
								linewidth: 2,
								zerolinewidth: 2,
								linecolor: "#858585",
								zerolinecolor: "#858585",
								ticks: "outside",
							},
							boxmode: "group",
							boxgap: 0.4,
							boxgroupgap: 0.1,
							annotations: annotations,
							modebar: {
								// orientation: "v",
							},
						}}
						config={{
							//displayModeBar: true,
							responsive: true,
						}}
						className="w-full h-[500px]"
					/>
					<div className="w-full h-[1px] flex items-center justify-center pr-[25%] pl-[80px]">
						<SortableList
							items={graphData.map((sample) => sample.Name)}
							visibility={graphData.map((sample) => {
								return { name: sample.Name, visible: true };
							})}
							callbackOnDragEnd={onSortEnd}
						/>
					</div>
				</>
			)}
		</div>
	);
};
