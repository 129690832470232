import React, { useState } from "react";
import modalIcon from "assets/images/logo-icon.png";
import { NeutralButton, NextButton, FailButton } from "components/buttons";
import {
  handleEmailVerification,
  handleLogout,
} from "features/authentication/actions";
import { Modal } from "components";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import loader from "../../assets/icons/loading.gif";
import { toast } from "react-toastify";

export function UnverifiedEmail() {
  const user = getAuth().currentUser;
  const auth = getAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  //console.log(user);

  const handleDoneButtonClick = async () => {
    try {
      setIsLoading(true);

      const loginData = secureLocalStorage.getItem("credentialsForSignIn"); // get new email and  verified password from localStorage
      const restoredEmail = window.localStorage.getItem("restoredEmail"); // get restored email from localStorage
      const verifiedType = window.localStorage.getItem("verified"); // get verified to know that user clicked on verify link in their email

      if (verifiedType) {
        if (restoredEmail) {
          signInWithEmailAndPassword(auth, restoredEmail, loginData.pwd).then(
            (userCredential) => {
              // remove password and email from localStorage
              secureLocalStorage.removeItem("credentialsForSignIn");
              // remove restored email
              window.localStorage.removeItem("restoredEmail");
              // remove verified value from localStorage
              window.localStorage.removeItem("verified");

              setIsLoading(false);
              navigate("/login");
            }
          );
        } else {
          signInWithEmailAndPassword(auth, loginData.email, loginData.pwd).then(
            (userCredential) => {
              // remove password and email from localStorage
              secureLocalStorage.removeItem("credentialsForSignIn");
              // remove restored email
              window.localStorage.removeItem("restoredEmail");
              // remove verified value from localStorage
              window.localStorage.removeItem("verified");

              setIsLoading(false);
              window.location.href = "/login";
            }
          );
        }
      } else {
        window.location.href = "/login";
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      }
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
  };

  return (
    <Modal
      icon={<img src={modalIcon} alt={""} />}
      title="Verify email"
      text={
        "Please check your emails at " +
        user.email +
        ". We've sent you an email so that you can verify your account."
      }
      buttonsArray={[
        <FailButton onClick={handleLogout}>
          <span className="text-md my-0 py-0 pr-2">&larr;</span> Log out
        </FailButton>,
        <NeutralButton onClick={handleEmailVerification}>
          Resend Email
        </NeutralButton>,
        <NextButton onClick={handleDoneButtonClick}>
          {isLoading ? (
            <img
              className={`object-cover w-5 transition ease-in-out delay-150`}
              src={loader}
              alt="loading"
            />
          ) : (
            "Done"
          )}
        </NextButton>,
      ]}
    />
  );
}
