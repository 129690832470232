import React, { useState, useEffect } from "react";
import {
  getAuth,
  confirmPasswordReset,
  verifyPasswordResetCode,
  applyActionCode,
  checkActionCode,
} from "firebase/auth";
import { useNavigate, Navigate } from "react-router-dom";
import {
  PasswordResetForm,
  EmailVerified,
  RecoverEmail,
} from "features/authentication";
import { LoadingPage } from "components";
import { toast } from "react-toastify";

export const AuthActions = () => {
  const navigate = useNavigate();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const actionCode = urlParams.get("oobCode");
  const mode = urlParams.get("mode");
  const auth = getAuth();
  const [emailVerified, setEmailVerified] = useState(false);
  const [loading, setLoading] = useState(true);

  //console.log("render");
  //console.log(mode);

  const onResetPassword = (password) => {
    //console.log("reset_password");
    verifyPasswordResetCode(auth, actionCode)
      .then(() => {
        // Save the new password.
        confirmPasswordReset(auth, actionCode, password)
          .then((resp) => {
            // Password reset has been confirmed and new password updated.
            toast.success("The password has been reset!", {
              position: "top-center",
            });
            navigate("/login");
          })
          .catch((error) => {
            // Error occurred during confirmation. The code might have expired or the
            // password is too weak.
            toast.error("The password reset failed!", {
              position: "top-center",
            });
          });
      })
      .catch((error) => {
        // Invalid or expired action code. Ask user to try to reset the password
        // again.
        toast.error("The reset link was invalid! Try reseting again.", {
          position: "top-center",
        });
      });
  };

  function handleRecoverEmail(auth, actionCode) {
    // Localize the UI to the selected language as determined by the lang
    // parameter.
    let restoredEmail = null;
    // Confirm the action code is valid.
    checkActionCode(auth, actionCode)
      .then((info) => {
        // Get the restored email address.
        restoredEmail = info["data"]["email"];

        window.localStorage.setItem("verified", true); // set 'verified' to know that user clicked on verify link in their email
        // Set localStorage Item to sign in after reseted email
        window.localStorage.setItem("restoredEmail", restoredEmail);
        // Revert to the old email.
        return applyActionCode(auth, actionCode);
      })
      .then(() => {
        // Account email reverted to restoredEmail
        setEmailVerified(true);
        setLoading(false);
      })
      .catch((error) => {
        // Invalid code.
        console.log("Error");
        setLoading(false);
      });
  }

  function handleVerifyEmail(auth, actionCode) {
    // Localize the UI to the selected language as determined by the lang
    // parameter.
    // Try to apply the email verification code.
    applyActionCode(auth, actionCode)
      .then((resp) => {
        // Email address has been verified.
        window.localStorage.setItem("verified", true); // set 'verified' to know that user clicked on verify link in their email

        setEmailVerified(true);
        setLoading(false);
      })
      .catch((error) => {
        // Code is invalid or expired. Ask the user to verify their email address
        // again.
        console.log("Error");
        setLoading(false);
      });
  }

  useEffect(() => {
    if (mode === "recoverEmail") {
      handleRecoverEmail(auth, actionCode);
    } else {
      handleVerifyEmail(auth, actionCode);
    }
  }, []);

  // Handle the user management action.
  switch (mode) {
    case "resetPassword":
      return (
        <>
          <main>
            <section>
              <PasswordResetForm onResetPassword={onResetPassword} />
            </section>
          </main>
        </>
      );
    case "verifyEmail":
      // TODO Display email verification handler and UI.
      return loading ? (
        <LoadingPage />
      ) : (
        <EmailVerified success={emailVerified} />
      );
      break;
    case "recoverEmail":
      return loading ? (
        <LoadingPage />
      ) : (
        <RecoverEmail success={emailVerified} />
      );
      break;
    default:
      // Error: invalid mode.
      return <Navigate to="/login" />;
  }
};
