import React from "react";

const ChartViewSettings = ({ toggles, handleToggleChange, type }) => {
	return (
		<div className="w-full py-5 flex flex-row items-center justify-between gap-10 pl-2 pr-4 max-md:flex-col max-md:gap-2">
			<div className="flex flex-col gap-2">
				<label className="relative inline-flex items-center cursor-pointer">
					<input
						type="checkbox"
						data-testid="uniform-checkbox"
						value=""
						className="sr-only peer"
						checked={toggles.uniform}
						onChange={(e) => {
							handleToggleChange(e, "uniform");
						}}
					/>
					<div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
					<span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
						Uniform Y axis
					</span>
				</label>
				{type !== "lambda" && (
					<label className="relative inline-flex items-center cursor-pointer">
						<input
							type="checkbox"
							data-testid="lambda-checkbox"
							value=""
							className="sr-only peer"
							checked={toggles.subtraction}
							onChange={(e) => {
								handleToggleChange(e, "subtraction");
							}}
						/>
						<div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
						<span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
							ABC subtraction
						</span>
					</label>
				)}
				<label className="relative inline-flex items-center">
					<input
						type="checkbox"
						data-testid="normal-distribution-checkbox"
						value=""
						className="sr-only peer"
						checked={toggles.normal}
						onChange={(e) => {
							handleToggleChange(e, "normal");
						}}
						disabled
					/>
					<div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
					<span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300 opacity-60">
						Show normal μ/σ (not available)
					</span>
				</label>
			</div>
		</div>
	);
};

export default ChartViewSettings;
