import "./App.css";
import React from "react";
import {
  Home,
  Login,
  Register,
  Team,
  ForgotPassword,
  Landing,
  AuthActions,
  UnverifiedEmail,
  Project,
  Profile,
} from "pages";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import { RequireAuth } from "features/authentication";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./firebase";
import { ToastContainer } from "react-toastify";
import { ProfileProvider } from "pages/contexts/profileContext";

import "react-toastify/dist/ReactToastify.css";

function App() {
  const [user, loading, error] = useAuthState(auth);
  console.log(error);
  return (
    <div>
      <Router>
        <div className="min-h-screen style-screen-background-light">
          <Routes>
            <Route
              path="/"
              element={
                <RequireAuth
                  authenticated={!user}
                  loading={loading}
                  redirect={"/home"}
                >
                  <Landing />
                </RequireAuth>
              }
            />
            <Route
              path="/register"
              element={
                <RequireAuth
                  authenticated={!user}
                  loading={loading}
                  redirect={"/home"}
                >
                  <Register />
                </RequireAuth>
              }
            />
            <Route
              path="/login"
              element={
                <RequireAuth
                  authenticated={!user}
                  loading={loading}
                  redirect={"/home"}
                >
                  <Login />
                </RequireAuth>
              }
            />
            <Route
              path="/forgot_password"
              element={
                <RequireAuth
                  authenticated={!user}
                  loading={loading}
                  redirect={"/home"}
                >
                  <ForgotPassword />
                </RequireAuth>
              }
            />
            <Route path="/auth_actions" element={<AuthActions />} />
            <Route
              path="/unverified_email"
              element={
                <RequireAuth
                  authenticated={user}
                  loading={loading}
                  redirect={"/login"}
                >
                  <UnverifiedEmail />
                </RequireAuth>
              }
            />
            <Route
              path="/home"
              element={
                <RequireAuth
                  authenticated={user}
                  loading={loading}
                  redirect={"/login"}
                >
                  <RequireAuth
                    authenticated={user ? user.emailVerified : false}
                    loading={loading}
                    redirect={"/unverified_email"}
                  >
                    <Home />
                  </RequireAuth>
                </RequireAuth>
              }
            />
            <Route
              path="/projects/:projectID"
              element={
                <RequireAuth
                  authenticated={user}
                  loading={loading}
                  redirect={"/login"}
                >
                  <RequireAuth
                    authenticated={user ? user.emailVerified : false}
                    loading={loading}
                    redirect={"/unverified_email"}
                  >
                    <Project />
                  </RequireAuth>
                </RequireAuth>
              }
            />
            <Route
              path="/team"
              element={
                <RequireAuth
                  authenticated={user}
                  loading={loading}
                  redirect={"/login"}
                >
                  <RequireAuth
                    authenticated={user ? user.emailVerified : false}
                    loading={loading}
                    redirect={"/unverified_email"}
                  >
                    <Team />
                  </RequireAuth>
                </RequireAuth>
              }
            />
            <Route
              path="/me"
              element={
                <RequireAuth
                  authenticated={user}
                  loading={loading}
                  redirect={"/login"}
                  skeletonLoading={true}
                >
                  <RequireAuth
                    authenticated={user ? user.emailVerified : false}
                    loading={loading}
                    redirect={"/unverified_email"}
                    skeletonLoading={true}
                  >
                    <ProfileProvider>
                      <Profile isLoading={loading} />
                    </ProfileProvider>
                  </RequireAuth>
                </RequireAuth>
              }
            />
          </Routes>
        </div>
      </Router>
      <ToastContainer />
    </div>
  );
}

export default App;
