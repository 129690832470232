import React, { useEffect } from "react";
import { CouplexChart, LambdaChart } from "features/projects/components/graphs";
import { DataVisualizer, SortableList } from "layouts";
import {
	downloadCSVFile,
	downloadXLSXFile,
} from "features/projects/actions/downloadFile";
import ChartViewSettings from "./components/ChartViewSettings";
import * as Plotly from "plotly.js-dist";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { ChartExport } from "./components/ChartExport";
import {
	getDatabase,
	ref as dbRef,
	onValue,
	get,
} from "firebase/database";
import "./chart.css";
import { CouplexChartsHelp, LambdaChartsHelp } from "../helpPages";

export const ChartTable = ({ title, type, projectID, projectTitle }) => {
	const db = getDatabase();
	const chartsArrLengthRef = dbRef(db, `projects/${projectID}/result/length`);
	const [chartsArrLength, setChartsArrLength] = React.useState();

	useEffect(() => {
		onValue(chartsArrLengthRef, (snapshot) => {
			let data = snapshot.val();
			setChartsArrLength(data);
		});
	}, []);

	/**
	 * get all graphs' raw data only once
	 * @returns raw data
	 */
	function getRawData() {
		return new Promise((resolve, reject) => {
			get(dbRef(db, `projects/${projectID}/result/data`))
				.then((snapshot) => {
					if (snapshot.exists()) {
						resolve(snapshot.val());
					} else {
						console.error("No data available");
					}
				})
				.catch((error) => {
					console.error(error);
				});
		});
	}
	/**
	 * This function generates a blob from URI
	 *
	 * @param {String} uri uri to be converted to blob
	 * @returns generated blob
	 */
	function generateBlobFromURI(uri) {
		return new Promise((resolve, reject) => {
			const xhr = new XMLHttpRequest();
			xhr.open("GET", uri, true);
			xhr.responseType = "blob";

			xhr.onload = () => {
				if (xhr.status === 200) {
					resolve(xhr.response);
				} else {
					reject(
						new Error(
							`Failed to generate Blob from URI. Status: ${xhr.status}`
						)
					);
				}
			};

			xhr.onerror = () => {
				reject(new Error("Failed to generate Blob from URI."));
			};

			xhr.send();
		});
	}

	/**
	 * downloads all images by clicking the photo button on all graphs rendered
	 */
	async function downloadGraphImages(format, filename = type) {
		const zip = new JSZip();
		const rawData = await getRawData();

		async function generateAndZipBlobs(rawData) {
			
			const promises = rawData.map(async (_, index) => {
				var update = {
					xaxis: {
						automargin: true
					}
				};
				Plotly.relayout(`${type}${index}`, update)
				const imgURI = await Plotly.toImage(`${type}${index}`, {
					format: format,
					filename: `${type}${index}`,
					height: 1000,
					width: 1000,
				});
				const blob = await generateBlobFromURI(imgURI);
				var update = {
					xaxis: {
						automargin: false
					}
				};
				Plotly.relayout(`${type}${index}`, update)
				zip.file(`${filename}_${index+1}.${format}`, blob);
			});

			await Promise.all(promises);
		}

		generateAndZipBlobs(rawData)
			.then(() => {
				zip.generateAsync({ type: "blob" })
					.then((zipBlob) => {
						saveAs(zipBlob, `${filename}_graphs`);
					})
					.catch((error) => {
						console.error("Error zipping and downloading:", error);
					});
			})
			.catch((error) => {
				console.error("Error generating and zipping blobs:", error);
			});
	}

	/**
	 * download raw data in the selected file format
	 */
	async function downloadRawData(downloadFormat, type) {
		const rawData = await getRawData();
		console.log(rawData)
        if (toggles.subtraction) {
            // get mean of ABC
            rawData.forEach((graph) => {
                let meanABC = 0;
                graph.forEach((sample) => {
                    if (sample.Name === "ABC") {
                        meanABC = sample.mean;
                    }
                });
                // subtract ABC from all samples
                graph.forEach((sample) => {
                    if (sample.y !== undefined) {
                        sample.y.forEach((y) => {
                            y.couplex = y.couplex - meanABC;
                        });
                    }
                });
            })
        }
		switch (downloadFormat) {
			case ".csv":
				downloadCSVFile(rawData, type, projectTitle);
				break;
			case ".xlsx":
				downloadXLSXFile(rawData, type, projectTitle);
				break;
		}
	}

	const [toggles, setToggles] = React.useState({
		uniform: false,
		subtraction: false,
		normal: false,
	});

	const handleToggleChange = (e, key) => {
		setToggles((prev) => ({
			...prev,
			[key]: e.target.checked,
		}));
	};

	return (
		<DataVisualizer
			header={title}
            tips={type === "couplex" ? <CouplexChartsHelp /> : <LambdaChartsHelp />}
		>
			{/* CHART EXPORT */}
			<ChartExport 
				downloadRawData = {downloadRawData}
				downloadGraphImages = {downloadGraphImages}
				type={type}
			/>
			{/* CHART SETTINGS/CUSTOMIZATION */}
			<ChartViewSettings
				toggles={toggles}
				handleToggleChange={handleToggleChange}
				type={type}
			/>
			<div
				id="chart-container"
				data-testid="chart-container"
				className="grid grid-cols-1 gap-y-8 lg:grid-cols-2 justify-center"
			>
				{Array.from(Array(chartsArrLength), (_, i) => i).map((index) =>
					type == "couplex" ? (
						<CouplexChart
							key={index}
							plotid={`couplex${index}`}
							projectID={projectID}
							index={index}
							toggles={toggles}
						/>
					) : (
						<LambdaChart
							key={index}
							plotid={`lambda${index}`}
							projectID={projectID}
							index={index}
							toggles={toggles}
						/>
					)
				)}
			</div>
		</DataVisualizer>
	);
};
