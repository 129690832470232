import React from 'react'
import {
    ArrowDownOnSquareStackIcon,
    DocumentChartBarIcon,
} from "@heroicons/react/24/solid";
import { NextButton } from "components/buttons";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

export const ChartExport = ({ downloadRawData, downloadGraphImages, type }) => {

    /**
    * saves the new file format selected in downloadFormat
    * @param {*} event
    */
    const handleFormatChange = (event) => {
        setDownloadFormat(event.target.value);
    };

    const [downloadFormat, setDownloadFormat] = React.useState(".xlsx");

    /**
     * saves the new image file format selected in downloadImageFormat
     * @param {*} event
     */
    const handleImageFormatChange = (event) => {
        setDownloadImageFormat(event.target.value);
    };

    const [downloadImageFormat, setDownloadImageFormat] = React.useState("png");

    /**
     * saves the new image file name selected in downloadImageName
     * @param {*} event
     */
    const handleImageNameChange = (event) => {
        setDownloadImageName(event.target.value);
    };

    const [downloadImageName, setDownloadImageName] = React.useState("");

    return (
        <>
            {/* CHART EXPORT */}
            <div className="w-full py-5 lg:h-[80px] lg:flex flex-row items-center justify-between gap-10 pl-2 pr-4  max-md:gap-2">
                {/* EXPORT DATA AS FILE FORMAT */}
                { <div className="sm:flex flex-row gap-4 items-center mb-5 lg:mb-0">
                    <FormControl sx={{ minWidth: 100 }} size="small">
                        <InputLabel id="demo-select-small-label">
                            format
                        </InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={downloadFormat}
                            label="Save as"
                            onChange={handleFormatChange}
                        >
                            <MenuItem value={".csv"}>.csv</MenuItem>
                            <MenuItem value={".xlsx"}>.xlsx</MenuItem>
                        </Select>
                    </FormControl>
                    <NextButton onClick={() => downloadRawData(downloadFormat, type)}>
                        <DocumentChartBarIcon className="h-5 w-5 flex-shrink-0 mr-2" />
                        <p className="mr-2">Export data</p>
                    </NextButton>
                </div>}
                {/* DOWNLOAD GRAPH IMAGES */}
                <div className="sm:flex flex-row gap-4 items-center">

                    <input
                        id="downloadImageName"
                        type="text"
                        name="downloadImageName"
                        onChange={handleImageNameChange}
                        value={downloadImageName}
                        placeholder="Download Name"
                        className="mb-3 sm:mb-0 block w-50 h-10 rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    />

                    <FormControl sx={{ minWidth: 100 }} size="small">
                        <InputLabel id="demo-select-small-label">
                            format
                        </InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={downloadImageFormat}
                            label="Save as"
                            onChange={handleImageFormatChange}
                        >
                            <MenuItem value={"svg"}>.svg</MenuItem>
                            <MenuItem value={"png"}>.png</MenuItem>
                        </Select>
                    </FormControl>
                    <NextButton onClick={() => downloadGraphImages(downloadImageFormat, downloadImageName ? downloadImageName : undefined)}>
                        <ArrowDownOnSquareStackIcon className="h-5 w-5 flex-shrink-0 mr-2" />
                        <p className="mr-2">Export graph images</p>
                    </NextButton>
                </div>
            </div>
        </>
    )
}