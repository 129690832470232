export const Background = ({ children, isFullScreen = false }) => {
  return (
    <>
      {isFullScreen ? (
        <div className="bg-gray-50 h-screen grid grid-cols-1 grid-rows-[auto_auto_1fr] ">
          {children}
        </div>
      ) : (
        <div className="bg-gray-50 min-h-screen">{children}</div>
      )}
    </>
  );
};
