import React from "react";
import { CircleStackIcon } from "@heroicons/react/24/outline";
import { ChevronDoubleLeftIcon } from "@heroicons/react/20/solid";
import { motion, AnimatePresence } from "framer-motion";

/**
 * framer-motion variant animation for title/icon appearance
 */
const animationOnClose = {
	visible: {
		opacity: "60%",
		transition: {
			duration: 0.2,
			delay: 0.2,
		},
	},
	hidden: {
		opacity: 0,
		transition: {
			duration: 0,
		},
	},
};

/**
 * THIS COMPONTENT HOLDS A TITLE AND A COLLAPSE/OPEN BUTTON FOR THE SIDEBAR
 * 
 * PROPS:
 * @param {Boolean} isSidebarClosed
 * @param {Function} toggleSidebarClose
 */
const SidebarMenu = ({ isSidebarClosed, toggleSidebarClose }) => {
	return (
		<div className="flex flex-row justify-between items-center">
            {/* DASHBOARD TITLE WITH ICON */}
			<AnimatePresence>
				{!isSidebarClosed && (
					<motion.div
                        data-testid="SidebarMenu-title"
						initial="hidden"
						animate="visible"
						exit="hidden"
						variants={animationOnClose}
						className="flex flex-row items-center gap-2 text-custom pl-3 pr-6"
					>
						<CircleStackIcon className="h-8 w-8" />
						<p className="text-lg font-semibold">Dashboard</p>
					</motion.div>
				)}
			</AnimatePresence>
            {/* COLLAPSE/OPEN BUTTON */}
			<button
                data-testid="SidebarMenu-ButtonToggleClose"
				onClick={toggleSidebarClose}
				className="text-custom p-1 bg-black opacity-60 bg-opacity-10 duration-200 hover:opacity-100 hover:text-primary-blue-dark rounded-medium"
			>
				<motion.div
					animate={{ rotate: isSidebarClosed ? 180 : 0 }}
					transition={{ delay: 0.2, duration: 2, type: "spring", stiffness: 100 }}
				>
					<ChevronDoubleLeftIcon className="h-6 w-6" />
				</motion.div>
			</button>
		</div>
	);
};

export default SidebarMenu;
