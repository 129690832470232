import React from "react";

export function FailButton(props) {
  return (
    <button
      {...props}
      className="mt-0 inline-flex w-full sm:w-auto justify-center rounded-full bg-red-50 px-3 py-2 text-sm font-semibold text-red-600 ring-1 ring-inset ring-red-500 shadow-md hover:bg-red-600 hover:text-white disabled:opacity-80 disabled:bg-gray-500 disabled:ring-gray-500 disabled:border-gray-500 disabled:text-gray-200 disabled:cursor-not-allowed"
    >
      {props.children}
    </button>
  );
}
