import React, { useEffect } from "react";
import { Sidebar } from "./elements/Sidebar";
import { SidebarItem } from "./elements/SidebarItem";
import { SidebarDropdown } from "./elements/SidebarDropdown";
import { SidebarFrameContent } from "./elements/SidebarFrameContent";
import SidebarFrameHelp from "./elements/SidebarFrameHelp";
import { ChartPieIcon } from "@heroicons/react/24/solid";
import { StarIcon } from "@heroicons/react/24/solid";
import { BookmarkIcon } from "@heroicons/react/24/solid";
import { ChartBarIcon } from "@heroicons/react/24/solid";
import { Cog8ToothIcon } from "@heroicons/react/24/solid";
import LoadingIcon from "../../assets/icons/loadingIcon.svg";
import {
	MagnifyingGlassCircleIcon,
	BellIcon,
	AdjustmentsVerticalIcon,
} from "@heroicons/react/24/outline";

/**
 * COMPONENT THAT HOLDS ALL SIDEBAR COMPONENTS AND RENDERS THEM IN A FULLSCREEN - TABLE
 *
 * PROPS:
 * @param {*} children
 */
export const SidebarFrame = ({ children }) => {
	/**
	 * The current selected content by the user (changed) by clicking sidebar button)
	 */
	const [selectedContent, setSelectedContent] = React.useState({
		mainContent: 0,
		subContent: 0,
	});

	/**
	 * Function to change selected content
	 * @param {Number} mainIndex main selected content (Listbutton - DropdownButton index)
	 * @param {Number} subIndex sub selected content (-1 if none) -- OPTIONAL
	 */
	function changeSelectedContent(mainIndex, subIndex = -1) {
		setSelectedContent({
			mainContent: mainIndex,
			subContent: subIndex,
		});
	}

	/**
	 * Function to get the corresponding icon based on the given name
	 * @param {string} iconName
	 * @returns React Icon Component
	 */
	const getIconByName = (iconName) => {
		switch (iconName) {
			case "ChartPieIcon":
				return <ChartPieIcon />;
			case "StarIcon":
				return <StarIcon />;
			case "BookMarkIcon":
				return <BookmarkIcon />;
			case "ChartBarIcon":
				return <ChartBarIcon />;
			case "Cog8ToothIcon":
				return <Cog8ToothIcon />;
			case "MagnifyingGlassCircleIcon":
				return <MagnifyingGlassCircleIcon />;
			case "BellIcon":
				return <BellIcon />;
			case "AdjustmentsVerticalIcon":
				return <AdjustmentsVerticalIcon />;
			case "LoadingIcon":
				return <img src={LoadingIcon}></img>;
		}
		return <StarIcon />;
	};

	/**
	 * Calculated arrays from passed in itemProps
	 * if ListButton --> object with title and icon
	 *  {
	 *      title: "",
	 *      icon: ""
	 *  }
	 * if ListDropDownButton --> object with 'itemProps' object and 'subItemProps' holding an array of objects
	 * {
	 *      itemProps: {
	 *          title: "",
	 *          icon: "",
	 *      }
	 *      subItemProps: [
	 *          {
	 *              title: "".
	 *              icon: "".
	 *          }
	 *      ]
	 *  }
	 */
	const [itemPropsState, setItemPropsState] = React.useState();

	useEffect(() => {
		calcItemPropsFromChildren();
	}, [children]);

	/**
	 * Check all SidebarItem and SiderDropdown passed in components and set itemPropsState with an array of values in the correct format
	 */
	const calcItemPropsFromChildren = () => {
		let itemPropsArr = [];
		let tempChildren =
			Array.isArray(children) ? children : [children];
		//console.log("children1", Array.isArray(children));
		tempChildren.forEach((child) => {
			if (!child) return;
			let { itemProps } = child.props;
			itemProps = { ...itemProps, icon: getIconByName(itemProps.icon) };
			if (child.type === SidebarItem) {
				//console.log("SidebarItem");
				itemPropsArr.push(itemProps);
			} else {
				//console.log("Not SidebarItem");
				let subItemPropsArr = [];
				let tempChildren2 =
					Array.isArray(child.props?.children)
						? child.props.children
						: [child.props.children];
					//console.log("children2", Array.isArray(child.props?.children));
				tempChildren2.forEach((dropdownElem) => {
					if (!dropdownElem) return;
					let subItemProps = dropdownElem.props.itemProps;
					subItemProps = {
						...subItemProps,
						icon: getIconByName(subItemProps.icon),
					};

					subItemPropsArr.push(subItemProps);
				});

				itemPropsArr.push({
					itemProps: itemProps,
					subItemProps: subItemPropsArr,
				});
			}
		});
		setItemPropsState(itemPropsArr);
	};

	
	/**
	 * Function to get the props from the current selected button/dropdown button
	 * @param {String} whichProp name of prop like --> "content" or "tips"
	 * @returns React Component
	 */
	function getSelectedProps(whichProp) {
		/**
		 * if prop Array -->  filters undefined elements
		 * if prop Object --> converts it into array
		 * @param {Array, Object} arr
		 * @returns filtered array
		 */
		function filterUndifined(arr) {
			let filtered = [];
			if (arr.constructor === Array) {
				filtered = arr.filter((child) => child);
			} else {
				if (children) filtered.push(children);
			}
			return filtered;
		}

		let mainChild = filterUndifined(children)[selectedContent.mainContent];

		if (!mainChild) return;
		let { content, tips } = mainChild.props;
		let subTips;
		let subContent;
		if (mainChild.type === SidebarDropdown) {
			let subChild = filterUndifined(mainChild.props.children)[
				selectedContent.subContent
			];
			//console.log("subChild", subChild);

			if (!subChild) return;
			subContent = subChild.props.content;
			subTips = subChild.props.tips;
		}
		if (whichProp === "content") {
			return (
				<>
					{content}
					{subContent}
				</>
			);
		} else if (whichProp === "tips") {
			return (
				<>
					{tips}
					{subTips}
				</>
			);
		}
	}

	return (
		<div className="grid grid-cols-[minmax(0,_1fr)_minmax(0,_1280px)_minmax(0,_1fr)] grid-rows-[minmax(auto,_1fr)] overflow-y-hidden max-sidebar:flex max-sidebar:flex-row max-sidebar3:overflow-x-hidden">
			<Sidebar
				selectedContent={selectedContent}
				changeSelectedContent={changeSelectedContent}
				itemProps={itemPropsState}
			></Sidebar>
			<SidebarFrameContent
				content={getSelectedProps("content")}
                tips={getSelectedProps("tips")}
			></SidebarFrameContent>
			{/* <div className="flex justify-end max-sidebar:hidden overflow-x-hidden overflow-y-scroll scrollbar-track-neutral-200 scrollbar-thin scrollbar-thumb-neutral-400">
				<SidebarFrameHelp tips={getSelectedProps("tips")} />
			</div> */}
		</div>
	);
};
