import React from "react";

/**
 * COMPONENT USED INSIDE OF SIDEBARFRAME (OR DROPDOWN) TO RENDER A SELECTABLE SIDEBAR BUTTON
 * 
 * PROPS:
 * @param {Object} itemProps props to customize the sidebar selectable button
 * @param {*} content Component to render in sidebar frame content when button is selected
 * @param {*} tips Component to render in quick tips when button is selected
 */
export const SidebarItem = ({ itemProps, content, tips }) => {
	return <div></div>;
};
