/* This component contain a form element where the user can read and update their own data. */

import React from "react";
import { useProfileContext } from "pages/contexts/profileContext";
import { motion, AnimatePresence } from "framer-motion";
// Components
import { DataVisualizer } from "layouts";

const ProfileFormSection = ({
  title = "",
  description = "",
  editModeButtonTitle = "",
  isEditable = false,
  setIsEditable = () => {},
  isUpdateLoading = false,
  children,
}) => {
  // Import required context variables/functions
  const { isLoading, combinedUserData, fadeIn, preventDoubleClick } =
    useProfileContext();

  return (
    <>
        <div className="flex flex-col sm:flex-row gap-2 sm:gap-0 items-center justify-between mb-4">
            <div className="mx-3">
              <AnimatePresence initial={false}>
                {isLoading || !combinedUserData?.lastName ? (
                  <>
                    <motion.div
                      initial="initial"
                      animate="animate"
                      variants={fadeIn}
                      role="status"
                      className="w-full animate-pulse self-center"
                    >
                      <div className="h-6 sm:h-6 md:h-7 bg-gray-300 rounded-full dark:bg-gray-700 w-[180px] sm:w-[190px] mb-2 sm:mb-2 m-auto sm:mr-0 sm:ml-0 sm:mt-0"></div>
                      <div className="h-5 sm:h-5 bg-gray-300 rounded-full dark:bg-gray-700 w-[250px] sm:w-[250px] m-auto "></div>
                      <span className="sr-only">Loading...</span>
                    </motion.div>
                  </>
                ) : (
                  <>
                    <motion.h1
                      initial="initial"
                      animate="animate"
                      variants={fadeIn}
                      data-testid="profile-information"
                      className="text-lg md:text-xl text-center sm:text-left font-bold tracking-tight text-gray-500"
                    >
                      {title}
                    </motion.h1>
                    <motion.p
                      initial="initial"
                      animate="animate"
                      variants={fadeIn}
                      className="mt-1 max-w-2xl text-sm md:text-base leading-6 text-gray-500"
                    >
                      {description}
                    </motion.p>
                  </>
                )}
              </AnimatePresence>
            </div>
            <div className="mx-3" style={{ display: "flex", gap: "1rem" }}>
              <AnimatePresence initial={false}>
                {isLoading || !combinedUserData?.lastName ? (
                  <>
                    <motion.div
                      initial="initial"
                      animate="animate"
                      variants={fadeIn}
                      role="status"
                      className="max-w-sm animate-pulse"
                    >
                      <div className="h-5 bg-gray-300 rounded-full dark:bg-gray-700 w-40 mt-2"></div>
                      <span className="sr-only">Loading...</span>
                    </motion.div>
                  </>
                ) : (
                  <>
                    {!isEditable ? (
                      <motion.p
                        initial="initial"
                        animate="animate"
                        data-testid="edit-mode-button"
                        variants={fadeIn}
                        onClick={() => setIsEditable(!isEditable)}
                        onDoubleClick={preventDoubleClick}
                        className={`capitalize mt-1 max-w-2xl text-sm md:text-base leading-6  ${
                          !isUpdateLoading
                            ? "cursor-pointer text-cyan-500 hover:text-cyan-600"
                            : "cursor-default text-gray-500"
                        } `}
                      >
                        {editModeButtonTitle}
                      </motion.p>
                    ) : (
                      <motion.p
                        initial={{ opacity: 1 }}
                        animate={{ opacity: 0 }}
                        className={`capitalize mt-1 max-w-2xl text-sm md:text-base leading-6  ${
                          !isUpdateLoading
                            ? "cursor-default text-cyan-500 hover:text-cyan-600"
                            : "cursor-default text-gray-500"
                        } `}
                      >
                        {editModeButtonTitle}
                      </motion.p>
                    )}
                  </>
                )}
              </AnimatePresence>
            </div>
          </div>
        {children}
    </>
  );
};

export default ProfileFormSection;
