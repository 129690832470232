// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCR_LaLuEppzS95b4hu_LxgauXP_pn40z0",
  authDomain: "castor-a01c0.firebaseapp.com",
  databaseURL: "https://castor-a01c0-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "castor-a01c0",
  storageBucket: "castor-a01c0.appspot.com",
  messagingSenderId: "1034062017597",
  appId: "1:1034062017597:web:116e4a7b9253d3a56aa654",
  measurementId: "G-0GLHQ5PM0M"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);

// Initialize Realtime Database and get a reference to the service
export const database = getDatabase(app);

// Initialize Cloud Storage and get a reference to the service
export const storage = getStorage(app);

export default app;