export const colorsForNames = {
	light: [
		"#e795a1",
		"#e79680",
		"#d1934d",
		"#bc9b4a",
		"#a79e46",
		"#73ab47",
		"#48af6c",
		"#4baaab",
		"#84aee6",
        "#e38be7"
	],
	dark: 
		["#e795a1",
		"#e79680",
		"#d1934d",
		"#bc9b4a",
		"#a79e46",
		"#73ab47",
		"#48af6c",
		"#4baaab",
		"#84aee6",
        "#e38be7"]
	,
};

export const tailwindColorsForNames = {
    light: {
        "#e795a1": `text-[#e795a1]`,
        "#e79680": "text-[#e79680]",
        "#d1934d": "text-[#d1934d]",
        "#bc9b4a": "text-[#bc9b4a]",
        "#a79e46": "text-[#a79e46]",
        "#73ab47": "text-[#73ab47]",
        "#48af6c": "text-[#48af6c]",
        "#4baaab": "text-[#4baaab]",
        "#84aee6": "text-[#84aee6]",
        "#e38be7": "text-[#e38be7]",
    },
    dark: {
        "#e795a1": `text-[#e795a1]`,
        "#e79680": "text-[#e79680]",
        "#d1934d": "text-[#d1934d]",
        "#bc9b4a": "text-[#bc9b4a]",
        "#a79e46": "text-[#a79e46]",
        "#73ab47": "text-[#73ab47]",
        "#48af6c": "text-[#48af6c]",
        "#4baaab": "text-[#4baaab]",
        "#84aee6": "text-[#84aee6]",
        "#e38be7": "text-[#e38be7]",
    },
};
