import React from "react";
import {
	signInWithEmailAndPassword,
	GoogleAuthProvider,
	getAuth,
	signInWithPopup,
	setPersistence,
	browserLocalPersistence,
	browserSessionPersistence,
} from "firebase/auth";
import { getDatabase, ref, child, get, set } from "firebase/database";
import { Link, useNavigate } from "react-router-dom";
import { LoginForm } from "features/authentication";
import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import { toast } from "react-toastify";

export const Login = () => {
	const navigate = useNavigate();
	const provider = new GoogleAuthProvider();
	const auth = getAuth();
	auth.useDeviceLanguage();

	const handleEmailLogin = (email, password, remember) => {
		const persistence = remember
			? browserLocalPersistence
			: browserSessionPersistence;
		setPersistence(auth, persistence)
			.then(() => {
				//console.log("login");
				signInWithEmailAndPassword(auth, email, password)
					.then((userCredential) => {
						// Signed in
						const user = userCredential.user;
						//setUser(user);
						navigate("/home");
						//console.log(user);
					})
					.catch((error) => {
						const errorCode = error.code;
						const errorMessage = error.message;
						let toastError;
						switch (errorCode) {
							case "auth/wrong-password":
								toastError = "The password is incorrect.";
								break;
							case "auth/user-not-found":
								toastError = "This user does not exist.";
								break;
							case "auth/too-many-requests":
								toastError =
									"Too many failed attempts. Try again later.";
								break;
							default:
								toastError = `Sign in failed. (${errorMessage})`;
								break;
						}
						toast.error(toastError, {
							position: "top-center",
						});
						console.log(errorCode);
					});
			})
			.catch((error) => {
				// Handle Errors here.
				const errorCode = error.code;
				const errorMessage = error.message;
			});
	};

	const handleGoogleLogin = (remember) => {
		const persistence = remember
			? browserLocalPersistence
			: browserSessionPersistence;
		setPersistence(auth, persistence)
			.then(() => {
				const provider = new GoogleAuthProvider();
				// In memory persistence will be applied to the signed in Google user
				// even though the persistence was set to 'none' and a page redirect
				// occurred.
				signInWithPopup(auth, provider)
					.then((result) => {
						// This gives you a Google Access Token. You can use it to access the Google API.
						const credential =
							GoogleAuthProvider.credentialFromResult(result);
						const token = credential.accessToken;
						// The signed-in user info.
						const user = result.user;

						registerWithGoogle(user);

						navigate("/home");
						// IdP data available using getAdditionalUserInfo(result)
						// ...
					})
					.catch((error) => {
						// Handle Errors here.
						const errorCode = error.code;
						const errorMessage = error.message;
						// The email of the user's account used.
						const email = error.customData.email;
						// The AuthCredential type that was used.
						const credential =
							GoogleAuthProvider.credentialFromError(error);
						// ...
					});
			})
			.catch((error) => {
				// Handle Errors here.
				const errorCode = error.code;
				const errorMessage = error.message;
			});
	};

	const writeUserData = (userId, data) => {
		const db = getDatabase();
		set(ref(db, "users/" + userId), data);
	};

	const registerWithGoogle = ({ uid, displayName, email }) => {
		const fullNameArray = displayName.split(" ");
		const firstName = fullNameArray[0] || "";
		const lastName = fullNameArray[1] || "";

		// console.log("uid: ", uid);
		// console.log("firstName: ", firstName);
		// console.log("lastName: ", lastName);
		// console.log("email: ", email);

		const dbRef = ref(getDatabase());
		get(child(dbRef, `users/${uid}`))
			.then((snapshot) => {
				if (!snapshot.exists()) {
					writeUserData(uid, {
						firstName: firstName,
						lastName: lastName,
						email: email,
					});
				}
			})
			.catch((error) => {
				console.log("FIREABASE ERROR");
				console.error(error);
			});
	};

	return (
		<>
			<main>
				<section>
					<div className={"px-5 py-5"}>
						<Link to={"/"}>
							<ArrowLeftIcon
								className="block h-6 w-6"
								aria-hidden="true"
							/>
						</Link>
					</div>

					<LoginForm
						emailLogin={handleEmailLogin}
						googleLogin={handleGoogleLogin}
					/>
				</section>
			</main>
		</>
	);
};
