import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  getAuth,
} from "firebase/auth";

/* Reauthenticate registered user with email and old password */
export const handleReauthentication = async (email, password) => {
  const reUser = getAuth().currentUser;
  const credential = EmailAuthProvider.credential(email, password);

  await reauthenticateWithCredential(reUser, credential);
};
