import React from "react";
import SidebarListButton from "./SidebarListButton";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import { AnimatePresence, motion } from "framer-motion";

/**
 * THIS COMPONENT IS A DROPDOWN NON-SELECTABLE BUTTON INSIDE A LIST
 * 
 * PROPS:
 * @param {Boolean} isSidebarClosed
 * @param {Function} toggleSidebarClose
 * @param {Function} changeSelectedContent used to pass as props to sub-buttons
 * @param {Function} isButtonSelected used to check if any sub-button is selected
 * @param {Number} mainIndex index of the actual dropdown button in the list
 * @param {Object} mainItemProps props given and calculated from SidebarDropdown
 * @param {Array} subItemProps props given and calculated from SidebarItems inside of a SidebarDropdown
 * @param {boolean} isMobile (optional)
 */
const SidebarListDropdownButton = ({
	isSidebarClosed,
	toggleSidebarClose,
	isButtonSelected,
	changeSelectedContent,
	mainIndex,
	mainItemProps,
	subItemProps,
    isMobile=false,
}) => {
	const { title, icon, disabled = false } = mainItemProps;

    /**
     * is the dropdown button currently open/dropped down (TRUE) or closed (FALSE)
     */
	const [isDropdown, setIsDropdown] = React.useState(false);

	const toggleDropdown = () => {
		if (isSidebarClosed) {
			toggleSidebarClose();
			setIsDropdown(true);
		} else {
			setIsDropdown((prev) => !prev);
		}
	};

    /**
     * STYLE OF THE ACTUAL DROPDOWN (non-selectable) BUTTON
     */
	const listButtonStyle =
		"text-black font-semibold w-full flex flex-row items-center px-4 py-[8px] rounded-medium opacity-60 hover:opacity-100 bg-black hover:bg-opacity-5 duration-200";

    /**
     * framer-motion variant animation for the dropdown - non selectable button
     */
	const variants = {
		visible: {
			width: "auto",
			opacity: 1,
			transition: {
				duration: 0.2,
				delay: 0.2,
			},
		},
		hidden: {
			width: 0,
			opacity: 0,
			transition: {
				duration: 0,
			},
		},
	};

    /**
     * framer motion variant animation for the dropdow list appearance
     */
	const dropdownAnimation = {
		visible: {
			height: "auto",
			width: "auto",
			opacity: 1,
			transition: {
				duration: 0.2,
			},
		},
		hidden: {
			height: 0,
			width: 0,
			opacity: 0,
			transition: {
				duration: 0.2,
			},
		},
	};

    /**
     * framer motion variant animation for line when dropdown list appears
     */
	const dropdownAnimationForLine = {
		visible: {
			height: "100%",
			transition: {
				delay: 0.2,
				duration: 0.5,
			},
		},
		hidden: {
			height: 0,
			transition: {
				duration: 0.2,
			},
		},
	};

    /**
     * framer-motion variant animtion for tooltip appearance
     */
    const showToolTipAnimation = {
		visible: {
			opacity: 1,
			transition: {
                delay: 0.5,
				duration: 0.2,
			},
		},
		hidden: {
			opacity: 0,
			transition: {
				duration: 0.2,
			},
		},
	};

	const [isHovered, setIsHovered] = React.useState(false);

	return (
		<div className="w-full">
            {/* ACTUAL DROPDOWN BUTTON */}
			<button
                onMouseOver={() => {
                    setIsHovered(true);
                }}
                onMouseOut={() => {
                    setIsHovered(false);
                }}
				disabled={disabled}
				data-testid="SidebarListDropdownButton-dropdownButton"
				onClick={toggleDropdown}
				className={`${listButtonStyle} ${
					isSidebarClosed ? "justify-center" : "justify-between"
				} ${
					(!isDropdown || isSidebarClosed) &&
					isButtonSelected(mainIndex)
						? "bg-opacity-10"
						: "bg-opacity-0"
				}`}
			>
                {/* DROPDOWN BUTTON --> ICON AND TITLE */}
				<div className="flex flex-row justify-start gap-3">
					<div
						data-testid="SidebarListDropdownButton-IconDiv"
						className="h-6 w-6"
					>
						{icon}
					</div>
					<AnimatePresence>
						{!isSidebarClosed && (
							<motion.p
								data-testid="SidebarListDropdownButton-dropdownButton-text"
								initial="hidden"
								animate="visible"
								exit="hidden"
								variants={variants}
								className="whitespace-nowrap"
							>
								{title}
							</motion.p>
						)}
					</AnimatePresence>
				</div>
                {/* DROPDOWN BUTTON --> DROPDOWN TOGGLE ICON */}
				{!isSidebarClosed && (
					<motion.div
						data-testid="SidebarListDropdownButton-dropdownButton-icon"
						animate={{ rotate: isDropdown ? 180 : 0 }}
						className="h-4 w-4"
					>
						<ChevronDownIcon />
					</motion.div>
				)}
                {/* DROPDOWN BUTTON --> TOOLTIP */}
				<AnimatePresence>
					{isSidebarClosed && isHovered && (
						<motion.div
                            data-testid="SidebarListDropdownButton-toolTip"
							initial="hidden"
							animate="visible"
							exit="hidden"
							variants={showToolTipAnimation}
							className="left-[105px] absolute whitespace-nowrap px-6 py-2 rounded-medium z-40 overflow-hidden bg-custom text-white text-sm"
						>
							{title}
						</motion.div>
					)}
				</AnimatePresence>
			</button>
            {/* DROPDOWN BOX */}
			<AnimatePresence>
				{!isSidebarClosed && isDropdown && (
					<motion.div
						initial="hidden"
						animate="visible"
						exit="hidden"
						variants={dropdownAnimation}
						className={"grid grid-cols-[10px_minmax(0,_1fr)]"}
					>
                        {/* DROPDOWN LINE ANIMATION */}
						{!isSidebarClosed && (
							<div className="w-[2px] h-full py-3">
								<AnimatePresence>
									<motion.div
										initial="hidden"
										animate="visible"
										exit="hidden"
										variants={dropdownAnimationForLine}
										className="w-full bg-black bg-opacity-20 ml-7 rounded-full"
									></motion.div>
								</AnimatePresence>
							</div>
						)}
                        {/* ACTUAL LIST WITH BUTTONS */}
						<ul
							className={`w-full flex flex-col gap-[4px] mt-2 mb-2 ${
								isSidebarClosed ? "pl-0" : "pl-8"
							}`}
						>
							{subItemProps.map((subProps, subIndex) => (
								<SidebarListButton
									key={subProps.title}
									testid="SidebarListDropdownButton-dropdownListItems"
                                    toggleSidebarClose={toggleSidebarClose}
									isSidebarClosed={isSidebarClosed}
									isSelected={isButtonSelected(
										mainIndex,
										subIndex
									)}
									changeSelectedContent={
										changeSelectedContent
									}
									mainIndex={mainIndex}
									subIndex={subIndex}
									itemProps={subProps}
                                    isMobile={isMobile}
								/>
							))}
						</ul>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};

export default SidebarListDropdownButton;
