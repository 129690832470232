import React from 'react';

export function NextButton(props) {

    const disabledStyle = "mt-3 inline-flex w-full justify-center rounded-full bg-blue-50 outline-neutral-500 px-3 py-2 text-sm font-semibold text-neutral-600 shadow-md ring-1 ring-inset ring-neutral-500 sm:mt-0 sm:w-auto opacity-60 cursor-not-allowed"
    const buttonStyle= "mt-3 inline-flex w-full justify-center rounded-full bg-blue-50 outline-blue-500 px-3 py-2 text-sm font-semibold text-blue-600 shadow-md ring-1 ring-inset ring-blue-500 hover:bg-blue-600 hover:text-white sm:mt-0 sm:w-auto";

    return (
        <button
            {...props}
            className={props.disabled ? disabledStyle : buttonStyle}>
            {props.children}
        </button>
    );
}
