import React from 'react';
import { Formik, Form, Field } from 'formik';
import { NavLink } from 'react-router-dom'
import * as yup from 'yup';
import logo from "assets/images/logo-icon.png"

const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
});

export const ForgotPasswordForm = (
  {
    onForgotPassword
  }
) => {
  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-20 w-auto"
            src={logo}
            alt="Actome"
          />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Forgot password
          </h2>
      </div>
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <Formik
          initialValues = {{
            email: '',
          }}
          validationSchema={validationSchema}
          onSubmit = {(values) => onForgotPassword(values.email)}
        >
          {({ errors, touched, values }) => (<Form className="space-y-6">
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Email address
              </label>
              <div className="mt-2">
                <Field
                  id="email"
                  name="email"
                  type="email"
                  className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"

                />
              </div>
              {errors.email && touched.email ? (
                  <div className="pl-2 block text-sm leading-6 text-red-600">{errors.email}</div>
                ) : null}
            </div>
            <div>
              <button 
              type="submit"
              className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                Send email
              </button>
            </div>
          </Form>)}
        </Formik>

        <p className="mt-10 text-center text-md text-gray-500">
            <NavLink to="/login" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
              Back
            </NavLink>
          </p>
      </div>
      
    </div>
  );
};
