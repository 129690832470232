import React from "react";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { getDatabase, ref, set } from "firebase/database";
import { auth } from "../../firebase";
import { RegisterForm } from "features/authentication";
import { toast } from "react-toastify";
import { handleEmailVerification } from "features/authentication/actions";
import secureLocalStorage from "react-secure-storage";

export const Register = () => {
  const writeUserData = (userId, data) => {
    const db = getDatabase();
    set(ref(db, "users/" + userId), data);
  };

  const onRegister = async (email, password, customClaims) => {
    await createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        const uid = user.uid;

        const data = customClaims;
        writeUserData(uid, data);

        secureLocalStorage.setItem("credentialsForSignIn", {
          email: email,
          pwd: password,
        }); // store password at localStorage to sign in after verification

        handleEmailVerification();

        toast.success("Account created successfully!", {
          position: "top-center",
        });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        let toastError;
        switch (errorCode) {
          case "auth/email-already-in-use":
            toastError = "This email is already in use.";
            break;
          default:
            toastError = `Account creation failed. (${errorMessage})`;
            break;
        }
        toast.error(toastError, {
          position: "top-center",
        });
        // ..
      });
  };

  return (
    <main>
      <section>
        <RegisterForm onRegister={onRegister} />
      </section>
    </main>
  );
};
