import React from "react";

export const ProjectDetailsHelp = () => {
	return (
		<div id="tips">
			<ul className="space-y-2">
				<li>
					<strong>Add Sample Definition File:</strong>
					<br /> Click on the "Add file" button under the "Sample
					definition file" section to upload a sample definition file.
				</li>
				<li>
					<strong>Add Raw Data Files:</strong>
					<br /> Click on the "Add file" button under the "Raw data
					files" section to upload raw data files. You can add
					multiple files.
				</li>
				<li>
					<strong>Delete Files:</strong>
					<br /> If you need to delete a file, click on the delete
					button next to the file you want to remove. Deleting files
					will trigger a recalculation of the project results.
				</li>
				<li>
					<strong>Calculate Results:</strong>
					<br /> Once you have uploaded the required files, click on
					the "Calculate results" button to initiate the analysis and
					generate the project results.
				</li>
				<li>
					<strong>View Results:</strong>
					<br /> After the analysis is complete, you can access the
					generated graphs by clicking the "Results" button on your
					dashboard.
				</li>
			</ul>
		</div>
	);
};
