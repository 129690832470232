import { get } from "firebase/database";
import * as XLSX from "xlsx/xlsx.mjs";

/**
 * This function generates an array with the graph vaules ordered by the well focused on couplex values
 * @param {Array} graphs
 * @returns formatted array
 */
export const getCouplexDataOrderedByWell = (graphs) => {
  let arr = {};

  graphs.forEach((graph) => {
    graph.forEach((sample) => {
      sample.y.forEach((y) => {
        if (!(y.well in arr)) {
          arr[y.well] = {
            well: y.well,
            sample: sample.Name,
            antibodyMix: sample.sampleId,
            group: sample.Group,
            GY: 0,
            GO: 0,
            GR: 0,
            YO: 0,
            YR: 0,
            OR: 0,
          };
        }

        arr[y.well] = {
          ...arr[y.well],
          [sample.Type]: y.couplex,
        };
      });
    });
  });

  let result = [];

  Object.keys(arr).map((key) => {
    result.push({
      ...arr[key],
      key,
    });
  });

  result.sort((a, b) => {
    if (a.well < b.well) return -1;
    else if (a.well > b.well) return 1;
    else return 0;
  });

  return result;
};

export const getLambdaDataOrderedByWell = (graphs) => {
  let arr = {};

  graphs.forEach((graph) => {
    let grph = {};
    graph.forEach((sample) => {
      sample.lambdas.left.forEach((lambda) => {
        grph[lambda.well] = {
          well: lambda.well,
          sample: sample.Name,
          group: sample.Group,
          antibodyLeft: sample.lambdas.antibodyleft,
          lambdaLeft: lambda.value,
        };
      });
	    sample.lambdas.right.forEach((lambda) => {
        grph[lambda.well] = {
          ...grph[lambda.well],
          antibodyRight: sample.lambdas.antibodyright,
          lambdaRight: lambda.value,
        };
      });
    });
    let result = [];

    Object.keys(grph).map((key) => {
      result.push({
        ...grph[key],
        key,
      });
    });

    result.sort((a, b) => {
      if (a.well < b.well) return -1;
      else if (a.well > b.well) return 1;
      else return 0;
    });

    arr[graph[0].Type] = result;

  });
  console.log(arr);
  return arr;
};

/**
 * converts and returns an array to the CSV format
 * @param {Array} data
 * @returns CSV
 */
function arrayToCsv(data) {
  return data
    .map(
      (row) =>
        row
          .map(String) // convert every value to String
          .map((v) => v.replaceAll('"', '""')) // escape double colons
          .map((v) => `"${v}"`) // quote it
          .join(",") // comma-separated
    )
    .join("\r\n"); // rows starting on new lines
}

/**
 * downloads a file with the given proprerties
 * @param {*} content the file content to be downloaded
 * @param {String} filename the file name
 * @param {String} contentType the file format --> .csv/.xlsx
 */
function downloadBlob(content, filename, contentType) {
  // Create a blob
  var blob = new Blob([content], { type: contentType });
  var url = URL.createObjectURL(blob);

  // Create a link to download it
  var pom = document.createElement("a");
  pom.href = url;
  pom.setAttribute("download", filename);
  pom.click();
}

const headers = {
  couplex: [
    "Well",
    "Sample",
    // "Antibody_Mix",
    "Experimental_Group",
    "GY_Couplexes",
    "GO_Couplexes",
    "GR_Couplexes",
    "YO_Couplexes",
    "YR_Couplexes",
    "OR_Couplexes",
  ],
  lambda: [
    "Well",
    "Sample",
    "Experimental_Group",
    "Antibody Left",
    "Antibody Right",
    "Lambda Left",
    "Lambda Right",
  ],
};

/**
 * fills the given array with the content
 * @param {Array} arr fills this array
 * @param {Array} graphs fills it with this graph values
 */
const fillArray = (arr, type, data) => {
  if (type === "lambda") {
    data.forEach((res) => {
      arr.push([
        res.well,
        res.sample,
        res.group,
        res.antibodyLeft,
        res.antibodyRight,
        res.lambdaLeft,
        res.lambdaRight,
      ]);
    });
    return arr;
  } else if (type == "couplex") {
    data.forEach((res) => {
      arr.push([
        res.well,
        res.sample,
        res.group,
        res.GY,
        res.GO,
        res.GR,
        res.YO,
        res.YR,
        res.OR,
      ]);
    });
    return arr;
  }
  return;
};

/**
 * download .csv
 * @param {Array} graphs graph values
 */
export const downloadCSVFile = (graphs, type, projectTitle) => {
  let data = [headers[type]];
  fillArray(data, type, graphs);
  // Generate CSV from array
  const csv = arrayToCsv(data);
  downloadBlob(
    csv,
    projectTitle + `_${type}_data.csv`,
    "text/csv;charset=utf-8;"
  );
};

/**
 * download .xlsx
 * @param {Array} graphs graph values
 */
export const downloadXLSXFile = (graphs, type, projectTitle) => {
  const workbook = XLSX.utils.book_new();
  if(type === "lambda") {
    Object.entries(getLambdaDataOrderedByWell(graphs)).map(([key, value]) => {
      let data = [headers[type]];
      const sheetName = key;
      console.log(value);
      const sheet_data = fillArray(data, type, value);
      console.log(sheet_data);
      const worksheet = XLSX.utils.aoa_to_sheet(sheet_data);
      XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    });
  }
  else if(type === "couplex") {
    let data = [headers[type]];
    fillArray(data, type, getCouplexDataOrderedByWell(graphs));
    const sheetName = "Samples1";
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
  }

  // Generate the XLSX file
  const excelBuffer = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array",
  });
  downloadBlob(
    excelBuffer,
    projectTitle + `_${type}_data.xlsx`,
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  );
};
