import {getAuth, sendEmailVerification} from "firebase/auth";
import {toast} from "react-toastify";

export const handleEmailVerification = () => {
    const auth = getAuth();
    sendEmailVerification(auth.currentUser)
        .then(() => {
            // Email verification sent!
            toast.success(
                "The verification email has been sent! Check your email!",
                {
                    position: "top-center",
                }
            );
        });
}