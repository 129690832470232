import React from "react";
import { ClickAwayListener, Tooltip, styled, tooltipClasses } from "@mui/material";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import ReportABugForm from "./ReportABugForm";

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 500,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

const ReportABug = () => {
    const [open, setOpen] = React.useState(false);
    const formikRef = React.useRef(null);

    const handleTooltipClose = () => {
        setOpen(prev => {
            if (prev) sessionStorage.setItem("reportBugMessage", formikRef?.current?.values.message ?? "");
            return false;
        }) ;
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };
    return <ClickAwayListener 
            onClickAway={handleTooltipClose}   
        >
        <div>
            <HtmlTooltip
                title={<ReportABugForm closeTooltip={handleTooltipClose} ref={formikRef}/>}
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
            >
                <button
                    className="text-orange-400 flex gap-1 items-center hover:underline mr-4 cursor-pointer h-[60px]"
                    onClick={handleTooltipOpen}
                >
                    <EnvelopeIcon className="h-5 w-5"/>
                    <p className="hidden sm:block">
                        Contact us
                    </p>
                </button>
            </HtmlTooltip>
        </div>
    </ClickAwayListener>
}

export default ReportABug;