import * as d3 from "d3";

const amulator_data = (emudata_from_amulator) => {
  return emudata_from_amulator.map((sample) => {
    let c = [];

    sample.colors.map((color) => {
      let r = [];
      let l = [];
      color.values.rightlambda.map((rlambda) => {
        r.push(rlambda / 1000);
      });
      color.values.leftlambda.map((llambda) => {
        l.push(llambda / 1000);
      });
      c.push(
        Object.assign({}, color, {
          values: Object.assign({}, color.values, {
            rightlambda: r,
            leftlambda: l,
          }),
        })
      );
    });
    return Object.assign({}, sample, {
      colors: c,
    });
  });
};

const nanoid = (t = 21) =>
  crypto
    .getRandomValues(new Uint8Array(t))
    .reduce(
      (t, e) =>
        (t +=
          (e &= 63) < 36
            ? e.toString(36)
            : e < 62
            ? (e - 26).toString(36).toUpperCase()
            : e > 62
            ? "-"
            : "_"),
      ""
    );

function processEmudata(data) {
  const samplesABC = data.filter((e) => e.abc); // filter only if sample.abc true

  const abcmeans = samplesABC.map(({ samplegroup, colors }) => {
    for (const color of colors) {
      const mean = d3.mean(color.values.couplex, (e) => e.value);
      return {
        group: samplegroup,
        colors: {
          colorId: color.colorId,
          color: color.colorpair,
          mean: mean,
        },
      };
    }
  });

  const sortedData = [...data];
  // console.log(sortedData);
  //   .sort((a, b) => {
  //   const reg = /\d+/;
  //   a = a.samplename.match(reg);
  //   b = b.samplename.match(reg);
  //   return a - b;
  // });

  let cnt = 0;
  const bardata = [];
  for (const { samplename, sampleId, samplegroup, colors, abc } of sortedData) {
    for (const color of colors) {
      const item = {
        abc,
        Type: color.colorpair,
        sampleId,
        Name: samplename,
        Included: true,
        lambdas: {
          antibodyright: color.antibodyright,
          antibodyleft: color.antibodyleft,
          x: samplename,
          left: color.values.leftlambda.map((l, i) => ({
            ...l,
            well: color.values.well[i].value,
          })),
          right: color.values.rightlambda.map((l, i) => ({
            ...l,
            well: color.values.well[i].value,
          })),
        },
        Group: samplegroup,
        samples: [],
      };
      for (const [k, couplex] of color.values.couplex.entries()) {
        const groupcolor = abcmeans.filter(
          (obj) =>
            obj.group === samplegroup && obj.colors.color === color.colorpair
        );
        let colormean;
        // if (groupcolor.length == 1 && abc_sub) {
        //   colormean = groupcolor[0].colors.mean;
        // } else {
        //   colormean = 0;
        // }
        item.samples.push({
          name: "S" + (k + 1),
          id: couplex.id,
          couplex: couplex.value,
          well: color.values.well[k].value,
          value: couplex.value, // - colormean
        });
      }
      // console.log(item);
      bardata.push(item);
    }
  }
  //console.log(bardata);
  return bardata;
}
function makeEmudataIds(emudata) {
  return emudata.map((e) => {
    const colors = e.colors.map((color) => {
      const ids = [...color.values.couplex].map((_) => nanoid(5));
      const colorValues = Object.entries(color.values).map(([key, values]) => [
        key,
        values.map((v, i) => ({
          value: v,
          id: ids[i],
        })),
      ]);
      return {
        ...color,
        colorId: nanoid(5),
        values: Object.fromEntries(colorValues),
      };
    });
    return { ...e, colors, sampleId: nanoid(5) };
  });
}

function calculateBoxPlotSingle(
  x,
  sampleId,
  points,
  tickWidth,
  color_pair,
  boxwidth
) {
  points.sort((a, b) => a.value - b.value);
  const values = points.map((d) => d.value);
  const min = values[0];
  const max = values[values.length - 1];
  const q1 = d3.quantile(values, 0.25);
  const q2 = d3.quantile(values, 0.5);
  const q3 = d3.quantile(values, 0.75);
  const iqr = q3 - q1; // interquartile range
  const r0 = Math.max(min, q1 - iqr * 1.5);
  const r1 = Math.min(max, q3 + iqr * 1.5);
  const quartiles = [q1, q2, q3];
  const range = [r0, r1];
  const sqrtN = Math.sqrt(values.length);
  const mean = d3.mean(values);
  const sigma = d3.deviation(values) || null;
  const CI = [mean - sigma, mean + sigma];
  const notch = (1.58 * iqr) / sqrtN;
  const boxWidth = null; //tickWidth * sqrtN; // Error (unidentified): the original code produces variable boxplot widths
  const y = points.map((point) => ({
    type: "point",
    sampleId,
    couplex: point.couplex,
    well: point.well,
    id: point.id,
    y: point.value,
    x: 0.5, //Math.random(),
    boxWidth,
  }));
  const outliers = y.filter((e) => e.y < r0 || e.y > r1);

  return {
    type: "sample",
    sampleId,
    quartiles,
    range,
    y,
    outliers,
    x,
    sqrtN,
    mean,
    points,
    sigma,
    boxWidth,
    CI,
    notch,
  };
}

const tickWidth = 10;

const colorsBins = (amulator_data) => {
  return d3.group(
    [...processEmudata(makeEmudataIds(amulator_data))].map((e) => ({
      ...e,
      ...calculateBoxPlotSingle(
        e.Name,
        e.sampleId,
        e.samples,
        tickWidth,
        e.Type
      ),
    })),
    (e) => e.Type
  );
};

export const makeGraphData = (data) => {
  const amulatorData = amulator_data(data);
  //console.log(amulatorData);
  const colors = colorsBins(amulatorData);
  return(colors);
};
