/* This is a context for profile page components. */

import React, { useState, useContext } from "react";

const AppContext = React.createContext();

const ProfileProvider = ({ children }) => {
  const [combinedUserData, setCombinedUserData] = useState({}); // This variable contains every data about current user
  const [isEditable, setIsEditable] = useState(null); // state variable for personal details form. If true then enable input fields, if false then disable input fields
  const [isPasswordEditable, setIsPasswordEditable] = useState(null); // state variable for password form. Triggered when user click change password button and 'cancel changing' button
  const [isGoogleProfile, setIsGoogleProfile] = useState(null); // state variable to know user logged in with google profile or registered user
  const [personalDetailsFomIsUpdating, setPersonalDetailsFomIsUpdating] =
    useState(false); // store true/false when the personal details form is updating
  const [passwordFormIsUpdating, setPasswordFormIsUpdating] = useState(false); // store true/false when the password form is updating

  // Function to change button state to enabled or disabled. First parameter is a ref value (button element). Second parameter true/false which decide the button should be disabled or not.
  const handleButtonState = (buttonRef, isDisabled) => {
    try {
      buttonRef.current.disabled = isDisabled;
    } catch (error) {}
  };

  // Function to prevent double click event
  const preventDoubleClick = (e) => {
    e.preventDefault();
  };

  // framer motion fade in transition
  const fadeIn = {
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
      transition: { duration: 0.5 },
    },
    in: {
      opacity: 1,
      transition: { duration: 0.5 },
    },
    out: {
      opacity: 0,
      transition: {
        duration: 0.6,
      },
    },
  };
  const fadeInOutModal = {
    initial: { opacity: 0, display: "none" },
    animate: {
      opacity: 1,
      transition: { duration: 0.5 },
    },
    in: {
      display: "flex",
      opacity: 1,
      transition: { duration: 0.7 },
    },
    out: {
      opacity: 0,
      transition: {
        duration: 0.6,
      },
      transitionEnd: {
        display: "none",
      },
    },
  };
  const fadeInOutHeight = {
    initial: { opacity: 0, display: "none" },
    animate: {
      opacity: 1,
      transition: { duration: 0.5 },
    },
    in: {
      display: "flex",
      opacity: 1,
      height: "auto",
      transition: { duration: 0.5 },
    },
    out: {
      opacity: 0,
      height: 0,
      transition: {
        duration: 0.6,
      },
      transitionEnd: {
        display: "none",
      },
    },
  };
  // framer motion button transition
  const buttonInAndOut = {
    initial: { opacity: 0, scale: 0 },
    show: {
      display: "flex",
      opacity: 1,
      scale: 1,
      transition: { duration: 0.5 },
    },
    hide: {
      opacity: 0,
      scale: 0,
      height: 0,
      transition: {
        height: { duration: 0.6, delay: 0.7 },
        scale: { duration: 0.3 },
        opacity: { delay: 0.4 },
        duration: 0.5,
      },
    },
  };
  //  framer motion input field animation
  const inputAnimations = {
    default: {
      display: "grid",
      opacity: 1,
      height: "auto",
    },
    initial: {
      display: "none",
      height: 0,
      opacity: 0,
    },
    show: (i) => ({
      opacity: 1,
      height: "90px",
      display: "grid",
      ease: [0.075, 0.82, 0.165, 1],
      transition: {
        height: { duration: 1 },
        opacity: { duration: 0.6, delay: 0.4 },
        ease: "easeInOut",
        duration: 1,
      },
    }),
    hide: (i) => ({
      height: 0,
      opacity: 0,
      transition: {
        height: { duration: 0.9 },
        opacity: { duration: 0.1 },
        ease: "easeInOut",
        delay: 0.6,
        duration: 1,
      },
      transitionEnd: { display: "none" },
    }),
  };
  // framer motion input container animation
  const containerAnimation = {
    initial: { height: 0 },
    show: { height: "10vh" },
    hide: {
      height: 0,
      transition: {
        when: "afterChildren",
        staggerChildren: 0.2,
        duration: 0.5,
        delay: 0.8,
      },
    },
  };

  return (
    <AppContext.Provider
      value={{
        isEditable,
        setIsEditable,
        isGoogleProfile,
        setIsGoogleProfile,
        personalDetailsFomIsUpdating,
        setPersonalDetailsFomIsUpdating,
        passwordFormIsUpdating,
        setPasswordFormIsUpdating,
        handleButtonState,
        isPasswordEditable,
        setIsPasswordEditable,
        combinedUserData,
        setCombinedUserData,
        fadeIn,
        buttonInAndOut,
        preventDoubleClick,
        inputAnimations,
        containerAnimation,
        fadeInOutModal,
        fadeInOutHeight,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useProfileContext = () => {
  return useContext(AppContext);
};

export { AppContext, ProfileProvider };
