import React, { useState } from "react";
import {
	DndContext,
	closestCenter,
	KeyboardSensor,
	PointerSensor,
	useSensor,
	useSensors,
} from "@dnd-kit/core";
import {
	arrayMove,
	SortableContext,
	sortableKeyboardCoordinates,
	horizontalListSortingStrategy,
} from "@dnd-kit/sortable";

import SortableItem from "./SortableItem";

export const SortableList = ({
	items = [],
	visibility = [],
	callbackOnDragEnd = () => {},
	colorArray = [],
}) => {
	const [listItems, setListItems] = useState(items);

	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	);

	function handleDragEnd(event) {
		const { active, over } = event;

		if (active.id !== over.id) {
			setListItems((prevItems) => {
				const oldIndex = prevItems.indexOf(active.id);
				const newIndex = prevItems.indexOf(over.id);

				callbackOnDragEnd(oldIndex, newIndex);
				return arrayMove(prevItems, oldIndex, newIndex);
			});
		}
	}

	return (
		<DndContext
			sensors={sensors}
			collisionDetection={closestCenter}
			onDragEnd={handleDragEnd}
		>
			<SortableContext
				items={listItems}
				strategy={horizontalListSortingStrategy}
			>
				<ul className="w-full h-full flex flex-row justify-between flex-grow-0 max-w-full">
					{listItems.filter(item => {
						return visibility.find(sample => sample.name === item).visible === true;
					}).map((item, index) => (
						<SortableItem
							key={item}
							id={item}
							color={
								colorArray.length === 0
									? ""
									: colorArray[index]
							}
						/>
					))}
				</ul>
			</SortableContext>
		</DndContext>
	);
};
