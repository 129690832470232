import { database, storage } from "../../../firebase";
import { getDatabase, ref, onValue, remove } from "firebase/database";
import { ref as strgRef, uploadBytesResumable, getDownloadURL, deleteObject, listAll } from "firebase/storage";

const deleteFolder = (path) => {
    const ref = strgRef(storage, path);
    listAll(ref)
        .then(dir => {
            dir.items.forEach(fileRef => deleteFile(ref.fullPath, fileRef.name));
            dir.prefixes.forEach(folderRef => deleteFolder(folderRef.fullPath))
        })
        .catch(error => console.log(error));
}
const deleteFile = (pathToFile, fileName) => {
    const ref = strgRef(storage, pathToFile + '/' + fileName);

    deleteObject(ref);
}

export const handleDeleteProject = (projectID) => {
    remove(ref(database, 'projects/' + projectID));
    deleteFolder(`/files/${projectID}`);
    
};