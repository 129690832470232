import React, { useState, useEffect, useRef } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { motion, AnimatePresence } from "framer-motion";
import debounce from "lodash.debounce";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import secureLocalStorage from "react-secure-storage";
// firebase functions
import { getAuth, updateEmail } from "firebase/auth";
import { getDatabase, update, ref } from "firebase/database";
import {
  handleEmailVerification,
  handleReauthentication,
} from "features/authentication/actions";
// profile context
import { useProfileContext } from "pages/contexts/profileContext";
// components
import ProfilePageCancelButton from "components/buttons/ProfilePageCancelButton";
import ProfilePageSubmitButton from "components/buttons/ProfilePageSubmitButton";
import ProfilDataItem from "layouts/ProfilDataItem";

const PersonalDetailsForm = () => {
  const {
    isLoading,
    personalDetailsFomIsUpdating,
    setPersonalDetailsFomIsUpdating,
    isGoogleProfile,
    isEditable,
    combinedUserData,
    inputAnimations,
    containerAnimation,
    setIsEditable,
  } = useProfileContext();

  const db = getDatabase();
  const user = getAuth().currentUser;
  const userRef = ref(db, `users/${user?.uid}`);
  const formRef = useRef(null);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true); // state to disable submit button when is is true
  const [isEmailChanged, setIsEmailChanged] = useState(false); // state to show/hide verify password field
  const navigate = useNavigate();

  useEffect(() => {
    const formik = formRef.current;

    return () => {
      if (isEditable) {
        setIsSubmitDisabled(true); // reset state value to disable submit button
        setIsEmailChanged(false); // hide verify password field
      }
      // Reseting formik values and errors
      formik?.resetForm(); // reseting form values/errors
    };
  }, [isEditable, combinedUserData]);

  useEffect(() => {
    // Set form initial values
    formRef.current.initialValues.firstName = combinedUserData.firstName;
    formRef.current.initialValues.lastName = combinedUserData.lastName;
    formRef.current.initialValues.email = combinedUserData.email;
    // Set form input field values when 'combinedUserData' changes
    formRef.current.values.firstName = combinedUserData.firstName;
    formRef.current.values.lastName = combinedUserData.lastName;
    formRef.current.values.email = combinedUserData.email;
  }, [isLoading, combinedUserData]);

  const handleSubmit = async (e) => {
    if (!isEditable) {
      // if the form is not editable then prevent submission
      e.preventDefault();
    } else {
      /* Handle update profile data */
      e.preventDefault();
      const formData = formRef.current.values;

      setPersonalDetailsFomIsUpdating(true); // set form updating to true
      setIsSubmitDisabled(true);

      try {
        // check if the email changed
        if (formData.email !== combinedUserData.email) {
          // validate input data because debounced input fields
          await validationSchema.validate(formData);
          // re-authenticate the user with verify password field
          await handleReauthentication(user.email, formData.verifyPassword);
          // update user email address
          await updateEmail(user, formData.email);
          secureLocalStorage.setItem("credentialsForSignIn", {
            email: formData.email,
            pwd: formData.verifyPassword,
          }); // store password at localStorage to sign in after verification
          toast.warning("Verify new email address!"); // send a warnning toast message
          handleEmailVerification(); // send an email verification mail
          // handleLogout();
          navigate("/unverified_email"); // navigate unverified email page
        }
        // validate input data because debounced input fields
        await validationSchemaWithoutVerifyPassword.validate(formData);

        // update firstname and last in the database
        await update(userRef, {
          firstName: formData.firstName,
          lastName: formData.lastName,
        });

        // Update finished
        toast.success("Personal Details Succesfully Updated!", {});
        setIsEditable(false); // turn off edit mode
      } catch (error) {
        // Handling error messages
        const errorCode = error.code;
        const errorMessage = error.message;
        let toastError;
        switch (errorCode) {
          case "auth/wrong-password":
            toastError = "Verify password is incorrect.";
            break;
          case "auth/missing-password":
            toastError = "Verify password is missing.";
            break;
          case "auth/weak-password":
            toastError = "New Password should be at least 8 characters.";
            break;
          case "auth/network-request-failed":
            toastError = "Make sure you are connected to the internet!";
            break;
          case "auth/requires-recent-login":
            toastError = "Reauthentication required. Please sign in again!";
            break;
          case "auth/user-mismatch":
            toastError = "Credential mismatch!";
            break;
          default:
            toastError = `Update failed. (${errorMessage})`;
            break;
        }
        toast.error(toastError);
        console.log(`%c ${error}`, "color: red");
      }
      setPersonalDetailsFomIsUpdating(false); // Change loading state to false
      setIsSubmitDisabled(false);
    }
  };

  // Validation Schema for formik
  const validationSchema = Yup.object({
    firstName: Yup.string()
      .min(2, "Must be at least 2 characters")
      .required("Required"),
    lastName: Yup.string()
      .min(2, "Must be at least 2 characters")
      .required("Required"),
    email: Yup.string().email("Invalid email address").required("Required"),
    verifyPassword: Yup.string()
      .min(8, "Must be at least 8 characters")
      .required("Required"),
  });

  const validationSchemaWithoutVerifyPassword = Yup.object({
    firstName: Yup.string()
      .min(2, "Must be at least 2 characters")
      .required("Required"),
    lastName: Yup.string()
      .min(2, "Must be at least 2 characters")
      .required("Required"),
    email: Yup.string().email("Invalid email address").required("Required"),
  });

  // When the user typing this function will be fired. If 'isSubmitDisabled' is true then disable deactivation button.
  const handleChange = async (e) => {
    // get values from form
    const values = formRef.current?.values;

    try {
      if (values.email !== combinedUserData.email) {
        setIsEmailChanged(true); // show verify password field
        // validate input values
        await validationSchema.validate({
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          verifyPassword: values.verifyPassword,
        });
      } else {
        setIsEmailChanged(false); // hide verify password field
        // validate inputs without verify password value
        await validationSchemaWithoutVerifyPassword.validate({
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
        });
      }

      setIsSubmitDisabled(false); // enable submit button
    } catch (error) {
      console.error(error);
      setIsSubmitDisabled(true); // disable submit button if have any errors
    }
  };
  const debouncedChangeHandler = debounce(handleChange, 900); // debounce input changes. Wait 900ms after every key stroke

  // Debounce clean-up function
  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, []);

  return (
    <Formik
      validationSchema={validationSchema}
      validateOnMount={false}
      initialValues={{
        firstName: combinedUserData.firstName,
        lastName: combinedUserData.lastName,
        email: combinedUserData.email,
        verifyPassword: "",
      }}
      innerRef={formRef}
    >
      {({ errors, values }) => {
        return (
          <Form onSubmit={handleSubmit} onChange={debouncedChangeHandler}>
            <ProfilDataItem
              inputProps={{
                label: "first name",
                name: "firstName",
                placeholder: "first name",
                inputType: "text",
              }}
              value={values.firstName}
              errors={errors.firstName}
              isEditable={isEditable}
              isUpdateLoading={personalDetailsFomIsUpdating}
            />
            <ProfilDataItem
              inputProps={{
                label: "last name",
                name: "lastName",
                placeholder: "last name",
                inputType: "text",
              }}
              value={values.lastName}
              errors={errors.lastName}
              isEditable={isEditable}
              isUpdateLoading={personalDetailsFomIsUpdating}
            />
            <ProfilDataItem
              inputProps={{
                label: "email address",
                name: "email",
                placeholder: "email address",
                inputType: "email",
                disabled: isGoogleProfile ? true : false,
              }}
              description={
                isGoogleProfile
                  ? "Email is unchangeable in google profile"
                  : "Verification Required"
              }
              value={values.email}
              errors={errors.email}
              isEditable={isEditable}
              isUpdateLoading={personalDetailsFomIsUpdating}
            />
            {!isGoogleProfile && (
              <AnimatePresence initial={false}>
                <motion.div
                  initial="initial"
                  variants={inputAnimations}
                  animate={isEmailChanged ? "show" : "hide"}
                >
                  <ProfilDataItem
                    inputProps={{
                      label: "verify password",
                      name: "verifyPassword",
                      placeholder: "●●●●●●●●●●●",
                      inputType: "password",
                    }}
                    value={values.verifyPassword}
                    errors={errors.verifyPassword}
                    isEditable={isEditable}
                    isUpdateLoading={personalDetailsFomIsUpdating}
                  />
                </motion.div>
              </AnimatePresence>
            )}
            <motion.div
              initial="initial"
              animate={isEditable ? "show" : "hide"}
              variants={containerAnimation}
              className="flex justify-around items-center h-auto sm:justify-end gap-3"
            >
              <ProfilePageCancelButton
                content="cancel editing"
                isEditable={isEditable}
                isUpdateLoading={personalDetailsFomIsUpdating}
                onClickFunc={() => setIsEditable(!isEditable)}
              />
              <ProfilePageSubmitButton
                content="save details"
                isDisabled={isSubmitDisabled}
                isUpdateLoading={personalDetailsFomIsUpdating}
                isEditable={isEditable}
              />
            </motion.div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PersonalDetailsForm;
