import React from "react";

/**
 * COMPONENT USED INSIDE OF SIDEBARFRAME TO RENDER DROPDOWN SIDEBAR BUTTON
 *
 * PROPS:
 * @param {*} children
 * @param {Object} itemProps props to customize the sidebar dropdown button
 * @param {*} content Component to render in sidebar frame content when any sub-button is selected
 * @param {*} tips Component to render in quick tips when any sub-button is selected
 */
export const SidebarDropdown = ({ children, itemProps, content, tips }) => {
	return <div>{children}</div>;
};
