import { SortableList } from "layouts";
import React, { useEffect } from "react";
import Plot from "react-plotly.js";
import { arrayMove } from "@dnd-kit/sortable";
import {
	getDatabase,
	ref as dbRef,
	set,
	onValue,
} from "firebase/database";
import { tailwindColorsForNames, colorsForNames } from "./GraphColors";

const colorCodes = {
	O: "#FFA500",
	R: "#E33535",
	G: "#3CBE1B",
	Y: "#F2E34C",
};

const graphSkeleton = (
	<div
		role="status"
		className="h-full w-full p-4 border border-gray-200 rounded shadow animate-pulse md:p-6 dark:border-gray-700"
	>
		<div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2.5"></div>
		<div className="w-48 h-2 mb-10 bg-gray-200 rounded-full dark:bg-gray-700"></div>
		<div className="flex items-baseline mt-4 space-x-6">
			<div className="w-full bg-gray-200 rounded-t-lg h-72 dark:bg-gray-700"></div>
			<div className="w-full h-56 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
			<div className="w-full bg-gray-200 rounded-t-lg h-72 dark:bg-gray-700"></div>
			<div className="w-full h-64 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
			<div className="w-full h-56 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
			<div className="hidden xl:block w-full bg-gray-200 rounded-t-lg h-80 dark:bg-gray-700"></div>
			<div className="hidden 2xl:block w-full bg-gray-200 rounded-t-lg h-72 dark:bg-gray-700"></div>
		</div>
		<span className="sr-only">Loading...</span>
	</div>
);

export const CouplexChart = ({
	projectID,
	index,
	toggles,
	plotid,
}) => {
	const db = getDatabase();
	const chartRef = dbRef(db, `projects/${projectID}/result/data/${index}`);
	const [graphData, setGraphData] = React.useState([]);
	const [isLoading, setIsLoading] = React.useState(true);
	const [visibility, setVisibility] = React.useState([]);

	useEffect(() => {
		onValue(chartRef, (snapshot) => {
			let data = snapshot.val();
			setGraphData(data);
			setVisibility(data.map((sample) => {
				return {
					name: sample.Name,
					visible: true,
				}
			}));
		});
	}, []);

	useEffect(() => {
		if (isLoading && graphData.length !== 0) {
			setIsLoading(false);
		}
	}, [graphData]);

	const [meanABC, setMeanABC] = React.useState(0);

	useEffect(() => {
		calcAndSetMeanABC();
	}, [graphData]);

	useEffect(() => {
		setData(convertGraphDataToPlotData());
	}, [graphData, toggles.subtraction])

	function calcAndSetMeanABC() {
		graphData.forEach((sample) => {
			if (sample.Name === "ABC") setMeanABC(sample.mean);
		});
	}

	function convertGraphDataToPlotData() {
		return graphData.map((sample) => {

            let color = "#dbcf44";
            if (sample.Name === "ABC") color = "#5269de"
            else if (sample.Name === "NTC") color = "#db4646"

			return {
				y: sample.y.map((y) =>
					toggles.subtraction ? y.couplex - meanABC : y.couplex
				),
				type: "box",
				name: sample.Name,
				boxpoints: "all",
				pointpos: 0,
				jitter: 0.5,
				whiskerwidth: 0.2,
				// fillcolor: "cls",
				fillcolor: color,
				marker: {
					size: 6,
					color: "#212121",
				},
				line: {
					width: 2,
				},
				hovertemplate:
					"<b>Couplex:</b> %{y}<br>" +
					"<b>Well:</b> %{text}" +
					"<extra></extra>",
				text: sample.y.map((y) => y.well),
				boxmean: true,
				notched: false,
			};
		});
	}

	const [data, setData] = React.useState();

	const title = isLoading
		? {}
		: {
				text: `<span>${graphData[0].lambdas.antibodyleft} @ ${graphData[0].lambdas.antibodyright}</span><br>`,
				x: 0,
				xref: "paper",
		  };

	const [leftColor, rightColor] = isLoading
		? ["", ""]
		: graphData[0].Type.split("").map((c) => colorCodes[c]);

	const annotations = [
		{
			x: 0,
			y: 1.1,
			xref: "paper",
			yref: "paper",
			text: " ",
			width: 35,
			bgcolor: leftColor,
			showarrow: false,
		},
		{
			x: 0.18,
			y: 1.1,
			xref: "paper",
			yref: "paper",
			text: " ",
			width: 35,
			bgcolor: rightColor,
			showarrow: false,
		},
	];

	function onSortEnd(oldIndex, newIndex) {
		let temp = graphData;
		let newGraphData = arrayMove(temp, oldIndex, newIndex);
		set(chartRef, newGraphData);
	}

	function getVisible(plotid){
		var plot = document.getElementById(plotid);
		const vis = plot.data.map((sample) => {
			return {
				name: sample.name,
				visible: sample.visible ? sample.visible : true,
			}
		})
		setVisibility(vis);
	}

	return (
		<div className="h-[580px] w-full">
			{isLoading ? (
				<div className="h-full w-full p-16 opacity-40">{graphSkeleton}</div>
			) : (
				<>
					<Plot
						divId={plotid}
						data={data}
						layout={{
							title: {
								text: title.text,
								x: title.x,
								y: 0.9,
								xref: title.xref,
								font: {
									family: 'Roboto, monospace',
									size: 20,
									color: "#212121",
									weght: 400,
								},
							},
							legend: {
								font: {
									family: 'Roboto, monospace',
									size: 18,
									color: "#212121",
									weght: 400,
								},
								xref: "container",
								xanchor: "left",
								x: 0.75,
								entrywidth: 1,
								entrywidthmode: "pixels",
							},
							xaxis: {
								tickangle: 45,
							},
							yaxis: {
								title: {
									text: "Number of couplexes [-]",
									font: {
										family: 'Roboto, monospace',
										size: 18,
										color: "#212121",
										weght: 400,
									},
								},
								range: toggles.uniform
								? [-1000, 2500]
								: [],
								showline: true,
								linewidth: 2,
								zerolinewidth: 2,
								linecolor: "#858585",
								zerolinecolor: "#858585",
								ticks: 'outside',
							},
							margin: {
								t: 100,
								r: 150,
								b: 0,
							},
							boxgap: 0,
							annotations: annotations,
							modebar: {
                                
								// orientation: "h",
							},
						}}
						useResizeHandler
						// config={{
						// 	//displayModeBar: true,
						// 	responsive: true,
						// }}
						className="w-full h-[500px]"
						onRestyle={() => getVisible(plotid)}
					/>
					<div className="w-full h-[1px] flex items-center justify-center pr-[25%] pl-[80px]">
						<SortableList
                            items={graphData.map((sample) => {
								return sample.Name
							})}
							visibility={visibility}
                            // colorArray={graphData.map(
                            //     (sample) => tailwindColorsForNames.light[sample.plotColor.light]
                            // )}
                            callbackOnDragEnd={onSortEnd}
                        />
					</div>
				</>
			)}
		</div>
	);
};
