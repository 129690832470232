import React, { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, onValue } from "firebase/database";
import { auth } from "../../firebase";
import { Background, Header, Navbar, PageFrame } from "layouts";
import logo from "assets/images/logo-icon.png";
import { handleLogout } from "features/authentication/actions";
import { ProjectList } from "features/projects";

export const Home = () => {
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);

  const db = getDatabase();

  //console.log(user);
  //console.log(userData);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        setUser(user);
        const userRef = ref(db, "users/" + uid);
        onValue(userRef, (snapshot) => {
          const data = snapshot.val();
          setUserData(data);
        });
        // ...
        //console.log("uid", uid);
      } else {
        // User is signed out
        // ...
        //navigate("/login");
        //console.log("user is logged out");
      }
    });
  }, []);

  return (
    <Background>
      <Navbar currentNavItem={"Dashboard"} />
      <Header>
        <h1 className="text-xl font-bold tracking-tight text-gray-900">
          My projects
        </h1>
      </Header>
      <PageFrame>
        <ProjectList />
      </PageFrame>
    </Background>
  );
};

/*{user && !user.emailVerified && (

        )}*/
