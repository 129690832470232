import React from "react";

export const LambdaChartsHelp = () => {
	return (
		<div id="tips">
			<ul className="space-y-2">
				<li>
					<strong>Export data:</strong>
					<br /> Click the left dropdown to select a file format and press the <i className="text-blue-500">Export data</i> button to dowload all the data.
				</li>
				<li>
					<strong>Export graphs as images:</strong>
					<br /> Click the right dropdown to select a file format and press the <i className="text-blue-500">Export graph images</i> button to dowload all of the images in a .zip file.
					If you enter a name into the <i className="text-blue-500">Download Name</i> box, the charts will be named that way. If no name is entered the graphs will be named after their type.
					The graphs within the .zip will be indexed from 1.
				</li>
				<li>
					<strong>Pan:</strong>
					<br /> Click on the Pan button and drag on the plot to pan
					the view.
				</li>
				<li>
					<strong>Zoom:</strong>
					<br /> Click on the + or - buttons to zoom in or out.
				</li>
				<li>
					<strong>Select:</strong>
					<br /> Click and drag on the plot to create a selection box.
				</li>
				<li>
					<strong>Reset:</strong>
					<br /> Double-click on the plot to expand or reset the view.
				</li>
				<li>
					<strong>Hover:</strong>
					<br /> Move the mouse cursor over data points to see
					tooltips.
				</li>
			</ul>
		</div>
	);
};
