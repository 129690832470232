import React from "react";

export function NeutralButton({ onClick, children, buttonRef }) {
  return (
    <button
      ref={buttonRef}
      type="button"
      className="mt-3 inline-flex w-full justify-center rounded-full bg-gray-50 outline-gray-500 px-3 py-2 text-sm font-semibold text-gray-600 shadow-md ring-1 ring-inset ring-gray-500 hover:bg-gray-600 hover:text-white sm:mt-0 sm:w-auto disabled:opacity-80 disabled:bg-gray-500 disabled:ring-gray-500 disabled:border-gray-500 disabled:text-gray-200 disabled:cursor-not-allowed"
      onClick={onClick}
    >
      {children}
    </button>
  );
}
