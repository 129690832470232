import React from 'react';
import { Navbar } from 'layouts';
import logo from "assets/images/logo-icon.png"


export const Team = () => {

    return (
        <>
            <div>
                <Navbar currentNavItem={'Team'}/>
                <div className="sm:mx-auto sm:w-full sm:max-w-sm py-12">
                    <img
                        className="mx-auto h-20 w-auto"
                        src={logo}
                        alt="Your Company"
                    />
                </div>
                <div className="mx-auto max-w-2xl text-center">
                    <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                        Your team
                    </p>
                    <p className="mt-2 text-xl font-bold tracking-tight text-gray-600 sm:text-xl">
                        Under construction...
                    </p>
                </div>
            </div>
        </>
    )
}