import React, { useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { handleEmailVerification } from "features/authentication/actions";
import { Modal } from "components";
import { NeutralButton, NextButton } from "components/buttons";
import CheckCircleIcon from "@heroicons/react/24/solid/CheckCircleIcon";
import ExclamationTriangleIcon from "@heroicons/react/24/solid/ExclamationTriangleIcon";
import secureLocalStorage from "react-secure-storage";
import loader from "../../../assets/icons/loading.gif";
import { toast } from "react-toastify";

export const RecoverEmail = ({ success }) => {
  const navigate = useNavigate();
  const auth = getAuth();
  const [isLoading, setIsLoading] = useState(false); // state variable to show the user the action is loading

  const handleDoneButtonClick = async (e) => {
    try {
      setIsLoading(true); // loading is true

      const loginData = secureLocalStorage.getItem("credentialsForSignIn"); // get new email and verified password from localStorage
      const email = window.localStorage.getItem("restoredEmail"); // get restored email from localStorage
      const verifiedType = window.localStorage.getItem("verified"); // get verified to know that user clicked on verify link in their email

      if (verifiedType) {
        // check if loginData(password) exists
        if (loginData) {
          await signInWithEmailAndPassword(auth, email, loginData.pwd); // sign-in with email and password
        }
      }
      // remove password and email from localStorage
      secureLocalStorage.removeItem("credentialsForSignIn");
      // remove restored email
      window.localStorage.removeItem("restoredEmail");
      // remove verified value from localStorage
      window.localStorage.removeItem("verified");

      setIsLoading(false);
      window.location.href = "/login";
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
  };

  return (
    <>
      {success ? (
        <Modal
          icon={<CheckCircleIcon className="text-green-600" />}
          title="Email Reseted"
          text={
            "Your email was reseted. You can now finish your sign in on the previous page or sign in this page."
          }
          buttonsArray={[
            <NextButton onClick={handleDoneButtonClick}>
              {isLoading ? (
                <img
                  className={`object-cover w-5 transition ease-in-out delay-150`}
                  src={loader}
                  alt="loading"
                />
              ) : (
                "Sign In"
              )}
            </NextButton>,
          ]}
        />
      ) : (
        <Modal
          icon={<ExclamationTriangleIcon className="text-red-600" />}
          title="Email Verification"
          text={
            "Verification link is invalid or expired. Please try verifying again."
          }
          buttonsArray={[
            <NeutralButton
              onClick={() => {
                handleEmailVerification();
              }}
            >
              Resend Email
            </NeutralButton>,
          ]}
        />
      )}
    </>
  );
};
