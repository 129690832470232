export const Header = ({ children }) => {
  return (
    <>
      <header data-testid="Header" className="bg-white shadow z-10">
        <div className="mx-auto max-w-7xl px-4 py-4 sm:px-6 lg:px-8">
          {children}
        </div>
      </header>
    </>
  );
};
