import React from "react";

/**
 * THIS COMPONENT IS A LINE DIVIDER TO SEPARE CONTENT IN THE SIDEBAR LIST
 */
const SidebarListDivider = () => {
	return (
		<div className="w-full h-[2px] rounded-full bg-black bg-opacity-20 my-4"></div>
	);
};

export default SidebarListDivider;
