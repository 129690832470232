import { PageFrame } from "layouts/PageFrame";
import React from "react";

/**
 * THIS COMPONENT DISPLAYS THE SELECTED CONTENT INSIDE A PAGEFRAME
 * 
 * PROPS:
 * @param {*} content selected content to display
 */
export const SidebarFrameContent = ({ content, tips }) => {
	return (
		<div className="max-sidebar:w-[100%] overflow-hidden">
			<div className="@container/frame h-[100%] max-h-[100%] overflow-x-hidden overflow-y-scroll max-sidebar3:pt-14 scrollbar-none scrollbar-track-gray-200 scrollbar-thumb-gray-400">
				<PageFrame isFullWidth testid="SidebarFrameContent-pageFrame" tips={tips}>
					{content}
				</PageFrame>
			</div>
		</div>
	);
};
