import React, { useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Tooltip, Button } from "flowbite-react";

/**
 * THIS COMPONENT IS A NORMAL SELECTABLE BUTTON OR SELECTABLE BUTTON INSIDE A DROPDOWN BUTTON IN A SIDEBAR LIST
 * if subIndex is -1, it is a normal selectable button inside the sidebar list
 * if subIndex is not -1, it is selectable button inside a dropdown button
 * 
 * PROPS:
 * @param {Boolean} isSidebarClosed 
 * @param {Boolean} isSelected is it the current selected button
 * @param {Function} changeSelectedContent 
 * @param {Number} mainIndex 
 * @param {Number} subIndex only if dropdown (optional)
 * @param {String} testid for testing purposes
 * @param {Object} itemProps props given and calculated from SidebarItem/SidebarDropdown
 * @param {Function} toggleSidebarClose (optional)
 * @param {boolean} isMobile (optional)
 */
const SidebarListButton = ({
	isSidebarClosed,
	isSelected,
	changeSelectedContent,
	mainIndex,
	subIndex = -1,
	testid = "",
	itemProps,
    toggleSidebarClose=() => {},
    isMobile=false,
}) => {
	const { title, icon, disabled = false } = itemProps;

    /**
     * Function to check if this component is a selectable button inside a dropdown button
     * @returns checked result
     */
	const isDropdown = () => {
		return subIndex !== -1;
	};

	const buttonStyle =
		"text-black font-semibold w-full flex flex-row gap-3 items-center px-4 rounded-medium opacity-60 hover:opacity-100 bg-black hover:bg-opacity-5 duration-200 z-40";

    /**
     * framer-motion variant animation when the sidebar closes/opens
     */
	const showAnimation = {
		visible: {
			width: "auto",
			opacity: 1,
			transition: {
				duration: 0.2,
				delay: 0.2,
			},
		},
		hidden: {
			width: 0,
			opacity: 0,
			transition: {
				duration: 0,
			},
		},
	};

    /**
     * framer-motion variant animation when the user hovers button when sidebar is closed
     */
    const showToolTipAnimation = {
		visible: {
			opacity: 1,
			transition: {
                delay: 0.5,
				duration: 0.2,
			},
		},
		hidden: {
			opacity: 0,
			transition: {
				duration: 0.2,
			},
		},
	};

	const [isHovered, setIsHovered] = React.useState(false);

	return (
		<button
			onMouseOver={() => {
				setIsHovered(true);
			}}
			onMouseOut={() => {
				setIsHovered(false);
			}}
			data-testid={testid}
			onClick={() => {
                if (isMobile) toggleSidebarClose();
				changeSelectedContent(mainIndex, subIndex);
			}}
			className={` ${buttonStyle} ${
				isSelected ? "bg-opacity-10" : "bg-opacity-0"
			} ${isDropdown() ? "py-[2px] max-sidebar3:py-[8px]" : "py-[8px]"} ${
				isSidebarClosed ? "justify-center" : "justify-start"
			}`}
		>
            {/* BUTTON ICON */}
			<div
				data-testid="SidebarListButton-IconDiv"
				className={`${
					isDropdown() ? "h-4 w-4" : "h-6 w-6"
				} flex-shrink-0`}
			>
				{icon}
			</div>
            {/* BUTTON TITLE */}
			<AnimatePresence>
				{!isSidebarClosed && (
					<motion.p
						data-testid="SidebarListButton-motion.p"
						initial="hidden"
						animate="visible"
						exit="hidden"
						variants={showAnimation}
						className="whitespace-nowrap"
					>
						{title}
					</motion.p>
				)}
			</AnimatePresence>
            {/* TOOLTIP */}
            <AnimatePresence>
				{isSidebarClosed && isHovered && (
					<motion.div
                        data-testid="SidebarListButton-toolTip"
						initial="hidden"
						animate="visible"
						exit="hidden"
						variants={showToolTipAnimation}
						className="left-[105px] absolute whitespace-nowrap px-6 py-2 rounded-medium z-40 overflow-hidden bg-custom text-white text-sm"
					>
						{title}
					</motion.div>
				)}
			</AnimatePresence>
		</button>
	);
};

export default SidebarListButton;
