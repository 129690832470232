import React from "react";
import { Navigate } from "react-router-dom";
import { LoadingPage } from "components";

export const RequireAuth = ({
  children,
  authenticated,
  loading,
  skeletonLoading,
  redirect,
}) => {
  //console.log(authenticated);
  // check if it is loading, if yes, show the loading page
  if (loading) {
    if (skeletonLoading) {
      return children;
    }
    return <LoadingPage />;
  }
  // check if the authenticated condition fails, then redirect the user to the specified page
  else if (!authenticated) {
    return <Navigate to={redirect} />;
  }
  // otherwise proceed
  else {
    return children;
  }
};
