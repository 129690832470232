export const PageFrame = ({ children, isFullWidth = false, testid = "", tips }) => {
	return (
		<>
			<main
				data-testid={testid}
				className={`mx-auto ${
					!isFullWidth && "max-w-7xl"
				} py-6 lg:px-8 sm:px-6 px-4`}
			>
				<div
					className={`p-4 shadow-lg bg-white rounded-2xl border border-gray-200`}
				>
					<div>{children}</div>
				</div>
			</main>
		</>
	);
};
