import React, { useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { handleEmailVerification } from "features/authentication/actions";
import { Modal } from "components";
import { NeutralButton, NextButton } from "components/buttons";
import CheckCircleIcon from "@heroicons/react/24/solid/CheckCircleIcon";
import ExclamationTriangleIcon from "@heroicons/react/24/solid/ExclamationTriangleIcon";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import loader from "../../../assets/icons/loading.gif";

export const EmailVerified = ({ success }) => {
  const navigate = useNavigate();
  const auth = getAuth();
  const [isLoading, setIsLoading] = useState(false);
  //console.log(auth);

  const handleDoneButtonClick = async () => {
    try {
      setIsLoading(true);

      const loginData = secureLocalStorage.getItem("credentialsForSignIn"); // get new email and verified password from localStorage
      const verifiedType = window.localStorage.getItem("verified"); // get verified to know that user clicked on verify link in their email

      if (verifiedType) {
        signInWithEmailAndPassword(auth, loginData.email, loginData.pwd).then(
          (userCredential) => {
            // remove password and email from localStorage
            secureLocalStorage.removeItem("credentialsForSignIn");
            // remove restored email
            window.localStorage.removeItem("restoredEmail");
            // remove verified value from localStorage
            window.localStorage.removeItem("verified");

            setIsLoading(false);
            window.location.href = "/login";
          }
        );
      } else {
        setTimeout(() => {
          setIsLoading(false);
          window.location.href = "/login";
        }, 1000);
      }
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
  };

  return (
    <>
      {success ? (
        <Modal
          icon={<CheckCircleIcon className="text-green-600" />}
          title="Email Verification"
          text={"Your email was verified. You can start using the application."}
          buttonsArray={[
            <NextButton onClick={handleDoneButtonClick}>
              {isLoading ? (
                <img
                  className={`object-cover w-5 transition ease-in-out delay-150`}
                  src={loader}
                  alt="loading"
                />
              ) : (
                "Done"
              )}
            </NextButton>,
          ]}
        />
      ) : (
        <Modal
          icon={<ExclamationTriangleIcon className="text-red-600" />}
          title="Email Verification"
          text={
            "Verification link is invalid or expired. Please try verifying again."
          }
          buttonsArray={[
            <NeutralButton
              onClick={() => {
                handleEmailVerification();
              }}
            >
              Resend Email
            </NeutralButton>,
          ]}
        />
      )}
    </>
  );
};
