import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import { getAuth } from "firebase/auth";
import { ref as dbRef, set, get, push } from "firebase/database";
import { database, storage } from "../../../firebase";
import {
	ref as strgRef,
	uploadBytesResumable,
	getDownloadURL,
} from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { NextButton, FailButton } from "components/buttons";
import { toast } from "react-toastify";
import { PlusIcon } from "@heroicons/react/24/outline";
import { checkFileExtension, uploadFile } from "../actions";

export const NewProjectModal = () => {
	// File state and modal state
	const [sampleDefFile, setSampleDefFile] = useState(null);
	const [dataFiles, setDataFiles] = useState(null);
	const [open, setOpen] = useState(false);
	const [percent, setPercent] = useState([]);
	const [totalPercent, setTotalPercent] = useState(0);
	const [started, setStarted] = useState(false);

	// Reset file state when the modal opens or closes
	useEffect(() => {
		setSampleDefFile(null);
		setDataFiles(null);
		setPercent((prev)=>[]);
		setTotalPercent(0);
		setStarted(false);
	}, [open]);
	useEffect(() => {
		let temp = 0;
		let len = 0;
		percent.forEach(element => {
			temp += element;
			len +=1;
		});
		temp -= percent[0];
		if (len > 1) {
			const tp = temp / (len - 1);
			setTotalPercent(tp);
		}
	}, [percent]);
	useEffect(() => {
		if(started){
			if(!sampleDefFile && !dataFiles){
				successClose();
			}
			else if(sampleDefFile && !dataFiles){
				if(percent[0] === 100){
					successClose();
				}
			}
			else if(!sampleDefFile && dataFiles){
				if(totalPercent === 100){
					successClose();
				}
			}
			else if(sampleDefFile && dataFiles){
				if(percent[0] === 100 && totalPercent === 100){
					successClose();
				}
			}
		}
	}, [percent, started, totalPercent])

	const successClose = () => {
		toast.success("Project created!", {
			position: "top-center",
		});
		setOpen(false);
		//console.log("READY");
	}

	// Form validation schema
	const validationSchema = yup.object({
		name: yup.string().required("Project name is required"),
		// token: yup.string().required("Token is required for project creation"),
	});

	//update percent list
	const updatePercent = (p, idx) => {
		setPercent((prev) =>
			Object.assign([], {
				...prev,
				[idx]: p,
			}));
	}

	// Function to create a new project
	const createProject = (data) => {
		const { name, token } = data;
		const projectID = uuidv4();

		//console.log("Creating project");

		const uid = getAuth().currentUser.uid;

		let projectData = {
			main: {
				accessorIDs: [uid],
				creatorID: uid,
				name: name,
				status: 0,
				result: "none"
			},
			details: {
				sampleDefFile: "",
				dataFiles: [],
				date: new Date().toLocaleString(),
				token: token,
				creatorName: "",
			},
			results: {
				// length: 0,
				// data: [],
			},
		};

		set(dbRef(database, "projects/" + projectID), projectData);

		// Get the creator's name from the database and set the project data
		get(dbRef(database, `users/${projectData.main.creatorID}`))
			.then((snapshot) => {
				if (snapshot.exists()) {
					projectData.details.creatorName =
						snapshot.val().firstName +
						" " +
						snapshot.val().lastName;

					set(
						dbRef(
							database,
							"projects/" + projectID + "/details/creatorName"
						),
						snapshot.val().firstName + " " + snapshot.val().lastName
					);
				} else {
					//console.log("No data available");
				}
			})
			.catch((error) => {
				console.error(error);
			});

		if (sampleDefFile) {
			const storageRef = strgRef(
				storage,
				`/files/${projectID}/${sampleDefFile.name}`
			);
			uploadFile(
				sampleDefFile,
				storageRef,
				(newData) => {
					set(
						dbRef(
							database,
							"projects/" + projectID + "/details/sampleDefFile"
						),
						newData
					);
				},
				updatePercent,
				0
			);
		}

		// Upload the data files if exists
		if (dataFiles) {
			let fileURLs = [];
			for (let i = 0; i < dataFiles.length; i++) {
				updatePercent(0, i + 1);
				let file = dataFiles[i];
				const storageRef = strgRef(
					storage,
					`/files/${projectID}/${file.name}`
				);
				uploadFile(
					file,
					storageRef,
					(newData) => {
						set(
							push(
								dbRef(
									database,
									"projects/" +
									projectID +
									"/details/dataFiles"
								)
							),
							newData
						);
					},
					updatePercent,
					i + 1
				);
			}
		}
		// console.log("PROJECT DATAA");
		// console.log(projectData);
		setStarted(true);
	};

	// Handle file upload event and update the file state
	function handleDataFileChange(event) {
		const files = event.target.files;
		if (files.length > 5) {
			toast.error("Upload 5 files maximum!", {
				position: "top-center",
			});
			return;
		}
		const filteredFiles = [...files]
			.map((file) => {
				if (!checkFileExtension(file)) {
					toast.error(`${file.name}'s extension is not supported!`, {
						position: "top-center",
					});
					return null;
				} else return file;
			})
			.filter((file) => file != null);
		if (filteredFiles.length > 0) {
			setDataFiles(filteredFiles);
		}
	}
	function handleSampleDefFileChange(event) {
		const file = event.target.files[0];
		if (checkFileExtension(file)) {
			setSampleDefFile(file);
		} else {
			toast.error(`File extension is not supported!`, {
				position: "top-center",
			});
		}
	}

	// Handle form submission
	function handleSubmit(values) {
		createProject({
			...values,
			token: "tjqQlo3cnt"
		});
	}

	return (
		<div className="">
			{/* Button to open the modal */}
			<NextButton
				onClick={() => setOpen(!open)}
				data-testid="NextButton-CreateProject"
			>
				<PlusIcon className="w-5 h-5 mr-2" />
				<p>Create project</p>
			</NextButton>

			{/* Modal */}
			<div
				id="newProjectModal"
				data-testid="NewProjectModal"
				tabIndex="-1"
				aria-hidden="true"
				className={`fixed top-0 left-0 right-0 z-50 w-full p-4 ${open ? "backdrop-blur-sm" : "hidden"
					} overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full`}
			>
				<div className="relative mx-auto top-1/2 -translate-y-1/2 w-full max-w-2xl max-h-full">
					{/* Modal content */}
					<div className="relative bg-white rounded-lg drop-shadow-2xl dark:bg-gray-700">
						{/* Modal header  */}
						<div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
							<h3 className="text-xl font-semibold text-gray-900 dark:text-white">
								Create Project
							</h3>
							<button
								onClick={() => setOpen(!open)}
								type="button"
								className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
								data-testid="NewProjectModal-closeButton"
								data-modal-hide="projectModal"
								disabled={started}
							>
								<svg
									aria-hidden="true"
									className="w-5 h-5"
									fill="currentColor"
									viewBox="0 0 20 20"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
										clipRule="evenodd"
									></path>
								</svg>
								<span className="sr-only">Close modal</span>
							</button>
						</div>
						{/* Modal body */}
						<div className="p-6 space-y-6">
							<Formik
								initialValues={{
									name: "",
									token: "",
								}}
								validationSchema={validationSchema}
								onSubmit={(values) => handleSubmit(values)}
							>
								{({ errors, touched, values }) => (
									<div>
										<Form className="space-y-4">
											{/* Project name field */}
											<div>
												<label
													htmlFor="name"
													className="block text-sm font-medium leading-6 text-gray-900"
												>
													Project name
												</label>
												<div className="mt-2">
													<Field
														data-testid="NewProjectModal-ProjectNameField"
														id="name"
														type="text"
														name="name"
														disabled={started}
														className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
													/>
													{/* Display error message if name field is invalid */}
													<ErrorMessage name="name">
														{(msg) => (
															<div className="text-red-500 text-sm">
																{msg}
															</div>
														)}
													</ErrorMessage>
												</div>
											</div>
											{/* <div>
												<label
													htmlFor="token"
													className="block text-sm font-medium leading-6 text-gray-900"
												>
													Token
												</label>
												<div className="mt-2">
													<Field
														data-testid="NewProjectModal-TokenField"
														id="token"
														type="text"
														name="token"
														disabled={started}
														className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
													/>
													<ErrorMessage name="token">
														{(msg) => (
															<div className="text-red-500 text-sm">
																{msg}
															</div>
														)}
													</ErrorMessage>
												</div>
											</div> */}

											{/* File input */}
											<div className="py-3">
												<label
													htmlFor="name"
													className="block text-sm font-medium leading-6 text-gray-900"
												>
													Sample definition file:
												</label>
												<label className="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 shadow-md rounded-2xl cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
													<div className="flex flex-col items-center justify-center pt-5 pb-6">
														<svg
															aria-hidden="true"
															className="w-10 h-10 mb-3 text-gray-400"
															fill="none"
															stroke="currentColor"
															viewBox="0 0 24 24"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																strokeLinecap="round"
																strokeLinejoin="round"
																strokeWidth="2"
																d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
															></path>
														</svg>
														<p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
															{sampleDefFile ? (
																<span className="font-semibold">
																	{
																		sampleDefFile.name
																	}
																</span>
															) : (
																<span className="font-semibold">
																	Click to
																	upload the
																	sample
																	definition
																	file:
																</span>
															)}
														</p>
													</div>
													<input
														data-testid="NewProjectModal-sampleFileInput"
														id="dropzone-file"
														onChange={
															handleSampleDefFileChange
														}
														type="file"
														accept=".xls,.xlsx,.csv"
														className="hidden"
														disabled={started}
													/>
												</label>

												{/* Progress bar */}
												<div className="w-full mt-2 bg-gray-200 rounded-full dark:bg-gray-700">
													{percent[0] > 0 && (
														<div
															className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
															style={{
																width: `${percent[0]}%`,
															}}
														>
															{percent[0]}%
														</div>
													)}
												</div>
											</div>
											<div className="py-3">
												<label
													htmlFor="name"
													className="block text-sm font-medium leading-6 text-gray-900"
												>
													Raw data files:
												</label>
												<label className="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 shadow-md rounded-2xl cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
													<div className="flex flex-col items-center justify-center pt-5 pb-6">
														<svg
															aria-hidden="true"
															className="w-10 h-10 mb-3 text-gray-400"
															fill="none"
															stroke="currentColor"
															viewBox="0 0 24 24"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																strokeLinecap="round"
																strokeLinejoin="round"
																strokeWidth="2"
																d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
															></path>
														</svg>
														<p className="mb-2 text-sm text-gray-500 dark:text-gray-400 text-center">
															{dataFiles ? (
																[
																	...dataFiles,
																].map(
																	(
																		file,
																		id
																	) => (
																		<span
																			key={
																				id
																			}
																			className="font-semibold"
																		>
																			{" "}
																			{
																				file.name
																			}{"; "}
																		</span>
																	)
																)
															) : (
																<span className="font-semibold">
																	Click to
																	upload the
																	raw data
																	files:
																</span>
															)}
														</p>
													</div>
													<input
														data-testid="NewProjectModal-dataFileInput"
														id="dropzone-file"
														onChange={
															handleDataFileChange
														}
														type="file"
														accept=".xls,.xlsx,.csv"
														className="hidden"
														disabled={started}
														multiple
													/>
												</label>

												{/* Progress bar */}
												<div className="w-full mt-2 bg-gray-200 rounded-full dark:bg-gray-700">
													{totalPercent > 0 && (
														<div
															className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
															style={{
																width: `${totalPercent}%`,
															}}
														>
															{totalPercent.toFixed(0)}%
														</div>
													)}
												</div>
											</div>

											{/* Modal footer */}
											<div className="flex items-center py-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
												<NextButton
													// onClick={() => handleSubmit(values)}
													type="submit"
													data-testid="NextButton"
													disabled={started}
												>
													Create
												</NextButton>
												<FailButton
													onClick={() =>
														setOpen(!open)
													}
													type="reset"
													data-testid="FailButton"
													disabled={started}
												>
													Cancel
												</FailButton>
											</div>
										</Form>
									</div>
								)}
							</Formik>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
