import {getAuth, signOut} from "firebase/auth";

export const handleLogout = () => {
    const auth = getAuth()
    signOut(auth).then(() => {
        // Sign-out successful.
        console.log("Signed out successfully")

    }).catch((error) => {
        console.log(error)
        // An error happened.
    });
}